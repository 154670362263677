import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import Table from 'tio-common/components/table/index';
import Paginator from 'tio-common/components/tio/paginator';
import type PslfFormModel from 'tio-common/models/pslf-form';
import TableRow from './company-forms-table/row';
import type Controller from 'tio-employee/controllers/authenticated/admin/pslf/forms';
interface PslfAdminCompanyFormsTableSignature {
    Args: {
        model: PslfFormModel[];
        controller: Controller;
    };
}
export default class PslfAdminCompanyFormsTableComponent extends Component<PslfAdminCompanyFormsTableSignature> {
    @action
    updateSortBy(sort1: string) {
        this.args.controller.sort = sort1;
    }
    @action
    changePaginatorPage(page1: number) {
        this.args.controller.page = page1;
    }
    static{
        template(`
    {{! @glint-expect-error: ED types}}
    <Table @isLoading={{@model.isLoading}}>
      <:thead>
        <tr class="font-semibold text-left bg-ocean-50">
          {{#let "px-2 py-1 border border-gray-300" as |cellClass|}}
            <th class={{cellClass}}>{{t "partner.employee.payroll_id"}}</th>
            <th class={{cellClass}}>{{t "name"}}</th>
            <th class={{cellClass}}>{{t "status"}}</th>
            <th class={{cellClass}}>{{t "user-roles.tas.approver.approver"}}</th>
            <th class={{cellClass}}>{{t "pslf.table.date_borrower_signed"}}</th>
            <th class={{cellClass}}>{{t "pslf.document_repo.date_employer_signed"}}</th>
            <th class={{cellClass}}>{{t "pslf.document_repo.form_type_and_employer"}}</th>
            <th class={{cellClass}}></th>
          {{/let}}
        </tr>
      </:thead>
      <:tbody>
        {{#each @model as |item|}}
          <TableRow @model={{item}} @isLoading={{item.is_loading}} />
        {{/each}}
      </:tbody>
      <:tfoot>
        <tr>
          <td class="text-right" colspan="8">
            <Paginator
              {{! @glint-expect-error: ed needs types}}
              @totalItems={{@model.meta.page.total}}
              @pageSize={{@controller.limit}}
              @currentPage={{@controller.page}}
              @onChange={{this.changePaginatorPage}}
            />
          </td>
        </tr>
      </:tfoot>
    </Table>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
