import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { array } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import { object, string } from 'yup';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TioButton from 'tio-common/components/tio/button';
import TioDialog from 'tio-common/components/tio/dialog';
import TioFooter from 'tio-common/components/tio/footer';
import TioForm from 'tio-common/components/tio/form';
import TioLoadingMessages from 'tio-common/components/tio/loading-messages';
import type PreRegisterService from 'tio-employee/services/pre-register';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
let SelfRegistrationRouteComponent = class SelfRegistrationRouteComponent extends Component {
    @service
    preRegister: PreRegisterService;
    @service
    router: RouterService;
    @service
    store: typeof Store;
    @tracked
    isSubmitting = false;
    @tracked
    formSubmitted = false;
    formSchema = object({
        work_email: string().required('* Required').email('The Email field must be a valid email'),
        first_name: string().required('* Required'),
        last_name: string().required('* Required')
    });
    @action
    closeDialog() {
        this.router.transitionTo('login');
    }
    @action
    async onSubmit({ work_email: work_email1, first_name: first_name1, last_name: last_name1 }: {
        work_email: string;
        first_name: string;
        last_name: string;
    }) {
        this.isSubmitting = true;
        try {
            await this.store.adapterFor('employee').selfRegister({
                email: work_email1,
                first_name: first_name1,
                last_name: last_name1
            });
        } catch (e1) {
            console.error(e1);
        } finally{
            this.isSubmitting = false;
            this.formSubmitted = true;
        }
    }
    static{
        template(`
    {{pageTitle (t "login.register")}}
    <div class="login flex flex-col space-y-10 justify-center items-center mt-20">
      <div class="bg-white w-full max-w-xl shadow-2xl p-5">
        <div class="text-center mb-6 text-lg">
          {{t "login.what_email"}}
          {{t "login.will_send_email"}}
        </div>

        <TioForm
          class="mx-2 mb-10"
          @i18nPath="login"
          @schema={{this.formSchema}}
          @onSubmit={{this.onSubmit}}
          as |form|
        >
          <form.InputField
            @containerClass="mb-4"
            @name="work_email"
            @type="email"
            @hint={{t "login.email_hint_self_registration"}}
            required
          />

          <form.InputField
            @containerClass="mb-4"
            @name="first_name"
            @type="text"
            @autocomplete="given-name"
            required
          />

          <form.InputField
            @containerClass="mb-4"
            @name="last_name"
            @type="text"
            @autocomplete="family-name"
            required
          />

          <form.Errors class="mt-6" />
          <form.Button class="w-36 my-10 mx-auto" @type="submit" @disableWhenInvalid={{true}} />

          {{#if this.isSubmitting}}
            <div class="flex justify-center">
              <TioLoadingMessages
                @i18nPath="login.loading_messages"
                @messageKeys={{array
                  "verify_credentials"
                  "wait_for_system_warmup"
                  "thank_you_for_your_patience"
                  "fetch_company_data"
                }}
                @initialIntervalMs={{0}}
                @messageIntervalMs={{3000}}
              />
            </div>
          {{/if}}
        </TioForm>

        <div class="flex justify-center mb-6 text-md text-tio-gray-400 gap-2">
          {{t "login.already_have_account"}}
          <LinkTo class="underline text-ocean-600" @route="login">
            {{t "login.login_here"}}
          </LinkTo>
        </div>
      </div>
    </div>
    <TioFooter />

    <TioDialog
      @show={{this.formSubmitted}}
      @containerClass="w-full sm:max-w-screen-sm"
      @headerClass="font-medium text-xl"
    >
      <:header>
        <div class="flex justify-center mt-4">
          {{t "login.action_required"}}
        </div>
      </:header>
      <:body>
        <p>{{t "login.self_registration_success"}}</p>
      </:body>
      <:actions>
        <div class="flex justify-center">
          <TioButton @onClick={{this.closeDialog}} class="w-48">{{t "common.close"}}</TioButton>
        </div>
      </:actions>
    </TioDialog>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(SelfRegistrationRouteComponent);
