import Route from '@ember/routing/route';

export default class ResetPasswordRoute extends Route {
  queryParams = {
    token: { refreshModel: true, replace: true },
    'ember-reset-password': { refreshModel: false },
  };

  async beforeModel() {
    // TODO: validate token here
  }
}
