import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';

export default class PslfEmployerVerificationRoute extends Route {
  @service declare store: typeof StoreService;

  async model(params: { form_document_id: string }) {
    const { form_document_id } = params;
    const pslfForm = await this.store.findRecord('pslf-form', form_document_id);
    const employerFormInfo = await this.store
      .adapterFor('pslf-form')
      .getEmployerFormInfo(form_document_id);
    return {
      pslfForm,
      employerFormInfo,
      documentId: form_document_id,
    };
  }
}
