import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import { not } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import Component from '@glimmer/component';
import FormCheckbox from '@frontile/forms-legacy/components/form-checkbox';
import type { IntlService } from 'ember-intl';
import type AccountModel from 'tio-common/models/account';
import type MatchPlanModel from 'tio-common/models/match-plan';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import TioButton from 'tio-common/components/tio/button';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import type { ErrorsSignature } from 'tio-common/components/tio/error-messages';
import AppContentCard from 'tio-common/components/app/content-card';
import CardGeneric from 'tio-common/components/card/generic';
import type RouterService from 'tio-employee/services/router';
interface SyfEnrollmentConfirmationSignature {
    Args: {
        accounts: AccountModel[];
        matchPlan: MatchPlanModel;
        onConfirmation: () => void;
        onCancel: () => void;
        loading: boolean;
        errors: ErrorsSignature | string | undefined;
    };
}
let SyfEnrollmentConfirmationComponent = class SyfEnrollmentConfirmationComponent extends Component<SyfEnrollmentConfirmationSignature> {
    @service
    intl: IntlService;
    @service
    router: RouterService;
    get activeAccounts() {
        return this.args.accounts?.filter((e1)=>!e1.deactivatedOn) || [];
    }
    get canEnroll() {
        // separate getter here since this will theoretically involve
        // more conditions than just this component state (ie actual)
        // terms and conditions
        // TODO: verify that this is actually doing what it's supposed
        // to under the hood
        return this.args.accounts.every((account1)=>!account1.isLoading);
    }
    // TODO: throttle this
    @action
    toggleBorrowerConfirmed(account1: AccountModel) {
        // NB: expecting this to mean something beyond just this local
        // component state at some point in the future
        account1.isVerified = !account1.isVerified;
        account1.save();
    }
    @action
    onAddStudentLoan() {
        this.router.transitionTo('authenticated.syf.account-linking');
    }
    @action
    mainTitle(account1: AccountModel) {
        return `${this.intl.t('account_linking.service_provider')}: ${account1.institutionName}`;
    }
    get showErrors() {
        return !!this.args.errors;
    }
    static{
        template(`
    {{! template-lint-disable no-bare-strings }}
    <h2 class="text-midnight text-large font-semibold mb-4">Benefit Enrollment Review</h2>
    <h3 class="font-semibold mb-2">The following are you benefit details</h3>
    <ul class="list-disc list-inside ml-4 mb-8">
      <li>
        {{@matchPlan.matchStatement}}
      </li>
      <li>
        {{@matchPlan.matchFrequencyDescription}}
      </li>
    </ul>
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 mb-12 gap-8">
      {{#each this.activeAccounts as |account|}}
        <AppContentCard @title={{this.mainTitle account}} @titleClass="mb-6" class="col-span-1">
          <CardGeneric class="!bg-white m:h-48 mb-6">
            <:content>
              <dl class="grid grid-cols-2 mb-4 text-sm">
                <dt class="mb-7 mr-4">Borrower Name</dt>
                <dd class="mb-7 font-semibold">{{account.observation.pii.name}}</dd>
                <dt class="mb-7 mr-4">Expected Monthly Payment (\$)</dt>
                <dd class="mb-7 font-semibold">
                  {{safeFormatNumber
                    account.providerRegularMonthlyPayment
                    style="currency"
                    currency="USD"
                  }}
                </dd>
              </dl>
            </:content>
          </CardGeneric>
          <div>
            <p class="mb-6">
              By selecting Yes, the payments you make to this account will be used towards your
              retirement matching.
            </p>
          </div>
          <div>
            <FormCheckbox
              data-legacy-input
              @checked={{account.isVerified}}
              @onChange={{fn this.toggleBorrowerConfirmed account}}
              @containerClass="font-semibold"
              class="mb-8"
              @label={{@matchPlan.ensureVerifyAccountStatement}}
            />
          </div>
        </AppContentCard>
      {{/each}}
    </div>

    <section>
      <TioErrorMessages
        @showErrors={{this.showErrors}}
        @error={{@errors}}
        @icon="error"
        class="text-center mb-8"
      />
      <div class="flex flex-col-reverse md:flex-row md:justify-center items-center gap-4">
        <TioButton @onClick={{@onCancel}} @outlined={{true}} class="mx-8 w-48">
          Cancel
        </TioButton>
        <TioButton
          @disabled={{not this.canEnroll}}
          @loading={{@loading}}
          @onClick={{@onConfirmation}}
          class="mx-8 w-48"
        >
          Enroll
        </TioButton>
      </div>
    </section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default SyfEnrollmentConfirmationComponent;
