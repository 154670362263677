import Route from '@ember/routing/route';
import { service } from '@ember/service';
import ENV from 'tio-employee/config/environment';
import { datadogRum } from '@datadog/browser-rum';
import type PreRegisterService from '../services/pre-register';
import type SessionService from '../services/session';
import type ZendeskService from '../services/zendesk';
import type FeaturesService from 'tio-common/services/features';
import type { IntlService } from 'ember-intl';
import type Transition from '@ember/routing/transition';
import type RouterService from '@ember/routing/router-service';
import type VueIntegrationService from 'tio-employee/services/vue-integration';
import type PartnerService from 'tio-employee/services/partner';

export default class ApplicationRoute extends Route {
  @service declare intl: IntlService;
  @service declare session: SessionService;
  @service declare preRegister: PreRegisterService;
  @service declare zendesk: ZendeskService;
  @service declare features: FeaturesService;
  @service declare router: RouterService;
  @service declare vueIntegration: VueIntegrationService;
  @service declare partner: PartnerService;

  constructor() {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments);

    this.router.on('routeWillChange', (transition: Transition) => {
      if (transition.to?.name === 'authenticated.loading') {
        return;
      }

      const queryParams = transition.to?.queryParams;
      const isEmberEnabled =
        queryParams?.['ember-enroll'] === '1' ||
        queryParams?.['ember-register'] === '1' ||
        queryParams?.['ember-forgot-password'] === '1' ||
        queryParams?.['ember-reset-password'] === '1';

      if (
        transition.to?.name &&
        ['register.index', 'enroll', 'forgot-password.index', 'reset-password.index'].includes(
          transition.to?.name
        ) &&
        !isEmberEnabled
      ) {
        this.vueIntegration.iframe.style.display = 'block';
        return;
      }

      if (
        transition.to?.name === 'authenticated.tuition-assistance.index' &&
        this.partner.hasLegacyTuitionAssistance
      ) {
        this.vueIntegration.iframe.style.display = 'block';
        return;
      }

      this.vueIntegration.iframe.style.display = 'none';
    });
  }

  async beforeModel() {
    // @ts-expect-error: @Julia we need a strategy for unifying more of the ENV
    this.features.initWithEnvironment(ENV);
    await this.session.setup();
    this.intl.setLocale('en-us');

    if (ENV.datadog) {
      datadogRum.init({
        ...ENV.datadog,
      });
    }
  }

  model() {
    return this.preRegister.load();
  }

  afterModel() {
    this.zendesk.setVisibilityForEnvironment();
  }
}
