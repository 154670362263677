import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { formatDuration } from 'tio-common/utils/date/format-duration';
import { registerDestructor } from '@ember/destroyable';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import TioDialog from 'tio-common/components/tio/dialog';
import type SessionContextService from 'tio-employee/services/session-context';
import type SessionService from 'tio-employee/services/session';
export default class AuthSessionExpiringDialogComponent extends Component {
    @service
    session: SessionService;
    @service
    sessionContext: SessionContextService;
    #intervalId: ReturnType<typeof setInterval>;
    @tracked
    isShowing = false;
    @tracked
    timeRemainingFormatted = '';
    constructor(owner1: unknown, args1: never){
        super(owner1, args1);
        registerDestructor(this, this.teardown);
        this.setup();
    }
    setup() {
        // NOTE: The user needs to be able to renew their session and close this dialog, then have it
        //       reappear when `isSessionExpiring` changes to `true` again. I couldn't find any way to
        //       do this with tracked or computed properties and an observer is the logical choice for
        //       this. In this use case, `isShowing` is usually computed from `isSessionExpiring`, but
        //       is also programmatically set to `false` in the `close` method.[twl 18.Oct.23]
        // eslint-disable-next-line ember/no-observers
        this.session.addObserver('isSessionExpiring', this.isSessionExpiringChanged);
        this.isShowing = this.session.isSessionExpiring;
    }
    @action
    teardown() {
        this.session.removeObserver('isSessionExpiring', this.isSessionExpiringChanged);
    }
    @action
    isSessionExpiringChanged() {
        this.isShowing = this.session.isSessionExpiring;
    }
    @action
    startCountdown() {
        this.#intervalId = setInterval(this.updateTimeRemaining, 1000);
    }
    @action
    updateTimeRemaining() {
        this.timeRemainingFormatted = formatDuration(Math.floor((this.session.expireAt - Date.now()) / 1000));
    }
    @action
    close() {
        this.isShowing = false;
    }
    @action
    renew() {
        this.session.renew();
        this.#stopCountdown();
        this.close();
    }
    @action
    logout() {
        this.#stopCountdown();
        this.sessionContext.logout();
        this.close();
    }
    #stopCountdown() {
        if (this.#intervalId) {
            clearInterval(this.#intervalId);
            this.#intervalId = undefined;
        }
    }
    static{
        template(`
    <div>
      <TioDialog
        @show={{this.isShowing}}
        @onShow={{this.startCountdown}}
        @onConfirm={{this.renew}}
        @onCancel={{this.logout}}
        @onClose={{this.close}}
        @submitButtonText={{t "auth.session_expiring.renew_button"}}
        @cancelButtonText={{t "auth.session_expiring.logout_button"}}
        @bodyClass="lg:min-w-[400px]"
      >
        <:header>
          <h1 class="m-3 mb-0 font-semibold text-2xl text-gray-800">
            {{t "auth.session_expiring.title"}}
          </h1>
        </:header>
        <:body>
          {{t "auth.session_expiring.message" time=this.timeRemainingFormatted}}
        </:body>
      </TioDialog>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
