import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { TrackedArray } from 'tracked-built-ins';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type ConditionModel from 'tio-common/models/condition';
import { fn } from '@ember/helper';
import TioPdfModal from 'tio-common/components/tio/pdf-modal';
import includes from 'ember-composable-helpers/helpers/includes';
import { t } from 'ember-intl';
import TioDialog from 'tio-common/components/tio/dialog';
import TioButton from 'tio-common/components/tio/button';
import FormCheckbox from '@frontile/forms-legacy/components/form-checkbox';
import embedPdf from 'tio-common/modifiers/embed-pdf';
import { htmlSafe } from '@ember/template';
import { on } from '@ember/modifier';
export interface TuitionAssistanceFormsAcceptConditionsSignature {
    Args: {
        conditions: readonly ConditionModel[];
        onChange: (value: ConditionModel[]) => void;
    };
}
export default class TuitionAssistanceFormsAcceptConditionsComponent extends Component<TuitionAssistanceFormsAcceptConditionsSignature> {
    // TODO: Not sure this is the correct type.
    @tracked
    conditionToView?: ConditionModel;
    acceptedTerms: ConditionModel[];
    constructor(owner1: unknown, args1: TuitionAssistanceFormsAcceptConditionsSignature['Args']){
        super(owner1, args1);
        this.acceptedTerms = new TrackedArray([]);
    }
    get isPdfCondition() {
        return this.conditionToView?.hasPdf;
    }
    @action
    didChangeAcceptanceOfTerm(condition1: ConditionModel, value1?: string) {
        if (value1) {
            // @ts-expect-error: ED needs types
            this.acceptedTerms.addObject(condition1);
        } else {
            // @ts-expect-error: ED needs types
            this.acceptedTerms.removeObject(condition1);
        }
        this.args.onChange?.([
            ...this.acceptedTerms
        ]);
    }
    static{
        template(`
    <div class="flex flex-col items-center justify-center my-6">
      {{#each @conditions as |condition|}}
        <div class="flex flex-col md:flex-row items-center text-base my-2">
          <FormCheckbox
            data-legacy-input
            @label={{condition.prompt}}
            @size="lg"
            @checked={{includes condition this.acceptedTerms}}
            {{! @glint-expect-error: mixing string and boolean }}
            @onChange={{fn this.didChangeAcceptanceOfTerm condition}}
          />
          <button
            type="button"
            class="mx-2 tio-copy w-40"
            {{on "click" (fn (mut this.conditionToView) condition)}}
          >
            {{t "click_here_to_view"}}
          </button>
        </div>
      {{/each}}
    </div>

    {{#if this.conditionToView}}
      {{#if this.conditionToView.hasPdf}}
        <TioPdfModal
          @show={{true}}
          {{! @glint-expect-error: mixing null and undefined }}
          @onClose={{fn (mut this.conditionToView) null}}
          @closeButtonText={{t "close"}}
        >
          <div {{embedPdf this.conditionToView.pdfUrl}}></div>
        </TioPdfModal>
      {{else}}
        <TioDialog
          @show={{true}}
          {{! @glint-expect-error: mixing null and undefined }}
          @onCancel={{fn (mut this.conditionToView) null}}
          {{! @glint-expect-error: mixing null and undefined }}
          @onConfirm={{fn (mut this.conditionToView) null}}
          @containerClass="w-full md:max-w-screen-md"
        >
          <:header>
            <p class="font-semibold">{{this.conditionToView.prompt}}</p>
          </:header>
          <:body>
            {{htmlSafe this.conditionToView.fullText}}
          </:body>
          <:actions as |dialogActions|>
            <div class="flex justify-center">
              <TioButton @onClick={{dialogActions.confirm}} class="ml-4 sm:min-w-48 sm:w-fit">
                {{t "close"}}
              </TioButton>
            </div>
          </:actions>
        </TioDialog>
      {{/if}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
