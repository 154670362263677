import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { not } from 'ember-truth-helpers';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import errorsForField from 'tio-common/helpers/errors-for-field';
import FormInput from '@frontile/forms-legacy/components/form-input';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import MaterialIcon from 'tio-common/components/material-icon';
import noLinkedLoansReasonSchema from 'tio-common/validation-schema/forms/no-linked-loans-reason';
import { t } from 'ember-intl';
import TioAlert from 'tio-common/components/tio/alert';
import TioButton from 'tio-common/components/tio/button';
import type Errors from 'tio-employee/types/errors';
import type { IntlService } from 'ember-intl';
import type PersonModel from 'tio-common/models/person';
import urlFor from 'ember-router-helpers/helpers/url-for';
// import slr from 'tio-employee/templates/authenticated/slr';
export interface AlertNoLinkedLoansSignature {
    Args: {
        person: PersonModel;
        didSave?: (person: PersonModel) => void;
    };
}
export default class AlertNoLinkedLoansComponent extends Component<AlertNoLinkedLoansSignature> {
    @service
    intl: IntlService;
    @tracked
    reason = '';
    @tracked
    explanation = '';
    @tracked
    hasSubmitted = false;
    @tracked
    submitError?: Errors;
    get canSubmit() {
        return this.reason && this.explanation;
    }
    @action
    submit() {
        this.submitTask.perform();
    }
    submitTask = dropTask(async ()=>{
        delete this.submitError;
        this.hasSubmitted = true;
        if (this.formValidationErrors.length) {
            return;
        }
        try {
            const saved1 = await this.saveFormValues.perform();
            this.hasSubmitted = false;
            this.args.didSave?.(saved1);
        } catch (e1) {
            this.submitError = e1;
            console.error(e1);
        }
    });
    saveFormValues = dropTask(async ()=>{
        const person1 = this.args.person;
        const existingPreferences1 = person1.preferences;
        const updatedPreferences1 = {
            ...existingPreferences1,
            'reason-for-not-linking-loans': this.reason,
            'explanation-for-not-linking-loans': this.explanation
        };
        person1.preferences = updatedPreferences1;
        await person1.save();
        return person1;
    });
    get formValidationErrors() {
        const schema1 = noLinkedLoansReasonSchema;
        const formModel1 = {
            'reason-for-not-linking-loans': this.reason,
            'explanation-for-not-linking-loans': this.explanation
        };
        try {
            schema1?.validateSync?.(formModel1, {
                abortEarly: false
            });
        } catch (err1) {
            return err1.inner || [];
        }
        return [];
    }
    get serverErrorMessages() {
        const genericErrorMessage1 = this.intl.t('error_occurred');
        if (this.submitError?.errors) {
            return this.submitError.errors.map((err1: Errors['errors'][number])=>{
                return err1?.detail || genericErrorMessage1;
            });
        } else if (this.submitError) {
            return [
                genericErrorMessage1
            ];
        }
        return [];
    }
    static{
        template(`
    <TioAlert @type="warning" @allowDismiss={{false}} class="text-sm">
      <:body>
        <p class="mb-4 text-md">
          {{t "no_linked_accounts_alert.warning"}}
          {{t
            "no_linked_accounts_alert.warning_body"
            htmlSafe=true
            hrefLink=(urlFor "authenticated.slr.linking")
            classText="text-ocean-600 font-semibold"
          }}
        </p>
        <TioButton @linkTo="authenticated.slr.linking" class="w-fit my-4">
          <MaterialIcon @icon="add" class="text-lg mr-1 leading-none" />
          {{t "no_linked_accounts_alert.add_loans_btn"}}
        </TioButton>

        <p class="font-semibold">
          {{t "no_linked_accounts_alert.not_adding_loans"}}
        </p>

        <form class="mx-1 md:mx-4">
          <FormRadioGroup
            data-legacy-input
            @containerClass="my-2 no-linked-loans"
            @hasSubmitted={{this.hasSubmitted}}
            @errors={{errorsForField "reason" schemaErrors=this.formValidationErrors}}
            @required="true"
            @value={{this.reason}}
            {{! @glint-expect-error: look into better option to mut}}
            @onChange={{fn (mut this.reason)}}
            as |Radio|
          >
            {{! @glint-expect-error: look into frontile types}}
            <Radio @label={{t "no_linked_accounts_alert.dont_want_add"}} @value="dont_want_add" />
            {{! @glint-expect-error: look into frontile types}}
            <Radio @label={{t "no_linked_accounts_alert.unable_add"}} @value="unable_add" />
          </FormRadioGroup>
          <FormInput
            data-legacy-input
            @value={{this.explanation}}
            @onInput={{fn (mut this.explanation)}}
            required="true"
            placeholder={{t "no_linked_accounts_alert.enter_reason"}}
            @containerClass="max-w-lg"
            @hasSubmitted={{this.hasSubmitted}}
            @errors={{errorsForField "explanation" schemaErrors=this.formValidationErrors}}
          >
            <TioButton class="ml-2 px-6" @onClick={{this.submit}} @disabled={{not this.canSubmit}}>
              {{t "submit"}}
            </TioButton>
          </FormInput>
        </form>
      </:body>
    </TioAlert>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
