import ApplicationAdapter from './application';
import type PslfFormModel from 'tio-common/models/pslf-form';

type Signer = {
  role: string;
  email: string;
  order: number;
};

interface IEmployerFormInfo {
  companyName: string;
  borrowerName: string;
  borrowerSignDate: string | null;
  employerSignDate: string | null;
  phone: string;
  email: string;
  verifyWithEmail: boolean;
  status: string;
  pslfFormId: string;
}

/**
 * An extended adapter for the `PslfForm` model.
 *
 * @memberof adapters
 */
class PslfFormAdapter extends ApplicationAdapter {
  async getEmployerFormInfo(formDocumentId: string): Promise<IEmployerFormInfo> {
    const url = this.buildURL('pslf-forms') + '/employer/form-info';
    const response = await this.ajax(url, 'GET', {
      params: { id: formDocumentId },
    });
    return response.data as IEmployerFormInfo;
  }

  /**
   * Loads the status of the document for the employer workflow to see if it's been signed yet.
   *
   * @param formDocumentId - the ID of the document to check
   */
  async pollForEmployerStatus(formDocumentId: string): Promise<string | undefined> {
    try {
      const response = await this.api.get('pslf-forms/employer/invite-status', {
        params: { id: formDocumentId },
      });
      console.log('pollForEmployerStatus response', response);
      return response.data.status;
    } catch (error) {
      this.logError(error);
      return undefined;
    }
  }

  /**
   * Sends a verification email to the email address of the employer.
   *
   * @param {string} id - unclear from vue store which id this is
   * @param {string} channel - 'sms | 'call'
   */
  async sendEmployerVerificationEmail(id: string, channel: string): Promise<void> {
    const url = this.buildURL('pslf-forms/employer/generate-email-verification');
    await this.ajax(url, 'POST', {
      data: {
        id,
        channel,
      },
    });
  }

  async verifyNonPartnerEmployer(id: string, approvalId: string) {
    try {
      const url = this.buildURL(`pslf-forms`) + '/non-partner-employer/verify';
      const response = await this.ajax(url, 'POST', {
        data: {
          id: id,
          approvalId: approvalId,
        },
      });
      return response;
    } catch (error) {
      if (error) {
        throw new Error('Bad request. Please check the employer data.');
      }
      throw new Error('An unexpected error occurred while verifying the non-partner employer.');
    }
  }
  /**
   * Verifies that an email code the employer has entered is valid.
   *
   * @param {string} id  - The ID used to lookup the form info, which for security
   *                  purposes is not the form ID itself
   *
   * @param {string} verificationCode - the verification code entered by the employer
   */
  async verifyEmployerEmail(id: string, verificationCode: string) {
    const url = this.buildURL('pslf-forms/employer/verify-email');
    try {
      const response = await this.ajax(url, 'POST', {
        data: {
          id,
          verificationCode,
        },
      });

      return {
        verified: true,
        ...response.data,
      };
    } catch (error) {
      this.logError(error);

      return {
        verified: false,
      };
    }
  }

  /**
   * Prepares a form for a NON-PARTNER employer signature by filling it in with the values contained in
   * `entity`, updating the store with an updated entity that contains the `embedUrl`.
   *
   * @param {string} approvalId - The hashed approval ID for this form
   * @param {PslfFormModel} formData  - The form to prepare for signature
   */
  async prepareForNonPartnerEmployerSignature(
    approvalId: string,
    formData: Record<string, string | number>
  ) {
    const url = this.buildURL('pslf-forms') + '/non-partner-employer/embed-document';
    const response = await this.ajax(url, 'POST', {
      data: {
        approvalId: approvalId,
        employerApartment: formData.employerApartment,
        employerCity: formData.employerCity,
        employerFein: formData.employerEin,
        employerName: formData.employerName,
        employerState: formData.employerState,
        employerStreetAddress: formData.employerStreetAddress,
        employerWebsite: formData.employerWebsite,
        employerZip: formData.employerZip,
        employmentContinues: formData.employmentContinues,
        employmentEndDate: formData.employmentEndDate,
        employmentHours: formData.employmentHours,
        employmentStartDate: formData.employmentStartDate,
        employmentStatus: formData.employmentStatus,
        id: formData.id,
      },
    });
    return { embedUrl: response.embedUrl, status: response.status };
  }

  /**
   * @param {PslfForm}  pslfForm - the PslfForm model for which to perform the action
   * @param {string} actionEndpoint - the server endpoint to append (ex. '/cancel')
   * @return {PslfForm}
   */
  async triggerFormActionAndReload(pslfForm: PslfFormModel, actionEndpoint: string) {
    if (!pslfForm || !actionEndpoint) {
      return;
    }
    const url = this.buildURL('pslf-form', pslfForm.id) + actionEndpoint;
    await this.ajax(url, 'PATCH');
    const updatedForm = await pslfForm.reload();
    return updatedForm;
  }

  /**
   * @param {PslfForm}  pslfForm - the PslfForm model to cancel
   * @return {PslfForm}
   */
  async cancel(pslfForm: PslfFormModel) {
    return this.triggerFormActionAndReload(pslfForm, '/cancel');
  }

  /**
   * @param {PslfForm}  pslfForm - the PslfForm model for which to send reminder
   * @return {PslfForm}
   */
  async resendEmployerEmail(pslfForm: PslfFormModel) {
    return this.triggerFormActionAndReload(pslfForm, '/notify-employer-sign-reminder');
  }

  /**
   * Prepares a form for an employer signature by filling it in with the values contained in
   * `entity`, updating the store with an updated entity that contains the `embedUrl`.
   *
   * @param pslfForm  - The form to prepare for signature
   */

  async prepareForEmployerSignature(pslfForm: PslfFormModel) {
    const url = this.buildURL('pslf-forms') + `/employer/embed-document`;
    const response = await this.ajax(url, 'POST', {
      data: {
        id: pslfForm.documentId,
        employmentHours: pslfForm.employerFields['employment-hours'],
        employmentStartDate: pslfForm.employerFields['employment-start-date'],
        employmentEndDate: pslfForm.employerFields['employment-end-date'],
        employmentContinues: pslfForm.employerFields['employment-continues'],
        employmentStatus: pslfForm.employerFields['employment-status'],
      },
    });
    return { embedUrl: response.embedUrl, status: response.status };
  }

  async prepareForBorrowerSignature(
    pslfForm: PslfFormModel,
    signers: [Signer, Signer],
    templateId: string,
    borrowerSsn: string
  ) {
    const url = this.buildURL('pslf-forms') + `/${pslfForm.id}/borrower-embed-document`;
    const response = await this.ajax(url, 'POST', {
      data: {
        templateId,
        signers,
        currentSigner: 'Borrower',
        borrowerSsn,
        useNewEmbedFlow: true,
      },
    });
    return { embedUrl: url, status: response.status };
  }

  async prepareForBorrowerPreApprovedSignature(
    pslfForm: PslfFormModel,
    signers: [Signer],
    templateId: string,
    borrowerSsn: string
  ) {
    //borrowerSsn = '';
    const url =
      this.buildURL('pslf-forms') + `/${pslfForm.id}/borrower-pre-approved-embed-document`;
    const response = await this.ajax(url, 'POST', {
      data: {
        templateId,
        signers,
        currentSigner: 'Borrower',
        borrowerSsn,
        useNewEmbedFlow: true,
      },
    });
    return { embedUrl: url, status: response.status };
  }
}

export default PslfFormAdapter;
