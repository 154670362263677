import { template } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import type PslfFormModel from 'tio-common/models/pslf-form';
import { t } from 'ember-intl';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import { eq, not, or } from 'ember-truth-helpers';
import { concat } from '@ember/helper';
import TioPreviewButton from 'tio-common/components/tio/preview-button';
import ActionButton from 'tio-common/components/pslf/my-documents/action-button';
import { fn } from '@ember/helper';
interface S {
    Args: {
        doFormAction: (action: string, form: PslfFormModel) => void;
        formToResend: PslfFormModel;
        model: PslfFormModel;
        openPreviewModal: (form: PslfFormModel) => void;
    };
    Element: HTMLDivElement;
}
const PslfMyDocumentsMyFormsTableCardComponent: TOC<S> = template(`
  <div
    class="text-gray-600 border border-gray-300 rounded first:rounded-t-none p-2 pr-4 mb-2 flex hover:shadow-lg"
    ...attributes
  >
    <div class="w-full overflow-hidden">
      {{! Title }}
      <div class="grid grid-cols-3 mb-px text-ocean-600 font-bold truncate">
        {{#if @model.isPriorEmployer}}
          {{t "pslf.form-type.priorEmployer"}}
          -
          {{@model.priorEmployerName}}
        {{else}}
          {{t "pslf.form-type.currentEmployer"}}
          -
          {{@model.employerDisplayName}}
        {{/if}}
      </div>

      {{! Content }}
      <div class="grid grid-cols-2 gap-1 sm:ml-20 mb-3 text-sm">
        <h1 class="font-semibold">
          {{t "pslf.document_repo.date_employer_signed"}}:
        </h1>
        <div class="place-content-start">
          {{#let @model.employerCompletedAt as |date|}}
            <span title={{date}}>
              {{#if date}}
                {{safeFormatDate date month="short" day="2-digit" year="numeric"}}
              {{else if (or @model.isPrintAndMail @model.isPriorEmployerClosed)}}
                {{t "pslf.table.not_applicable"}}
              {{else if @model.isAwaitingSignature}}
                {{t "pslf.table.pending"}}
              {{/if}}
            </span>
          {{/let}}
        </div>

        <h1 class="font-semibold">
          {{t "pslf.document_repo.date_you_signed"}}:
        </h1>
        <div class="place-content-start">
          {{#let @model.borrowerCompletedAt as |date|}}
            <span title={{date}}>
              {{#if date}}
                {{safeFormatDate date month="short" day="2-digit" year="numeric"}}
              {{else}}
                {{t "pslf.table.pending"}}
              {{/if}}
            </span>
          {{/let}}
        </div>

        <h1 class="font-semibold">
          {{t "status"}}:
        </h1>
        <div>
          {{t (concat "pslf.status." @model.status)}}
        </div>

        <h1 class="font-semibold">
          {{t "pslf.document_repo.approver_info"}}:
        </h1>
        <div class="truncate">
          {{@model.approverDisplayEmail}}
        </div>

        <h1 class="font-semibold">
          {{t "pslf.document_repo.form_type_and_employer"}}:
        </h1>
        <div class="truncate">
          {{t (concat "pslf.form-type." @model.formType)}}
          <br />
          {{#if @model.isPriorEmployer}}
            -
            {{@model.priorEmployerName}}
          {{else}}
            {{@model.employerDisplayName}}
          {{/if}}
        </div>
      </div>
      <div class="flex justify-center">
        <TioPreviewButton
          class="px-2"
          @onClick={{fn @openPreviewModal @model}}
          @isDisabled={{@model.isAwaitingSignature}}
        />
        <ActionButton
          @icon="schedule_send"
          @label={{t "pslf.document_repo.resend"}}
          @loading={{eq @formToResend.id @model.id}}
          @isDisabled={{not @model.canResendEmail}}
          @onClick={{fn @doFormAction "resend" @model}}
        />
        <ActionButton
          @icon="mark_email_read"
          @isDisabled={{not @model.canChangeApprover}}
          @label={{t "pslf.document_repo.change_approver"}}
          @loading={{false}}
          @onClick={{fn @doFormAction "changeApprover" @model}}
        />
        <ActionButton
          @icon="block"
          @isDisabled={{not @model.canCancel}}
          @label={{t "disable"}}
          @loading={{false}}
          @onClick={{fn @doFormAction "cancel" @model}}
          class={{if @model.canCancel "!text-red-500"}}
        />
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PslfMyDocumentsMyFormsTableCardComponent;
