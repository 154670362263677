import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import AppPage from 'tio-employee/components/app/page';
import TioPageHeaderTitle from 'tio-common/components/tio/page/header/title';
import AppPageContent from 'tio-employee/components/app/page/content';
import { t } from 'ember-intl';
import PslfSignFormPage from 'tio-employee/components/pslf/sign-form-page';
import type PslfFormModel from 'tio-common/models/pslf-form';
import type PslfAdminFormSignController from 'tio-employee/controllers/authenticated/admin/pslf/form/sign';
export interface S {
    Args: {
        model: PslfFormModel;
        controller: PslfAdminFormSignController;
    };
}
export default RouteTemplate<S>(template(`
    <AppPage>
      <TioPageHeaderTitle>
        {{t "pslf.default_full"}}
      </TioPageHeaderTitle>
      <AppPageContent>
        <PslfSignFormPage @form={{@model}} @signer={{@controller.signerType}} />
      </AppPageContent>
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
