import { template } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { hash } from '@ember/helper';
import { on } from '@ember/modifier';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import optional from 'ember-composable-helpers/helpers/optional';
import { eq } from 'ember-truth-helpers';
interface AppHeaderCurrentUserMenuItemSig {
    Args: {
        icon: string;
        items: unknown;
        onClick?: () => void;
        route?: string;
        title: string;
    };
}
const AppHeaderCurrentUserMenuItemComponent: TOC<AppHeaderCurrentUserMenuItemSig> = template(`
  {{! @glint-expect-error: have no idea what the type should be }}
  {{#let (component @items.Item) as |MenuItem|}}
    <MenuItem as |item|>
      <item.Element
        class="px-4 py-2 flex items-center text-gray-500 cursor-pointer
          {{if item.isActive 'bg-tio-gray-100'}}
          "
        {{on
          "click"
          (if @route (transitionTo @route (hash queryParams=(hash))) (optional @onClick))
        }}
      >
        {{#if (eq @icon "\$blank")}}
          <span class="mr-2 w-6 h-6"></span>
        {{else if @icon}}
          <span class="inline-flex mr-2 material-icons">{{@icon}}</span>
        {{/if}}

        {{@title}}
      </item.Element>
    </MenuItem>
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppHeaderCurrentUserMenuItemComponent;
