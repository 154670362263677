import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import AppPage from 'tio-employee/components/app/page';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TioAlert from 'tio-common/components/tio/alert';
import TioPageHeaderTitle from 'tio-common/components/tio/page/header/title';
import TuitionAssistanceDashboardApplyToPrograms from 'tio-employee/components/tuition-assistance/dashboard/apply-to-programs';
import TuitionAssistanceDashboardMyPaymentActivity from 'tio-employee/components/tuition-assistance/dashboard/my-payment-activity';
import TuitionAssistanceDashboardMyPrograms from 'tio-employee/components/tuition-assistance/dashboard/my-programs';
import type { LimitsStatusDataSignature } from 'tio-common/services/tuition-assistance';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import TuitionAssistanceDashboardLpnBanner from 'tio-employee/components/tuition-assistance/dashboard/lpn-banner';
export interface S {
    Args: {
        model: {
            isHeldParticipant: boolean;
            activeInstances: TasProgramInstanceModel[];
            templates: TasProgramTemplate[];
            pastInstances: TasProgramInstanceModel[];
            limitsStatus: LimitsStatusDataSignature;
        };
    };
}
export default RouteTemplate<S>(template(`
    {{pageTitle (t "dashboard")}}
    <AppPage>
      <TioPageHeaderTitle>
        {{t "tuition_assistance.default"}}
      </TioPageHeaderTitle>

      {{#if @model.isHeldParticipant}}
        <TioAlert @type="warning" class="my-4" @allowDismiss={{true}}>
          <:header>
            <p class="font-bold">
              {{t "tuition_assistance.held_participant_warnings.dashboard_title"}}
            </p>
          </:header>
          <:body>
            <p class="text-sm">
              {{t "tuition_assistance.held_participant_warnings.dashboard_description"}}
            </p>
          </:body>
        </TioAlert>
      {{/if}}
      <TuitionAssistanceDashboardLpnBanner />
      <h2 class="text-lg font-semibold mt-8">
        {{t "tuition_assistance.dashboard.payment_activity.payments"}}
      </h2>
      <h3 class="font-semibold mt-4">
        {{t "tuition_assistance.dashboard.payment_activity.total_payments"}}
      </h3>
      <p>
        {{t "tuition_assistance.dashboard.payment_activity.note"}}
      </p>
      <div class="pb-16">

        <TuitionAssistanceDashboardMyPaymentActivity
          class="m-2 mb-16"
          @limitsStatus={{@model.limitsStatus}}
        />
        <TuitionAssistanceDashboardMyPrograms
          @programStatus="active"
          @programInstances={{@model.activeInstances}}
          @sectionHeader={{t "tuition_assistance.dashboard.active_programs"}}
          class="mt-12 mb-12"
        />
        <TuitionAssistanceDashboardApplyToPrograms
          @programTemplates={{@model.templates}}
          @programInstances={{@model.activeInstances}}
          class="mb-12"
        />
        {{#if @model.pastInstances.length}}
          <TuitionAssistanceDashboardMyPrograms
            @programStatus="past"
            @programInstances={{@model.pastInstances}}
            @sectionHeader={{t "tuition_assistance.dashboard.past_programs"}}
            class="mb-12"
          />
        {{/if}}
      </div>
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
