import { template } from "@ember/template-compiler";
import { A } from '@ember/array';
import { action } from '@ember/object';
import { dropTask, all } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { htmlSafe } from '@ember/template';
import { object, string, boolean } from 'yup';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import errorsForField from 'tio-common/helpers/errors-for-field';
import FormCheckbox from '@frontile/forms-legacy/components/form-checkbox';
import FormInput from '@frontile/forms-legacy/components/form-input';
import includes from 'ember-composable-helpers/helpers/includes';
import TioButton from 'tio-common/components/tio/button';
import TioDialog from 'tio-common/components/tio/dialog';
import TioDialogDocumentAsset from 'tio-common/components/tio/dialog/document-asset';
import type { IntlService } from 'ember-intl';
import type ConditionModel from 'tio-common/models/condition';
import type EmailAddressModel from 'tio-common/models/email-address';
import type Errors from 'tio-employee/types/errors';
import type PreRegisterService from 'tio-employee/services/pre-register';
import type RouterService from '@ember/routing/router';
import type SessionContextService from 'tio-employee/services/session-context';
import type Store from '@ember-data/store';
const postAuthRegisterFormSchema = object({
    didAcceptAllTerms: boolean().required('The terms and conditions must be accepted.').oneOf([
        true
    ], 'The terms and conditions must be accepted.')
});
const requiredEmailSchema = object({
    email: string().required('* Email is required').email('Please enter a valid email address')
});
export interface AuthRegisterPostAuthTermsDialogSignature {
    Args: {
        primaryEmailAddress?: EmailAddressModel;
        didSave?: () => void;
    };
}
export default class AuthRegisterPostAuthTermsDialogComponent extends Component<AuthRegisterPostAuthTermsDialogSignature> {
    @service
    intl: IntlService;
    @service
    store: typeof Store;
    @service
    router: RouterService;
    @service
    preRegister: PreRegisterService;
    @service
    sessionContext: SessionContextService;
    @tracked
    hasSubmitted = false;
    @tracked
    submitError?: Errors;
    @tracked
    email = '';
    @tracked
    didAgreeAppTermsAndPrivacy = false;
    @tracked
    acceptedCustomTerms: ConditionModel[] = A([]);
    @tracked
    showingTermsOfUse = false;
    @tracked
    showingPrivacyPolicy = false;
    constructor(owner1: unknown, args1: AuthRegisterPostAuthTermsDialogSignature['Args']){
        super(owner1, args1);
        if (this.args.primaryEmailAddress) {
            this.email = this.args.primaryEmailAddress.email;
        }
    }
    get isSsoRegistration() {
        return this.sessionContext.isOtherProviderLogin;
    }
    get termsOfUse() {
        return this.preRegister.termsOfUse;
    }
    get privacyPolicy() {
        return this.preRegister.privacyPolicy;
    }
    get customTerms() {
        return this.preRegister.customTerms || [];
    }
    get didAcceptAllCustomTerms() {
        return this.acceptedCustomTerms.length === this.customTerms.length;
    }
    get didAcceptAllTerms() {
        return this.didAgreeAppTermsAndPrivacy && this.didAcceptAllCustomTerms;
    }
    get allConditionsToAccept(): ConditionModel[] {
        // @ts-expect-error: Once ED is typed this will go away.
        return [
            this.termsOfUse,
            this.privacyPolicy,
            ...this.customTerms
        ].compact();
    }
    get formModel() {
        return {
            email: this.email,
            didAcceptAllTerms: this.didAcceptAllTerms
        };
    }
    get formSchema() {
        return this.isSsoRegistration ? postAuthRegisterFormSchema.concat(requiredEmailSchema) : postAuthRegisterFormSchema;
    }
    get formValidationErrors() {
        const schema1 = this.formSchema;
        try {
            schema1?.validateSync?.(this.formModel, {
                abortEarly: false
            });
        } catch (err1) {
            return err1.inner || [];
        }
        return [];
    }
    get serverErrorMessages() {
        const genericErrorMessage1 = this.intl.t('error_occurred');
        if (this.submitError?.errors) {
            return this.submitError.errors.map((err1)=>{
                return err1?.detail || genericErrorMessage1;
            });
        } else if (this.submitError) {
            return [
                genericErrorMessage1
            ];
        }
        return [];
    }
    @action
    async submit() {
        this.submitTask.perform();
    }
    @action
    didChangeAcceptanceOfCustomTerm(condition1: ConditionModel, value1: boolean) {
        if (value1) {
            // @ts-expect-error: Once ED is typed this will go away.
            this.acceptedCustomTerms.addObject(condition1);
        } else {
            // @ts-expect-error: Once ED is typed this will go away.
            this.acceptedCustomTerms.removeObject(condition1);
        }
    }
    @action
    showDialog(type1: string, event1: Event) {
        event1.preventDefault();
        event1.stopPropagation();
        switch(type1){
            case 'terms':
                this.showingTermsOfUse = true;
                break;
            case 'privacy':
                this.showingPrivacyPolicy = true;
                break;
        }
    }
    submitTask = dropTask(async ()=>{
        delete this.submitError;
        this.hasSubmitted = true;
        if (this.formValidationErrors.length) {
            return;
        }
        try {
            await this.saveFormValues.perform();
            this.hasSubmitted = false;
            await this.args.didSave?.();
        } catch (e1) {
            this.submitError = e1;
            console.error(e1);
        }
    });
    saveFormValues = dropTask(async ()=>{
        const user1 = this.sessionContext.user;
        const agreements1 = this.allConditionsToAccept.map((condition1)=>{
            return this.store.createRecord('agreement', {
                user: user1,
                condition: condition1
            });
        });
        const emailAddress1 = this.args.primaryEmailAddress || this.store.createRecord('emailAddress', {
            contactType: 'PRIMARY',
            user: user1
        });
        emailAddress1.email = this.email;
        const promises1 = agreements1.map((agreement1)=>{
            return agreement1.save();
        });
        promises1.push(emailAddress1.save());
        const saved1 = await all(promises1);
        return saved1;
    });
    static{
        template(`
    <TioDialog @show={{true}} @removeCloseBtn={{true}} @containerClass="w-full sm:max-w-screen-md">
      <:body>
        <div class="flex flex-col items-center">
          <h1 class="text-4xl mb-4">
            {{if this.isSsoRegistration (t "login.sign_up") (t "login.terms_of_service_heading")}}
          </h1>

          <h2 class="text-xl mb-6 text-center">
            {{if
              this.isSsoRegistration
              (t "login.enroll_in_tuition_to_access")
              (t "login.please_accept_the_terms")
            }}
          </h2>
        </div>

        <form class="flex flex-col items-center my-4">
          {{#if this.isSsoRegistration}}
            <div class="max-w-[36rem]">
              <FormInput
                data-legacy-input
                @label={{t "login.email"}}
                @value={{this.email}}
                @onInput={{fn (mut this.email)}}
                @errors={{errorsForField "email" schemaErrors=this.formValidationErrors}}
                @hasSubmitted={{this.hasSubmitted}}
              />
              <div class="text-sm text-gray-500 my-2">
                {{t "login.please_use_email_for_notifications"}}
              </div>
            </div>
          {{/if}}

          <div class="my-2">
            <div class="flex flex-row items-start text-base my-2">
              <FormCheckbox
                data-legacy-input
                @size="lg"
                @checked={{this.didAgreeAppTermsAndPrivacy}}
                @onChange={{fn (mut this.didAgreeAppTermsAndPrivacy)}}
                aria-labelledby="appTermsAndPrivacy"
              />
              <div id="appTermsAndPrivacy" class="ml-1">
                {{t "login.i_have_read_and_agree_to_tuitions"}}
                <button class="tio-copy" type="button" {{on "click" (fn this.showDialog "terms")}}>
                  {{t "login.terms_of_service"}}
                </button>
                {{t "and"}}
                <button
                  class="tio-copy"
                  type="button"
                  {{on "click" (fn this.showDialog "privacy")}}
                >
                  {{t "login.privacy_policy"}}.
                </button>
              </div>
            </div>

            {{#each this.customTerms as |condition|}}
              <div class="flex flex-row items-start text-base my-2">
                <FormCheckbox
                  data-legacy-input
                  @size="lg"
                  @checked={{includes condition this.acceptedCustomTerms}}
                  @onChange={{fn this.didChangeAcceptanceOfCustomTerm condition}}
                  aria-labelledby={{condition.id}}
                />
                <div id={{condition.id}} class="ml-1 tio-terms-and-conditions">
                  {{htmlSafe condition.fullText}}
                </div>
              </div>
            {{/each}}
          </div>
        </form>
      </:body>
      <:actions>
        <div class="flex justify-center mb-3">
          <TioButton
            @onClick={{this.submit}}
            @loading={{this.submitTask.isRunning}}
            @disabled={{this.formValidationErrors.length}}
            class="min-w-48"
          >
            {{t "login.agree_text"}}
          </TioButton>
        </div>
      </:actions>
    </TioDialog>

    <TioDialogDocumentAsset
      @title="Privacy Policy"
      {{! @glint-expect-error: should privacy be optional? }}
      @assetName={{this.privacyPolicy.staticJsonAssetName}}
      @show={{this.showingPrivacyPolicy}}
      @onCancel={{fn (mut this.showingPrivacyPolicy) false}}
    />

    <TioDialogDocumentAsset
      @title="Terms of Service"
      {{! @glint-expect-error: should privacy be optional? }}
      @assetName={{this.termsOfUse.staticJsonAssetName}}
      @show={{this.showingTermsOfUse}}
      @onCancel={{fn (mut this.showingTermsOfUse) false}}
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
