import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { array } from '@ember/helper';
import { object, string } from 'yup';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import TioForm from 'tio-common/components/tio/form';
import TioLoadingMessages from 'tio-common/components/tio/loading-messages';
import type PreRegisterService from 'tio-employee/services/pre-register';
import type RouterService from 'tio-employee/services/router';
import type SessionService from 'tio-employee/services/session';
interface AuthLoginLoginFormComponentSignature {
    Args: {
        email: string;
    };
}
export default class AuthLoginLoginFormComponent extends Component<AuthLoginLoginFormComponentSignature> {
    @service
    router: RouterService;
    @service
    session: SessionService;
    @service
    preRegister: PreRegisterService;
    @tracked
    initialFormData: {
        email: string;
        password: string;
    };
    constructor(owner1: unknown, args1: AuthLoginLoginFormComponentSignature['Args']){
        super(owner1, args1);
        this.initialFormData = {
            email: args1.email,
            password: ''
        };
    }
    formSchema = object({
        email: string().required('* Required').email('The Email field must be a valid email'),
        password: string().required('* Required')
    });
    @action
    async authenticate({ email: email1, password: password1 }: {
        email: string;
        password: string;
    }) {
        this.router.detectRedirectParameter();
        try {
            await this.session.authenticate('authenticator:database', email1, password1, {
                noTransitionAfterAuth: true
            });
            console.log('Authenticated with Ember');
            if (this.session.isAuthenticated) {
                this.router.transitionTo('/dashboard');
            }
        } catch (error1) {
            if (error1.reasons) {
                console.error('Vue login errors:', error1.reasons);
            } else if (error1.details) {
                console.error(`Ember login error (${error1.message}):`, error1.details.error ?? error1.details);
            }
            throw new Error(error1.details?.error ?? error1.message ?? error1.error ?? error1);
        }
    }
    static{
        template(`
    <TioForm
      class="mx-2 mb-10"
      @i18nPath="login.form"
      @schema={{this.formSchema}}
      @onSubmit={{this.authenticate}}
      @data={{this.initialFormData}}
      as |form|
    >
      <form.InputField @containerClass="mb-10" @name="email" @type="email" />

      <form.InputField
        @containerClass="mb-4"
        @name="password"
        @type="password"
        @autocomplete="current-password"
      />

      <div class="flex justify-end">
        <a class="underline text-ocean-600" href="/forgot-password">
          {{t "login.forgot_password_question"}}
        </a>
      </div>

      <form.Errors class="mt-6" />

      {{#if form.submissionState.isPending}}
        <div class="mt-6 text-center">
          <TioLoadingMessages
            @i18nPath="login.loading_messages"
            @messageKeys={{array
              "verify_credentials"
              "wait_for_system_warmup"
              "thank_you_for_your_patience"
              "gather_account_details"
              "fetch_company_data"
            }}
          />
        </div>
      {{/if}}
      <form.Button class="w-36 mt-10 mx-auto" @type="submit" @disableWhenInvalid={{false}} />
    </TioForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
