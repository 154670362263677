import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { and, eq, gte, or } from 'ember-truth-helpers';
import { concat } from '@ember/helper';
import { fn } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AppContentCard from 'tio-common/components/app/content-card';
import Component from '@glimmer/component';
import ForgivenessEstimatorResultInstructions from './result-instructions';
import type IntlService from 'ember-intl/services/intl';
import type RecommendationModel from 'tio-common/models/recommendation';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import type StrategyFinderService from 'tio-common/services/strategy-finder';
import TioDialog from 'tio-common/components/tio/dialog';
import TioDownloadButton from 'tio-common/components/tio/download-button';
import TioPageHorizontalRule from 'tio-common/components/tio/page/horizontal-rule';
import TioPdfPrintButton from 'tio-common/components/tio/pdf/print-button';
export interface FEResultsDashboardTableSignature {
    Args: {
        programType: string;
        recommendation: RecommendationModel;
    };
    Element: HTMLElement;
    Blocks: {
        default: [];
    };
}
export default class ResultsDashboardTable extends Component<FEResultsDashboardTableSignature> {
    @service
    intl: IntlService;
    @service
    strategyFinder: StrategyFinderService;
    @tracked
    toggleDialog = false;
    get estimatedMonthlyPayment() {
        return this.args.recommendation?.estimatedMonthlyPayment;
    }
    get estimatedForgivenessAmount() {
        return this.args.recommendation?.estimatedForgivenessAmount;
    }
    get estimatedForgivenessDate() {
        return this.args.recommendation?.estimatedForgivenessDate;
    }
    get currentPlanResults() {
        return this.args.recommendation?.formattedCurrentPlanSummary;
    }
    get bestPlanResults() {
        return this.args.recommendation.formattedBestPlanSummary;
    }
    get workflowRoute() {
        return this.args.programType === 'pslf' ? 'authenticated.pslf.dashboard.forgiveness-estimator' : this.args.programType === 'sf' ? 'authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.questionnaire' : 'authenticated.idr.dashboard.forgiveness-estimator';
    }
    get verticalHeaderRows() {
        return [
            {
                title: this.intl.t('recommendations_estimator.estimated_total_to_pay'),
                dataKey: 'payoffAmount',
                formatter: 'formatMoney'
            },
            {
                title: this.intl.t('recommendations_estimator.years_left_to_repay'),
                dataKey: 'termMonths',
                formatter: 'formatTermMonths'
            },
            {
                title: this.intl.t('recommendations_estimator.monthly_payment'),
                dataKey: 'monthlyPayments',
                formatter: 'formatMoney',
                asterisk: true
            },
            {
                title: this.intl.t('recommendations_estimator.estimated_total_interest_paid'),
                dataKey: 'totalInterestPaid',
                formatter: 'formatMoney'
            },
            {
                title: this.intl.t('recommendations_estimator.estimated_amount_forgiven'),
                subtitle: this.intl.t('recommendations_estimator.estimated_amount_forgiven_includes_interest'),
                dataKey: 'remLoanBalance',
                formatter: 'formatMoney'
            }
        ];
    }
    get horizontalHeaderColumns() {
        switch(this.args.programType){
            case 'pslf':
                return [
                    {
                        title: this.intl.t('recommendations_estimator.best_plan')
                    },
                    {
                        title: this.intl.t('recommendations_estimator.current_plan')
                    }
                ];
            case 'idr':
                return [
                    {
                        title: this.intl.t('idr.abbreviated')
                    },
                    {
                        title: this.intl.t('recommendations_estimator.current_plan')
                    }
                ];
            case 'sf':
                return [
                    {
                        title: this.intl.t('common.strategy_finder.default')
                    },
                    {
                        title: this.intl.t('recommendations_estimator.current_plan')
                    }
                ];
            default:
                return [];
        }
    }
    get tableRowData() {
        return this.verticalHeaderRows.map((row1)=>{
            // @ts-expect-error: need real types
            const col1Value1 = this.bestPlanResults[row1.dataKey];
            // @ts-expect-error: need real types
            const col2Value1 = this.currentPlanResults[row1.dataKey];
            return {
                title: row1.title,
                subtitle: row1.subtitle,
                col1Data: this.formatData(row1.formatter, col1Value1),
                col2Data: this.formatData(row1.formatter, col2Value1),
                asterisk: row1.asterisk
            };
        });
    }
    @action
    formatData(formatFunctionName1: string, data1: unknown) {
        // @ts-expect-error: I have no idea
        return this[formatFunctionName1]?.(data1) || data1;
    }
    @action
    formatMoney(cents1 = []) {
        return this.strategyFinder.formatCentsRange(cents1);
    }
    @action
    formatTermMonths(months1 = []) {
        return this.strategyFinder.formatMonthsRange(months1);
    }
    static{
        template(`
    <AppContentCard
      @titleClass="uppercase"
      @title={{t "pslf_dashboard.results.default"}}
      ...attributes
    >
      <TioPageHorizontalRule @size="sm" />
      <div class="grid grid-cols-7 gap-4">
        {{#if (eq @programType "idr")}}
          <p class="col-span-7">{{t "idr.results.description" htmlSafe=true}}</p>
        {{/if}}
        <div
          class="col-span-7
            {{if (or (eq @programType 'idr') (eq @programType 'sf')) 'md:col-span-4'}}"
        >
          {{#if @recommendation.hasErrorsInCalcs}}
            <div class="m-2">
              {{t "pslf_dashboard.results.error"}}
              <LinkTo @route={{this.workflowRoute}} class="tio-copy">
                {{#if (eq @programType "sf")}}
                  {{t "common.strategy_finder.default"}}
                {{else}}
                  {{t "recommendations_estimator.forgiveness_estimator"}}
                {{/if}}
              </LinkTo>
              {{concat ". " (t "pslf_dashboard.results.thank_you")}}
            </div>
          {{else}}
            {{#if (eq @programType "idr")}}
              <div class="font-semibold my-6">
                <p>
                  {{t "idr.results.estimated_monthly_payment"}}
                  <span class="ml-1 text-midnight">
                    {{#if (gte this.estimatedMonthlyPayment 0)}}
                      {{safeFormatNumber
                        this.estimatedMonthlyPayment
                        style="currency"
                        currency="USD"
                        maximumFractionDigits=0
                      }}
                    {{/if}}
                  </span>
                </p>
                <p>
                  {{t "idr.results.estimated_loan_forgiveness"}}
                  <span class="ml-1 text-midnight">
                    {{#if (gte this.estimatedForgivenessAmount 0)}}
                      {{safeFormatNumber
                        this.estimatedForgivenessAmount
                        style="currency"
                        currency="USD"
                        maximumFractionDigits=0
                      }}
                    {{/if}}
                  </span>
                </p>
              </div>
            {{else}}
              <p class="mb-6">{{t "pslf_dashboard.results.description"}}</p>
              <div class="font-semibold mb-6">
                <p class="mb-1">
                  {{t "pslf_dashboard.results.estimated_monthly_payment_is"}}
                  <span class="ml-1 text-midnight">
                    {{#if (gte this.estimatedMonthlyPayment 0)}}
                      {{safeFormatNumber
                        this.estimatedMonthlyPayment
                        style="currency"
                        currency="USD"
                      }}*
                    {{/if}}
                  </span>
                </p>
                <p class="mb-1">
                  {{t "pslf_dashboard.results.estimated_loan_forgiveness_amount_is"}}
                  <span class="ml-1 text-midnight">
                    {{#if (gte this.estimatedForgivenessAmount 0)}}
                      {{safeFormatNumber
                        this.estimatedForgivenessAmount
                        style="currency"
                        currency="USD"
                      }}
                    {{/if}}
                  </span>
                </p>
                <p class="mb-1">
                  {{t "pslf_dashboard.results.estimated_loan_forgiveness_date_is"}}
                  <span class="ml-1 text-midnight">
                    {{this.estimatedForgivenessDate}}
                  </span>
                </p>
              </div>
            {{/if}}

            <table class="text-sm w-full mb-4 pslf-dashboard-results-table">
              <thead>
                <tr>
                  <td></td>
                  {{#each this.horizontalHeaderColumns as |column|}}
                    <th
                      scope="col"
                      class="text-center even:bg-ocean-600 even:text-white"
                    >{{column.title}}</th>
                  {{/each}}
                </tr>
              </thead>
              <tbody>
                {{#each this.tableRowData as |row|}}
                  <tr>
                    <th scope="row" class="text-left align-top text-gray-700">
                      {{row.title}}
                      {{#if row.subtitle}}
                        <div class="text-xs font-normal">{{row.subtitle}}</div>
                      {{/if}}
                    </th>
                    <td class="text-center align-top bg-ocean-50">{{row.col1Data}}
                      {{#if (and row.asterisk (eq @programType "pslf"))}}
                        <span>*</span>
                      {{/if}}
                    </td>
                    <td class="text-center align-top">
                      {{row.col2Data}}
                    </td>
                  </tr>
                {{/each}}
              </tbody>
            </table>
            <div class="flex justify-center mb-6">
              <button
                type="button"
                {{on "click" (fn (mut this.toggleDialog) true)}}
                class="tio-copy"
              >
                {{t "recommendations_estimator.step_by_step_instructions"}}
              </button>
            </div>
            <div class="flex justify-around gap-2">
              <TioPdfPrintButton
                @url={{@recommendation.downloadUrl}}
                @filename={{@recommendation.downloadFilename}}
                class="w-full max-w-48"
              />
              <TioDownloadButton
                @url={{@recommendation.downloadUrl}}
                @filename={{@recommendation.downloadFilename}}
                class="w-full max-w-48"
              />
            </div>
          {{/if}}
        </div>
        {{yield}}
      </div>
    </AppContentCard>

    <TioDialog
      @show={{this.toggleDialog}}
      @containerClass="w-full sm:max-w-screen-xl my-8"
      @onCancel={{fn (mut this.toggleDialog) false}}
    >
      <:header>
        <h1 class="text-midnight font-semibold ml-2">
          {{t "recommendations_estimator.step_by_step_instructions"}}
        </h1>
      </:header>
      <:body>
        <ForgivenessEstimatorResultInstructions @recommendation={{@recommendation}} />
      </:body>
      <:actions>
      </:actions>
    </TioDialog>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
