import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { eq, and, not, gte } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import errorsForField from 'tio-common/helpers/errors-for-field';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import MaterialIcon from 'tio-common/components/material-icon';
import missingInfoLinkedLoanFormSchema from 'tio-common/validation-schema/forms/missing-info-linked-loans';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import safeT from 'tio-common/helpers/safe-t';
import TioButton from 'tio-common/components/tio/button';
import TioIconButton from 'tio-common/components/tio/icon-button';
import type { ErrorSignature } from 'tio-employee/types/errors';
import type ErrorsSignature from 'tio-employee/types/errors';
import type LoanModel from 'tio-common/models/loan';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type StrategyFinderService from 'tio-common/services/strategy-finder';
export interface AccountsLinkedLoansSummaryLoanDetailSignature {
    Args: {
        loan: LoanModel;
        allowOverrideEdits: boolean;
        loanErrors: ErrorsSignature;
        style: 'row' | 'list';
    };
}
// TODO: Is this something that should be shared?
type LoanTypeOption = {
    label: string;
    value: string;
};
// TODO: Is this something that should be shared?
type RepaymentPlanOption = {
    label: string;
    value: string;
};
// TODO: Is this something that should be shared?
type InterestRateOption = {
    label: string;
    value: number;
};
type Error = {
    loanId: string;
    fields: string[];
};
export default class AccountsLinkedLoansSummaryLoanDetail extends Component<AccountsLinkedLoansSummaryLoanDetailSignature> {
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @service
    strategyFinder: StrategyFinderService;
    @tracked
    isEditing = false;
    @tracked
    selectedLoanTypeOption?: LoanTypeOption;
    @tracked
    selectedRepaymentPlanOption?: RepaymentPlanOption;
    @tracked
    customInterestRate?: InterestRateOption;
    @tracked
    loanSummaryErrors = null;
    get loanErrors() {
        return this.loanSummaryErrors || this.args.loanErrors || [];
    }
    get customLoanType() {
        return this.selectedLoanTypeOption?.value || '';
    }
    get customRepaymentPlan() {
        return this.selectedRepaymentPlanOption?.value || '';
    }
    get allowOverrideEdits() {
        // This dialog is also used for read-only display (for now).
        // If this is false, we don't want to allow for edits.
        return this.args.allowOverrideEdits || false;
    }
    get canEdit() {
        // @ts-expect-error: seems like a bug or are loans using a differnt format?
        return this.allowOverrideEdits && this.loanErrors.length;
    }
    get missingFields() {
        // @ts-expect-error: seems like a bug or are loans using a differnt format?
        const missingFieldArrays1 = this.loanErrors.map?.(// @ts-expect-error: seems like a bug or are loans using a differnt format?
        (loanError1: ErrorSignature)=>loanError1.fields);
        return missingFieldArrays1.flat();
    }
    get isMissingLoanType() {
        return this.missingFields.includes('loanClass');
    }
    get isMissingInterestRate() {
        return this.missingFields.includes('interestRate');
    }
    get isMissingRepaymentPlan() {
        return this.missingFields.includes('repaymentPlan');
    }
    get observationOverridesForLoanType() {
        if (!this.customLoanType) {
            return {};
        }
        if (this.customLoanType === 'PLUS') {
            return {
                loanClass: 'DIRECT',
                parentPlus: 'YES'
            };
        }
        return {
            loanClass: this.customLoanType
        };
    }
    get observationOverridesForInterestRate() {
        if (!this.customInterestRate) {
            return {};
        }
        return {
            interestRate: this.customInterestRate.value
        };
    }
    get observationOverridesForRepaymentPlan() {
        if (!this.customRepaymentPlan) {
            return {};
        }
        return {
            repaymentPlan: this.customRepaymentPlan
        };
    }
    get repaymentPlanOptions() {
        return this.strategyFinder.repaymentPlanOptions;
    }
    get loanTypeOptions() {
        return [
            {
                label: 'Direct',
                value: 'DIRECT'
            },
            {
                label: 'Federal Family Education Loan (FFEL)',
                value: 'FFEL'
            },
            {
                label: 'Parent PLUS',
                value: 'PLUS'
            },
            {
                label: 'Private',
                value: 'PRIVATE'
            }
        ];
    }
    get interestRateOptions() {
        return [
            {
                label: '2%',
                value: 2
            },
            {
                label: '3%',
                value: 3
            },
            {
                label: '4%',
                value: 4
            },
            {
                label: '5%',
                value: 5
            },
            {
                label: '6%',
                value: 6
            },
            {
                label: '7%',
                value: 7
            },
            {
                label: '8%',
                value: 8
            }
        ];
    }
    get inputModel() {
        return {
            loanType: this.customLoanType || '',
            interestRate: this.customInterestRate?.value,
            repaymentPlan: this.customRepaymentPlan || ''
        };
    }
    // Currently these validations do not prevent submit, TODO
    get inputValidationErrors() {
        const schema1 = missingInfoLinkedLoanFormSchema;
        try {
            schema1?.validateSync?.(this.inputModel, {
                abortEarly: false
            });
        } catch (err1) {
            return err1.inner || [];
        }
        return [];
    }
    save = dropTask(async ()=>{
        const payload1 = {
            ...this.observationOverridesForLoanType,
            ...this.observationOverridesForInterestRate,
            ...this.observationOverridesForRepaymentPlan
        };
        await this.store.adapterFor('loan').setCustomObservationData(this.args.loan.id, payload1);
        await this.args.loan.reload();
        const newSummary1 = await this.store.adapterFor('user').getLoanSummary(this.sessionContext.user.id);
        this.loanSummaryErrors = newSummary1.errors?.filter((error1: Error)=>error1.loanId === this.args.loan.id);
        this.isEditing = false;
    });
    @action
    cancel() {
        this.isEditing = false;
        delete this.customInterestRate;
        delete this.selectedLoanTypeOption;
        delete this.selectedRepaymentPlanOption;
        this.loanSummaryErrors = null;
    }
    static{
        template(`
    {{#if (eq @style "row")}}
      <tr class="border text-center">
        <td class="text-left p-2 truncate">
          {{@loan.name}}
        </td>
        <td class="p-2">
          {{#if (and this.isEditing this.isMissingRepaymentPlan)}}
            <FormSelect
              data-legacy-input
              @label=""
              @options={{this.repaymentPlanOptions}}
              @selected={{this.selectedRepaymentPlanOption}}
              @onChange={{fn (mut this.selectedRepaymentPlanOption)}}
              {{! @glint-expect-error: known issue with power select }}
              @renderInPlace={{true}}
              @placeholder="Select"
              @required="true"
              @errors={{errorsForField "repaymentPlan" schemaErrors=this.inputValidationErrors}}
              @disabled={{not this.isEditing}}
              as |plan|
            >
              {{! @glint-expect-error: types are erased in component }}
              {{plan.label}}
            </FormSelect>
          {{else if this.isMissingRepaymentPlan}}
            <div class="text-red-500">
              <MaterialIcon @icon="warning_amber" class="align-middle" />
              <span>{{t "pslf_dashboard.actions.dialog.need_more_info"}}</span>
            </div>
          {{else}}
            {{safeT @loan.repaymentPlan nestedPath="common.repayment_plans" defaultToBlank=true}}
          {{/if}}
        </td>
        <td class="p-2">
          {{#if (and this.isEditing this.isMissingLoanType)}}
            <FormSelect
              data-legacy-input
              @label=""
              @options={{this.loanTypeOptions}}
              @selected={{this.selectedLoanTypeOption}}
              @onChange={{fn (mut this.selectedLoanTypeOption)}}
              {{! @glint-expect-error: known issue with power select }}
              @renderInPlace={{true}}
              @placeholder="Select"
              @required="true"
              @errors={{errorsForField "loanType" schemaErrors=this.inputValidationErrors}}
              @disabled={{not this.isEditing}}
              as |type|
            >
              {{! @glint-expect-error: types are erased in component }}
              {{type.label}}
            </FormSelect>
          {{else if this.isMissingLoanType}}
            <div class="text-red-500">
              <MaterialIcon @icon="warning_amber" class="align-middle" />
              <span>{{t "pslf_dashboard.actions.dialog.need_more_info"}}</span>
            </div>
          {{else}}
            {{@loan.loanTypeDisplayName}}
          {{/if}}
        </td>
        <td class="p-2">
          {{#if (and this.isEditing this.isMissingInterestRate)}}
            <FormSelect
              data-legacy-input
              @label=""
              @options={{this.interestRateOptions}}
              @selected={{this.customInterestRate}}
              @onChange={{fn (mut this.customInterestRate)}}
              {{! @glint-expect-error: known issue with power select }}
              @renderInPlace={{true}}
              @required="true"
              @placeholder="Select"
              @errors={{errorsForField "interestRate" schemaErrors=this.inputValidationErrors}}
              disabled={{not this.isEditing}}
              as |rate|
            >
              {{! @glint-expect-error: types are erased in component }}
              {{rate.label}}
            </FormSelect>
          {{else if this.isMissingInterestRate}}
            <div class="text-red-500">
              <MaterialIcon @icon="warning_amber" class="align-middle" />
              <span>{{t "pslf_dashboard.actions.dialog.need_more_info"}}</span>
            </div>
          {{else}}
            {{@loan.interestRate}}
          {{/if}}
        </td>
        <td class="p-2 text-right">
          {{#if (gte @loan.currentBalance 0)}}
            {{safeFormatNumber @loan.currentBalance style="currency" currency="USD"}}
          {{/if}}
        </td>
        <td class="p-2 flex justify-end">
          {{#if this.isEditing}}
            <TioIconButton
              @onClick={{this.save.perform}}
              @loading={{this.save.isRunning}}
              @title="Save"
              @icon="check"
              @outlined={{true}}
              class="mx-1"
            />
            <TioIconButton
              @onClick={{this.cancel}}
              @disabled={{this.save.isRunning}}
              @title="Cancel"
              @icon="close"
              @outlined={{true}}
              class="mx-1"
            />
          {{else if this.canEdit}}
            <TioIconButton
              {{! @glint-expect-error: need to figure out what to do with mut }}
              @onClick={{fn (mut this.isEditing) true}}
              @title="Edit"
              @icon="edit"
              @outlined={{true}}
              class="mx-1"
            />
          {{/if}}
        </td>
      </tr>
    {{else if (eq @style "list")}}
      <li class="border rounded-md p-2 bg-white mb-2">
        <div class="grid grid-cols-7 auto-rows-min gap-1 items-start relative">
          <h2 class="col-span-3 text-right font-semibold">
            {{t "pslf_dashboard.actions.dialog.loan_name"}}:
          </h2>
          <p class="col-span-4 truncate">
            {{@loan.name}}
          </p>

          <h2 class="col-span-3 text-right font-semibold">
            {{t "pslf_dashboard.actions.dialog.repayment_plan"}}:
          </h2>
          <p class="col-span-4">
            {{#if (and this.isEditing this.isMissingRepaymentPlan)}}
              <FormSelect
                data-legacy-input
                @label=""
                @options={{this.repaymentPlanOptions}}
                @selected={{this.selectedRepaymentPlanOption}}
                @onChange={{fn (mut this.selectedRepaymentPlanOption)}}
                {{! @glint-expect-error: known issue with power select }}
                @renderInPlace={{true}}
                @placeholder="Select"
                @required="true"
                @errors={{errorsForField "repaymentPlan" schemaErrors=this.inputValidationErrors}}
                @disabled={{not this.isEditing}}
                as |plan|
              >
                {{! @glint-expect-error: types are erased in component }}
                {{plan.label}}
              </FormSelect>
            {{else if this.isMissingRepaymentPlan}}
              <div class="text-red-500">
                <MaterialIcon @icon="warning_amber" class="align-middle" />
                <span>{{t "pslf_dashboard.actions.dialog.need_more_info"}}</span>
              </div>
            {{else}}
              {{safeT @loan.repaymentPlan nestedPath="common.repayment_plans" defaultToBlank=true}}
            {{/if}}
          </p>

          <h2 class="col-span-3 text-right font-semibold">
            {{t "pslf_dashboard.actions.dialog.loan_type"}}:
          </h2>
          <p class="col-span-4">
            {{#if (and this.isEditing this.isMissingLoanType)}}
              <FormSelect
                data-legacy-input
                @label=""
                @options={{this.loanTypeOptions}}
                @selected={{this.selectedLoanTypeOption}}
                @onChange={{fn (mut this.selectedLoanTypeOption)}}
                {{! @glint-expect-error: known issue with power select }}
                @renderInPlace={{true}}
                @size="sm"
                @required="true"
                @placeholder="Select"
                @errors={{errorsForField "loanType" schemaErrors=this.inputValidationErrors}}
                @disabled={{not this.isEditing}}
                as |type|
              >
                {{! @glint-expect-error: types are erased in component }}
                {{type.label}}
              </FormSelect>
            {{else if this.isMissingLoanType}}
              <div class="text-red-500">
                <MaterialIcon @icon="warning_amber" class="align-middle text-2xl" />
                <span>{{t "pslf_dashboard.actions.dialog.need_more_info"}}</span>
              </div>
            {{else}}
              {{@loan.loanTypeDisplayName}}
            {{/if}}
          </p>

          <h2 class="col-span-3 text-right font-semibold">
            {{t "pslf_dashboard.actions.dialog.interest_rate"}}:
          </h2>
          <p class="col-span-4">
            {{#if (and this.isEditing this.isMissingInterestRate)}}
              <FormSelect
                data-legacy-input
                @label=""
                @options={{this.interestRateOptions}}
                @selected={{this.customInterestRate}}
                @onChange={{fn (mut this.customInterestRate)}}
                {{! @glint-expect-error: known issue with power select }}
                @renderInPlace={{true}}
                @required="true"
                @size="sm"
                @placeholder="Select"
                @errors={{errorsForField "interestRate" schemaErrors=this.inputValidationErrors}}
                disabled={{not this.isEditing}}
                as |rate|
              >
                {{! @glint-expect-error: types are erased in component }}
                {{rate.label}}
              </FormSelect>
            {{else if this.isMissingInterestRate}}
              <div class="text-red-500">
                <MaterialIcon @icon="warning_amber" class="align-middle text-2xl" />
                <span>{{t "pslf_dashboard.actions.dialog.need_more_info"}}</span>
              </div>
            {{else}}
              {{@loan.interestRate}}
            {{/if}}
          </p>

          <h2 class="col-span-3 text-right font-semibold">
            {{t "pslf_dashboard.actions.dialog.current_balance"}}:
          </h2>
          <p class="col-span-3">
            {{#if (gte @loan.currentBalance 0)}}
              {{safeFormatNumber @loan.currentBalance style="currency" currency="USD"}}
            {{/if}}
          </p>

          {{#if this.isEditing}}
            <div class="col-span-7 flex justify-center">
              <TioButton
                @onClick={{this.cancel}}
                @disabled={{this.save.isRunning}}
                @outlined={{true}}
                class="m-1 w-32"
              >{{t "cancel"}}
              </TioButton>
              <TioButton
                @onClick={{this.save.perform}}
                @loading={{this.save.isRunning}}
                class="m-1 w-32"
              >
                {{t "save"}}
              </TioButton>
            </div>
          {{else if this.canEdit}}
            <div class="col-span-1 place-self-end">
              <TioIconButton
                {{! @glint-expect-error: need to figure out what to do with mut }}
                @onClick={{fn (mut this.isEditing) true}}
                @title="Edit"
                @icon="edit"
                @outlined={{true}}
                class="m-1"
              />
            </div>
          {{/if}}
        </div>
      </li>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
