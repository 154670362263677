import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { notEq, eq } from 'ember-truth-helpers';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type RecommendationModel from 'tio-common/models/recommendation';
import { t } from 'ember-intl';
import TioDialog from 'tio-common/components/tio/dialog';
import type { IntlService } from 'ember-intl';
import type StrategyFinderService from 'tio-common/services/strategy-finder';
export interface ForgivenessEstimatorEstimatorSummaryDialogSignature {
    Args: {
        programType: string;
        recommendation: RecommendationModel;
        didCancel?: () => void;
    };
}
export default class ForgivenessEstimatorEstimatorSummaryDialog extends Component<ForgivenessEstimatorEstimatorSummaryDialogSignature> {
    @service
    strategyFinder: StrategyFinderService;
    @service
    intl: IntlService;
    get programType() {
        return this.args.programType;
    }
    get inputSummary() {
        const inputSummary1 = this.args.recommendation?.formattedInputSummary;
        if (this.programType != 'sf') {
            inputSummary1.monthsOfPayments = inputSummary1.monthsOfPayments / 12;
        }
        inputSummary1.income = this.strategyFinder.formatCentsRange([
            inputSummary1.income
        ]);
        inputSummary1.before2007 === 'before-october-2007' ? (inputSummary1.before2007 = this.intl.t('yes')) : (inputSummary1.before2007 = this.intl.t('no'));
        if (inputSummary1.degree) {
            inputSummary1.degree = this.intl.t(`forgiveness_estimator.${inputSummary1.degree}`);
        }
        if (inputSummary1.affordPayment) {
            inputSummary1.affordPayment = this.intl.t(`${inputSummary1.affordPayment}`);
        }
        return inputSummary1;
    }
    @action
    cancel() {
        this.args.didCancel?.();
    }
    @action
    formatMoney(centsRange1 = []) {
        return this.strategyFinder.formatCentsRange(centsRange1);
    }
    static{
        template(`
    <TioDialog
      @show={{true}}
      @containerClass="w-full sm:max-w-screen-md"
      @onCancel={{this.cancel}}
      @headerClass="text-lg font-semibold"
    >
      <:header>
        {{#if (notEq this.programType "sf")}}
          {{t "forgiveness_estimator.results.your_monthly_payment"}}
        {{else}}
          {{t "strategy_finder.your_payment_and_sf_input"}}
        {{/if}}
      </:header>
      <:body>
        <div class="text-sm text-tio-gray-700 flex flex-col">
          <div class="flex my-2">
            <p class="font-semibold">
              {{t "forgiveness_estimator.results.gross_income"}}:
            </p>
            <span class="ml-1">{{this.inputSummary.income}}</span>
          </div>
          <div class="flex my-2">
            <p class="font-semibold">{{t "forgiveness_estimator.family_size"}}:</p>
            <span class="ml-1">{{this.inputSummary.familySize}}</span>
          </div>
          <div class="flex my-2">
            {{#if (eq this.programType "sf")}}
              <p class="font-semibold">
                {{t "strategy_finder.months_of_qualifying_payments"}}:
              </p>
            {{else}}
              <p class="font-semibold">
                {{t "forgiveness_estimator.results.number_of_years"}}:
              </p>
            {{/if}}
            <span class="ml-1">{{this.inputSummary.monthsOfPayments}}</span>
          </div>
          <div class="flex my-2">
            <p class="font-semibold">
              {{t "forgiveness_estimator.results.loans_before_2007"}}:
            </p>
            <span class="ml-1">{{this.inputSummary.before2007}}</span>
          </div>
          {{#if this.inputSummary.degree}}
            <div class="flex my-2">
              <p class="font-semibold">
                {{t "forgiveness_estimator.grad_or_undergrad"}}:
              </p>
              <span class="ml-1">{{this.inputSummary.degree}}</span>
            </div>
          {{/if}}
          {{#if this.inputSummary.affordPayment}}
            <div class="flex my-2">
              <p class="font-semibold">
                {{t "forgiveness_estimator.afford_payments"}}:
              </p>
              <span class="ml-1">{{this.inputSummary.affordPayment}}</span>
            </div>
          {{/if}}
        </div>
      </:body>
      <:actions>
      </:actions>
    </TioDialog>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
