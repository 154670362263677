import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class EnrollController extends Controller {
  @tracked inviteCode = '';
  @tracked code = '';
  @tracked email = '';
  @tracked 'ember-enroll' = 0;

  queryParams = ['inviteCode', 'code', 'email', 'ember-enroll'];
}
