import { template } from "@ember/template-compiler";
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { trackedFunction } from 'reactiveweb/function';
import { LinkTo } from '@ember/routing';
import { not } from 'ember-truth-helpers';
import Component from '@glimmer/component';
import screenIs from 'tio-common/helpers/screen-is';
import TioFaqs from 'tio-common/components/tio/faqs';
import TioStepOverview from 'tio-common/components/tio/step-overview';
import TioHeader from 'tio-common/components/tio/page/hero/index';
import TioButton from 'tio-common/components/tio/button';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TasProgramTemplateModel from 'tio-common/models/tas-program-template';
export interface TuitionAssistanceProgramDetailsOverviewSignature {
    Args: {
        programTemplate: TasProgramTemplateModel;
        isHeldParticipant?: boolean;
        isActiveParticipant?: boolean;
        isRestrictedByProgramExclusivity?: boolean;
        ineligibleBasedOnWaitingPeriod: boolean;
    };
}
export default class TuitionAssistanceProgramDetailsOverview extends Component<TuitionAssistanceProgramDetailsOverviewSignature> {
    @service
    intl: IntlService;
    @service
    store: StoreService;
    @service
    sessionContext: SessionContextService;
    @service
    router: RouterService;
    activeProgramInstancesData = trackedFunction(this, async ()=>{
        const activeInstances1 = await this.store.query('tas-program-instance', {
            filter: {
                employee: this.sessionContext.currentEmployee.id,
                active: true
            },
            include: 'tas-program-template,tas-applications'
        });
        return activeInstances1;
    });
    get activeProgramInstances() {
        return this.activeProgramInstancesData.value || [];
    }
    get isHeldParticipant() {
        return !!this.args.isHeldParticipant;
    }
    get isActiveParticipant() {
        return this.args.isActiveParticipant;
    }
    get isRestrictedByProgramExclusivity() {
        return this.args.isRestrictedByProgramExclusivity;
    }
    get canApplyToProgram() {
        if (this.isHeldParticipant || !this.isActiveParticipant || this.isRestrictedByProgramExclusivity || this.args.ineligibleBasedOnWaitingPeriod || !this.sessionContext.currentEmployee.id || !this.activeProgramInstancesData.value) {
            return false;
        }
        if (this.activeProgramInstances.length === 0 || this.isMultipleInstanceProgram) {
            return true;
        }
        const templateIds1 = this.activeProgramInstances.map((instance1: TasProgramTemplateModel)=>instance1.tasProgramTemplate.id);
        const hasActiveInstanceForProgram1 = templateIds1.includes(this.args.programTemplate.id);
        return !hasActiveInstanceForProgram1;
    }
    get programSteps() {
        return this.rawProgramSteps.map((step1)=>this.intl.t(`tuition_assistance.program_steps.${step1}`));
    }
    get instanceForExistingApp() {
        const templateId1 = this.args.programTemplate.id;
        const instances1 = this.activeProgramInstances;
        const activeInstanceMatchesTemplateId1 = instances1.find((instance1: TasProgramTemplateModel)=>templateId1 === instance1.tasProgramTemplate.id);
        return activeInstanceMatchesTemplateId1;
    }
    get programInstance() {
        return this.instanceForExistingApp || {};
    }
    get hideProcessDescriptionSteps() {
        return this.args.programTemplate.hideProcessDescriptionSteps;
    }
    get rawProgramSteps() {
        const model1 = this.args.programTemplate;
        if (!model1) {
            return [];
        }
        if (model1.isPreApprovalEnabled && model1.isCoursePreApprovalEnabled && model1.isReimbursementProgram) {
            return [
                'get_program_approval',
                'get_courses_approval',
                'complete_courses',
                'submit_grades',
                'final_reimbursement'
            ];
        }
        if (model1.isPreApprovalEnabled && model1.isCoursePreApprovalEnabled && model1.isPrepaidProgram) {
            return [
                'get_program_approval',
                'get_courses_approval',
                'prepayment',
                'complete_courses',
                'submit_grades'
            ];
        }
        if (!model1.isPreApprovalEnabled && model1.isCoursePreApprovalEnabled && model1.isReimbursementProgram) {
            return [
                'get_courses_approval',
                'complete_courses',
                'submit_grades',
                'final_reimbursement'
            ];
        }
        if (!model1.isPreApprovalEnabled && model1.isCoursePreApprovalEnabled && model1.isPrepaidProgram) {
            return [
                'get_courses_approval',
                'prepayment',
                'complete_courses',
                'submit_grades'
            ];
        }
        if (!model1.isPreApprovalEnabled && !model1.isCoursePreApprovalEnabled && model1.isReimbursementProgram) {
            return [
                'complete_application',
                'final_reimbursement'
            ];
        }
        // TAS.TODO - Update with missing scenarios
        return [];
    }
    get isMultipleInstanceProgram() {
        return this.args.programTemplate.isMultipleInstanceProgram;
    }
    get buttonText() {
        const templateIds1 = this.activeProgramInstances.map((instance1: TasProgramTemplateModel)=>instance1.tasProgramTemplate.id);
        const hasActiveInstanceForProgram1 = templateIds1.includes(this.args.programTemplate.id);
        if (this.isMultipleInstanceProgram && hasActiveInstanceForProgram1) {
            return this.intl.t('tuition_assistance.program_details.create_new_program_instance');
        } else {
            return this.intl.t('tuition_assistance.program_details.start_application');
        }
    }
    get isDependentProgram() {
        return this.args.programTemplate.isDependentProgram;
    }
    createInstanceAndApply = dropTask(async ()=>{
        const programTemplate1 = this.args.programTemplate;
        const instance1 = this.store.createRecord('tas-program-instance', {
            tasProgramTemplate: programTemplate1,
            employee: this.sessionContext.currentEmployee,
            fields: {},
            customFields: programTemplate1.instanceCustomFields
        });
        instance1.setInitialEmployeeFields();
        await instance1.save();
        if (programTemplate1.isCertificateProgram) {
            try {
                await this.store.adapterFor('tas-program-instance').requestProgramApproval(instance1);
            } catch (e1) {
                console.error(e1);
            }
            this.router.transitionTo('authenticated.tuition-assistance.programs.instance.certificate.index', instance1.id);
        } else {
            this.router.transitionTo('authenticated.tuition-assistance.programs.instance.apply', instance1.id);
        }
    });
    static{
        template(`
    <div class="flex flex-col">
      <div class="flex w-1/2">
        <TioHeader
          @headerBeginning={{@programTemplate.programName}}
          @description={{@programTemplate.programDescription}}
        />
      </div>
      <div class="flex my-8">
        <TioButton
          @onClick={{this.createInstanceAndApply.perform}}
          @disabled={{not this.canApplyToProgram}}
          @loading={{this.createInstanceAndApply.isRunning}}
          class="!w-96 !py-6 uppercase"
        >
          {{t "tuition_assistance.program_details.start_application"}}
        </TioButton>
      </div>
      {{#if this.instanceForExistingApp}}
        <div class="flex text-sm">
          <span class="text-center">
            {{t "tuition_assistance.you_already_have_an_active_program"}}
            {{#if this.isMultipleInstanceProgram}}
              <LinkTo @route="authenticated.tuition-assistance.dashboard" class="tio-copy">
                {{t "click_here"}}
              </LinkTo>
            {{else}}
              <LinkTo
                @route="authenticated.tuition-assistance.programs.instance.index"
                @model={{this.instanceForExistingApp}}
                class="tio-copy"
              >
                {{t "click_here"}}
              </LinkTo>
            {{/if}}
            {{t "tuition_assistance.to_view_program details"}}
          </span>
        </div>
      {{/if}}
    </div>

    {{#unless this.hideProcessDescriptionSteps}}
      <section class="mt-6 mb-4 md:my-8">
        {{#if (screenIs "md")}}
          <h1 class="mb-3 text-xl font-semibold text-midnight">
            {{t "tuition_assistance.program_details.what_is_the_process"}}
          </h1>
          <TioStepOverview @steps={{this.programSteps}} />
        {{else}}
          <h3 class="text-lg font-semibold text-left">
            {{t "tuition_assistance.program_details.what_is_the_process"}}
          </h3>
          <TioStepOverview @steps={{this.programSteps}} class="pl-4" />
        {{/if}}
      </section>
    {{/unless}}

    <article>
      <h1 class="py-4 font-medium text-xl">
        {{t "tuition_assistance.faq"}}
      </h1>
      {{#each @programTemplate.programFaqs as |faq|}}
        <TioFaqs @question={{faq.heading}} @answer={{faq.body}} class="ml-8" />
      {{/each}}
    </article>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
