import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type StoreService from 'tio-common/services/store';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';

type Query = {
  filter: {
    'is-non-partner'?: boolean;
    status?: string;
    'employer-group-id'?: string;
    company: string;
    search?: string;
  };
  sort?: string;
  page: {
    limit: number;
    offset: number;
  };
  include: string;
};

type Params = {
  group: string;
  limit: number;
  page: number;
  search: string;
  sort: string;
  status: string;
};

export default class AuthenticatedAdminPslfFormsRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;
  @service declare partner: PartnerService;

  queryParams = {
    group: { refreshModel: true, replace: true },
    limit: { refreshModel: true, replace: true },
    page: { refreshModel: true, replace: true },
    search: { refreshModel: true, replace: true },
    sort: { refreshModel: true, replace: true },
    status: { refreshModel: true, replace: true },
  };

  async model({ search, group, status, page, limit, sort }: Params) {
    const filter: Partial<Query['filter']> = {
      'is-non-partner': false,
      company: this.partner.company?.id,
    };

    if (group) {
      filter['employer-group-id'] = group;
    }

    if (status) {
      filter['status'] = status;
    }

    if (search) {
      filter.search = search;
    }

    const query = {
      filter,
      sort,
      page: {
        limit,
        offset: (page - 1) * limit,
      },
      include: 'attachments',
    };

    return this.store.query('pslf-form', query);
  }
}
