import ApplicationAdapter from './application';

/**
 * An extended adapter for the `TasParticipant` model.
 *
 * @memberof adapters
 */

class TasParticipantAdapter extends ApplicationAdapter {
  /**
   * @param {string}  tasParticipantId - an ID of a TAS participant record
   * @param {object}  changelog - a changelog object with a note
   *
   * @return {object}
   */

  async removeTasParticipantHold(
    tasParticipantId: string,
    changelog: { note: string }
  ): Promise<void> {
    const url = this.buildURL('tas-participants', tasParticipantId) + '/transition/state/activate';
    return await this.ajax(url, 'PATCH', {
      data: { data: { attributes: { _changelog: changelog } } },
    });
  }

  async holdTasParticipant(tasParticipantId: string, changelog: { note: string }): Promise<void> {
    const url = this.buildURL('tas-participants', tasParticipantId) + '/transition/state/hold';
    return await this.ajax(url, 'PATCH', {
      data: { data: { attributes: { _changelog: changelog } } },
    });
  }
}

export default TasParticipantAdapter;
