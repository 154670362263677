import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import FreestyleGuide from 'ember-freestyle/components/freestyle-guide';
import FreestyleSection from 'ember-freestyle/components/freestyle-section';
import FreestyleUsage from 'ember-freestyle/components/freestyle-usage';
import FreestylePalette from 'ember-freestyle/components/freestyle-palette';
import FreestylePaletteItem from 'ember-freestyle/components/freestyle-palette-item';
import FreestyleCollection from 'ember-freestyle/components/freestyle-collection';
import CardGeneric from 'tio-common/components/card/generic';
import TioButton from 'tio-common/components/tio/button';
import MaterialIcon from 'tio-common/components/material-icon';
import { tioGray, tioViolet, tioMidnight, tioPlum, tioMaroon, tioTeal, tioFuscia, tioMint, tioOcean, tioError } from '../utils/freestyle-data';
export default RouteTemplate(template(`
    {{! template-lint-disable no-bare-strings }}
    <FreestyleGuide @title="Tuition.io" @subtitle="Living Style Guide">
      <FreestyleSection @name="Visual Style" as |Section|>
        <Section.subsection @name="Typography">
          <div class="p-4">
            <p>Tuition.io uses
              <a class="text-blue-600 cursor-pointer" href="https://tailwindcss.com/">Tailwind CSS</a>
              in the application, as well as the
              <a
                class="text-blue-600 cursor-pointer"
                href="https://github.com/tailwindlabs/tailwindcss-typography"
              >Tailwind CSS Typography</a>
              plugin.</p>
            <p>Here are some examples of Tailwind Typography:</p>
          </div>
          <FreestyleUsage @slug="typography-fonts" @title="Font Family">
            <p class="font-sans text-xl">The quick brown fox jumped over the lazy dog.</p>
            <p class="font-serif text-xl">The quick brown fox jumped over the lazy dog.</p>
            <p class="font-mono text-xl">The quick brown fox jumped over the lazy dog.</p>
          </FreestyleUsage>

          <FreestyleUsage @slug="typography-font-size" @title="Font size">
            <p class="text-sm">The quick brown fox jumped over the lazy dog.</p>
            <p class="text-base">The quick brown fox jumped over the lazy dog.</p>
            <p class="text-lg">The quick brown fox jumped over the lazy dog.</p>
            <p class="text-xl">The quick brown fox jumped over the lazy dog.</p>
            <p class="text-2xl">The quick brown fox jumped over the lazy dog.</p>
            <p class="text-3xl">The quick brown fox jumped over the lazy dog.</p>
            <p class="text-4xl">The quick brown fox jumped over the lazy dog.</p>
            <p class="text-5xl">The quick brown fox jumped over the lazy dog.</p>
          </FreestyleUsage>
          <div class="text-xl">
            <FreestyleUsage @slug="typography-font-weight" @title="Font weight">
              <p class="font-light">The quick brown fox jumped over the lazy dog.</p>
              <p class="font-normal">The quick brown fox jumped over the lazy dog.</p>
              <p class="font-medium">The quick brown fox jumped over the lazy dog.</p>
              <p class="font-semibold">The quick brown fox jumped over the lazy dog.</p>
              <p class="font-bold">The quick brown fox jumped over the lazy dog.</p>
            </FreestyleUsage>
          </div>
          <div class="text-xl font-medium">
            <FreestyleUsage @slug="typography-letter-spacing" @title="Letter spacing">
              <p class="tracking-tighter">The quick brown fox jumped over the lazy dog.</p>
              <p class="tracking-tight">The quick brown fox jumped over the lazy dog.</p>
              <p class="tracking-normal">The quick brown fox jumped over the lazy dog.</p>
              <p class="tracking-wide">The quick brown fox jumped over the lazy dog.</p>
              <p class="tracking-wider">The quick brown fox jumped over the lazy dog.</p>
              <p class="tracking-widest">The quick brown fox jumped over the lazy dog.</p>
            </FreestyleUsage>
          </div>
        </Section.subsection>

        <Section.subsection @name="Color">
          <div class="flex p-4">
            <FreestylePalette
              @colorPalette={{tioGray}}
              @title="Gray Color Palette"
              @description="Found in tio.styles tio-shared-tailwind.config"
            />
          </div>
          <div class="flex p-4">
            <div>
              <p class="FreestylePalette-title">Midnight</p>
              <FreestylePaletteItem @color={{tioMidnight}} />
            </div>
            <div>
              <p class="FreestylePalette-title">Plum</p>
              <FreestylePaletteItem @color={{tioPlum}} />
            </div>
            <div>
              <p class="FreestylePalette-title">Maroon</p>
              <FreestylePaletteItem @color={{tioMaroon}} />
            </div>
            <div>
              <p class="FreestylePalette-title">Teal</p>
              <FreestylePaletteItem @color={{tioTeal}} />
            </div>
          </div>
          <div class="flex p-4">
            <FreestylePalette
              @colorPalette={{tioViolet}}
              @title="Violet Color Palette"
              @description="Found in tio.styles tio-shared-tailwind.config"
            />
          </div>
          <div class="flex p-4">
            <FreestylePalette
              @colorPalette={{tioFuscia}}
              @title="Fuscia Color Palette"
              @description="Found in tio.styles tio-shared-tailwind.config"
            />
          </div>
          <div class="flex p-4">
            <FreestylePalette
              @colorPalette={{tioMint}}
              @title="Mint Color Palette"
              @description="Found in tio.styles tio-shared-tailwind.config"
            />
          </div>
          <div class="flex p-4">
            <FreestylePalette
              @colorPalette={{tioOcean}}
              @title="Ocean Color Palette"
              @description="Found in tio.styles tio-shared-tailwind.config"
            />
          </div>
          <div class="flex p-4">
            <FreestylePalette
              @colorPalette={{tioError}}
              @title="Error Color Palette"
              @description="Found in tio.styles tio-shared-tailwind.config"
            />
          </div>
        </Section.subsection>
      </FreestyleSection>
      <FreestyleSection @name="TIO Common" as |Section|>
        <Section.subsection @name="Generic Card">
          <FreestyleUsage @slug="card-generic" @title="Generic Card">
            <CardGeneric
              @icon="account_circle"
              class="hover:shadow-lg border-gray-300"
              @truncateContent={{true}}
            >
              <:title>
                <h1 class="fotn-bold text-2xl">Generic Card Title</h1>
              </:title>

              <:subtitle><p class="italic">Generic card subtitle</p></:subtitle>

              <:content>
                <p>Generic Card Content</p>
                <p class="font-sans text-xl">The quick brown fox jumped over the lazy dog.</p>
                <p class="font-serif text-xl">The quick brown fox jumped over the lazy dog.</p>
                <p class="font-mono text-xl">The quick brown fox jumped over the lazy dog.</p>
              </:content>
            </CardGeneric>
          </FreestyleUsage>
        </Section.subsection>
        <Section.subsection @name="Tio Buttons">
          <FreestyleCollection
            @title="Tio Button Collection"
            @defaultKey="Standard"
            @inline={{true}}
            as |Collection|
          >
            <Collection.variant @key="Standard">
              <FreestyleUsage>
                <TioButton class="mx-1">
                  Button
                </TioButton>
              </FreestyleUsage>
            </Collection.variant>
            <Collection.variant @key="Outlined">
              <FreestyleUsage>
                <TioButton @outlined={{true}} class="mx-1">
                  Button
                </TioButton>
              </FreestyleUsage>
            </Collection.variant>
            <Collection.variant @key="Disabled">
              <FreestyleUsage>
                <TioButton @disabled={{true}} class="mx-1">
                  Button
                </TioButton>
              </FreestyleUsage>
            </Collection.variant>
            <Collection.variant @key="Loading">
              <FreestyleUsage>
                <TioButton @loading={{true}} class="mx-1">
                  Button
                </TioButton>
              </FreestyleUsage>
            </Collection.variant>
            <Collection.variant @key="Icon">
              <FreestyleUsage>
                <TioButton class="mx-1">
                  <MaterialIcon @icon="attach_money" />
                  Button
                </TioButton>
              </FreestyleUsage>
            </Collection.variant>
          </FreestyleCollection>
        </Section.subsection>
      </FreestyleSection>
    </FreestyleGuide>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
