import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AccountsLinkedLoansSummary from 'tio-employee/components/accounts/linked-loans-summary';
import CardInfo from '../card-info';
import Component from '@glimmer/component';
import NumberBadge from '../todo/number-badge';
import TioButton from 'tio-common/components/tio/button';
import TioDialog from 'tio-common/components/tio/dialog';
import Todo from '../todo';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type AccountModel from 'tio-common/models/account';
import type PersonModel from 'tio-common/models/person';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
export interface PslfDashboardActionsAccountsTodoSignature {
    Args: {
        accounts: AccountModel[];
        itemNo: string;
        person: PersonModel;
        loanSummary: LoanSummary;
    };
    Element: HTMLElement;
}
export default class PslfDashboardActionsAccountsTodoComponent extends Component<PslfDashboardActionsAccountsTodoSignature> {
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @tracked
    showAccountsLinkedDialog = false;
    @action
    toggleDialog(action1: 'open' | 'close') {
        if (action1 === 'open') {
            this.showAccountsLinkedDialog = true;
        }
        if (action1 === 'close') {
            this.showAccountsLinkedDialog = false;
        }
    }
    get hasLinkedAccounts() {
        return !!this.args.accounts.length;
    }
    get iconText() {
        return this.hasLinkedAccounts ? 'link' : 'link_off';
    }
    get accountInstitutionDisplayNames() {
        return this.args.accounts.slice().map((account1: AccountModel)=>account1.institutionName);
    }
    static{
        template(`
    <Todo
      @title={{t "pslf_dashboard.actions.account_linking_status"}}
      @isComplete={{this.hasLinkedAccounts}}
    >
      <:leftHeader>
        <NumberBadge @number={{@itemNo}} />
      </:leftHeader>
      <:body>
        <CardInfo @icon={{this.iconText}}>
          <:info>
            <p>{{t "pslf_dashboard.actions.accounts_linked"}}</p>
            <ul class="font-bold">
              {{#each this.accountInstitutionDisplayNames as |name|}}
                <li>{{name}}</li>
              {{else}}
                <li>{{t "number_zero"}}</li>
              {{/each}}
            </ul>
            {{#if this.hasLinkedAccounts}}
              <button
                class="mt-1 text-ocean-600 font-semibold underline"
                type="button"
                {{on "click" (fn this.toggleDialog "open")}}
              >
                {{t "pslf_dashboard.actions.view_details"}}
              </button>
            {{/if}}
          </:info>
        </CardInfo>
      </:body>
      <:actions>
        <TioButton @linkTo="authenticated.slr.linking">
          {{t "pslf_dashboard.actions.add_accounts"}}
        </TioButton>
      </:actions>
    </Todo>

    <TioDialog
      @show={{this.showAccountsLinkedDialog}}
      @containerClass="w-full sm:max-w-screen-xl my-8"
      @onCancel={{fn this.toggleDialog "close"}}
    >
      <:header>
        <h1 class="text-xl text-tio-gray-500 font-semibold">{{t
            "pslf_dashboard.actions.accounts_linked"
          }}</h1>
      </:header>
      <:body>
        <AccountsLinkedLoansSummary
          @accounts={{@accounts}}
          @allowOverrideEdits={{true}}
          @person={{@person}}
          @loanSummary={{@loanSummary}}
        />
      </:body>
      <:actions>
      </:actions>
    </TioDialog>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
