import ApplicationAdapter from './application';
import { service } from '@ember/service';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import type TasApplication from 'tio-common/models/tas-application';
import type SessionContextService from '../services/session-context';
/**
 * An extended adapter for the `TasApplication` model.
 *
 * @memberof adapters
 */
class TasApplicationAdapter extends ApplicationAdapter {
  @service declare sessionContext: SessionContextService;
  /**
   * @param {TasApplication}  tasApplication - the TasApplication model for which to perform the action
   * @param {string} actionEndpoint - the server endpoint to append (ex. '/request-courses-approval')
   * @return {TasApplication}
   */
  async triggerActionAndReload(
    tasApplication: TasApplication,
    actionEndpoint: string,
    comment = ''
  ) {
    if (!tasApplication || !actionEndpoint) {
      return;
    }

    const url = this.buildURL('tas-application', tasApplication.id) + actionEndpoint;

    const name = this.sessionContext.user?.person?.fullName || '';
    const userId = this.sessionContext.user?.id || '';
    const currentRole = this.sessionContext.currentRole;
    const actingWithRole = {
      role: currentRole?.role,
      relationshipType: currentRole?.relationshipType,
    };
    const preApprovedTotal =
      getSingleValueForTasField('PRE_APPROVED_TOTAL', tasApplication.fields) || null;
    const finalApprovedTotal =
      getSingleValueForTasField('FINAL_APPROVED_TOTAL', tasApplication.fields) || null;
    const waiveEvidenceAttachmentRequirement =
      getSingleValueForTasField('WAIVE_EVIDENCE_ATTACHMENT_REQUIREMENT', tasApplication.fields) ||
      false;
    const waiveEvidenceApprovalRequirement =
      getSingleValueForTasField('WAIVE_EVIDENCE_APPROVAL_REQUIREMENT', tasApplication.fields) ||
      false;

    await this.ajax(url, 'PATCH', {
      data: {
        note: comment,
        preApprovedTotal: preApprovedTotal,
        finalApprovedTotal: finalApprovedTotal,
        waiveEvidenceAttachmentRequirement: waiveEvidenceAttachmentRequirement,
        waiveEvidenceApprovalRequirement: waiveEvidenceApprovalRequirement,
        by: {
          name,
          userId,
          actingWithRole,
        },
      },
    });
    const updatedModel = await tasApplication.reload();
    return updatedModel;
  }

  /**
   * @param {TasApplication} tasApplication - the tasApplication to submit for courses approval
   * @return {TasApplication}
   */

  async requestCoursesApproval(tasApplication: TasApplication, comment = '') {
    return this.triggerActionAndReload(tasApplication, '/request-courses-approval', comment);
  }

  async approveCourses(tasApplication: TasApplication, comment = '') {
    return this.triggerActionAndReload(tasApplication, '/approve-courses', comment);
  }

  async rejectCourses(tasApplication: TasApplication, comment = '') {
    return this.triggerActionAndReload(tasApplication, '/reject-courses', comment);
  }

  async requestChanges(tasApplication: TasApplication, comment = '') {
    return this.triggerActionAndReload(tasApplication, '/decline-courses', comment);
  }

  async requestCourseEvidence(tasApplication: TasApplication, comment = '') {
    return this.triggerActionAndReload(tasApplication, '/request-evidence-approval', comment);
  }

  async approveCourseEvidence(tasApplication: TasApplication, comment = '') {
    return this.triggerActionAndReload(tasApplication, '/approve-evidence', comment);
  }

  async rejectCourseEvidence(tasApplication: TasApplication, comment = '') {
    return this.triggerActionAndReload(tasApplication, '/reject-evidence', comment);
  }

  async requestCourseEvidenceChanges(tasApplication: TasApplication, comment = '') {
    return this.triggerActionAndReload(tasApplication, '/decline-evidence', comment);
  }

  async abandonCourse(tasApplication: TasApplication, comment = '') {
    return this.triggerActionAndReload(tasApplication, '/abandon', comment);
  }

  // TODO: Why do we accept unkonwn here? Once this is type checked should that be handled at the call site?
  async updateApprovedTotal(tasApplication: TasApplication, amount: unknown, reason = '') {
    if (!tasApplication || !reason || amount === null || amount === undefined) {
      throw new Error('Missing required parameters');
    }

    const url = this.buildURL('tas-application', tasApplication.id) + '/update-approved-total';
    await this.ajax(url, 'PATCH', {
      data: {
        amount,
        reason,
      },
    });
    const updatedModel = await tasApplication.reload({
      include: 'tas-assistances',
    });
    return updatedModel;
  }

  // TODO: Why do we accept unkonwn here? Once this is type checked should that be handled at the call site?
  async requestRepayment(tasApplication: TasApplication, amount: unknown, reason = '') {
    if (!tasApplication || !reason || amount === null || amount === undefined) {
      throw new Error('Missing required parameters');
    }

    const url = this.buildURL('tas-application', tasApplication.id) + '/request-repayment';
    await this.ajax(url, 'PATCH', {
      data: {
        amount,
        reason,
      },
    });
    const updatedModel = await tasApplication.reload({
      include: 'tas-assistances,tas-program-instance.employee.tas-participant',
    });
    return updatedModel;
  }

  async waiveEvidenceApproval(tasApplication: TasApplication) {
    const url = this.buildURL('tas-application', tasApplication.id) + '/waive-evidence-approval';
    await this.ajax(url, 'PATCH');
  }

  async recordRepayment(tasApplication: TasApplication, amount: number, reason = '') {
    if (!tasApplication || !reason || amount === null || amount === undefined) {
      throw new Error('Missing required parameters');
    }

    const url = this.buildURL('tas-application', tasApplication.id) + '/record-repayment';
    await this.ajax(url, 'PATCH', {
      data: {
        amount,
        reason,
      },
    });
    const updatedModel = await tasApplication.reload({
      include: 'tas-assistances,tas-program-instance.employee.tas-participant',
    });
    return updatedModel;
  }

  async insertReviewer(tasApplication: TasApplication, employeeId: number, ordinal: number) {
    if (!tasApplication || !employeeId || !ordinal) {
      throw new Error('Missing required parameters');
    }

    const url = this.buildURL('tas-application', tasApplication.id) + '/insert-reviewer';
    await this.ajax(url, 'PATCH', {
      data: {
        'employee-id': employeeId,
        ordinal,
      },
    });

    return await tasApplication.reload();
  }
}

export default TasApplicationAdapter;
