/* eslint-disable ember/no-get */ import { template } from "@ember/template-compiler";
import { get } from '@ember/object';
import { service } from '@ember/service';
import AppSidebarNavGroupsEmployeeMyAccountActivity from './nav-groups/employee/my-account-activity';
import AppSidebarNavGroupsEmployeePlanningForCollege from './nav-groups/employee/planning-for-college';
import AppSidebarNavGroupsEmployeePslf from './nav-groups/employee/pslf';
import AppSidebarNavGroupsEmployeeQuestions from './nav-groups/employee/questions';
import AppSidebarNavGroupsEmployeeRepayingStudentDebt from './nav-groups/employee/repaying-student-debt';
import Component from '@glimmer/component';
import type EmployeeService from 'tio-employee/services/employee';
import type FeaturesService from 'tio-common/services/features';
import type IntlService from 'ember-intl/services/intl';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';
import type UiSidebarNavComponent from 'tio-common/components/ui/sidebar/nav';
import { and, not } from 'ember-truth-helpers';
export interface AppSidebarEmployeeNavComponentSignature {
    Args: {
        nav: typeof UiSidebarNavComponent;
    };
}
let AppSidebarEmployeeNavComponent = class AppSidebarEmployeeNavComponent extends Component<AppSidebarEmployeeNavComponentSignature> {
    @service
    partner: PartnerService;
    @service
    employee: EmployeeService;
    @service
    sessionContext: SessionContextService;
    @service
    intl: IntlService;
    @service
    features: FeaturesService;
    get isPslfEnabled() {
        return this.partner.isPslfEnabled;
    }
    get isFaqEnabled() {
        return !this.partner.supportModule || this.partner.isFaqEnabled;
    }
    get hasTuitionAssistance() {
        return this.partner.hasNewTuitionAssistance || this.employee.hasLegacyTuitionAssistance;
    }
    get isTaOnly() {
        return this.partner.isTaOnly || this.employee.isTaOnlyEmployee;
    }
    get isTaEnabledSettingExists() {
        return this.partner.companySettings.tuitionAssistance && 'isTaEnabled' in this.partner.companySettings.tuitionAssistance ? true : false;
    }
    get isTaSettingEnabled() {
        return (this.partner.companySettings.tuitionAssistance && !!this.partner.companySettings.tuitionAssistance.isTaEnabled);
    }
    get showTuitionAssistance() {
        if (this.isTaEnabledSettingExists) {
            return this.partner.hasNewTuitionAssistance && this.isTaSettingEnabled;
        } else {
            return this.hasTuitionAssistance;
        }
    }
    get hasContributions() {
        return this.employee.hasContributions;
    }
    get canInviteFamily() {
        return this.partner.doesAllowFamilyPlan && !this.sessionContext.isFamilyMember;
    }
    get tuitionAssistanceLabel() {
        const settings1 = this.partner.companySettings;
        const translationKey1 = get(settings1, 'employeePortalTab')?.toLowerCase();
        return translationKey1 ? this.intl.t(`sidebar.${translationKey1}`) : this.intl.t('sidebar.tuition_assistance');
    }
    get idrEnabled() {
        return this.partner.isIdrEnabled;
    }
    get isSyfEnabled() {
        return this.partner.companySettings.modules?.syf?.isEnabled;
    }
    static{
        template(`
    {{#let @nav as |nav|}}
      {{#if (and this.isTaOnly this.showTuitionAssistance)}}
        {{! @glint-expect-error: no idea}}
        <nav.item
          @nestedRoute="tuition-assistance"
          @icon="school"
          @label={{this.tuitionAssistanceLabel}}
          data-test-sidebar-employee-primary-tuition-assistance
        />
      {{else if (and this.isTaOnly (not this.showTuitionAssistance))}}
        {{! @glint-expect-error: no idea}}
        <nav.item @nestedRoute="dashboard" @icon="home" @label="Dashboard" />
      {{else}}
        {{! @glint-expect-error: no idea}}
        <nav.item @nestedRoute="dashboard" @icon="home" @label="Dashboard" />

        <AppSidebarNavGroupsEmployeeMyAccountActivity
          @nav={{nav}}
          @hasContributions={{this.hasContributions}}
        />

        {{#if this.isPslfEnabled}}
          <AppSidebarNavGroupsEmployeePslf @nav={{nav}} />
        {{/if}}

        {{#if this.idrEnabled}}
          {{! @glint-expect-error: no idea}}
          <nav.item
            @nestedRoute="idr.dashboard"
            @icon="currency_exchange"
            @label="Income-Driven Repayment"
            data-test-sidebar-employee-primary-income-driven-repayment
          />
        {{/if}}

        {{#if this.showTuitionAssistance}}
          {{! @glint-expect-error: no idea}}
          <nav.item
            @nestedRoute="tuition-assistance"
            @icon="school"
            @label={{this.tuitionAssistanceLabel}}
            data-test-sidebar-employee-primary-tuition-assistance
          />
        {{/if}}

        {{#if this.isSyfEnabled}}
          {{! @glint-expect-error: no idea}}
          <nav.item
            {{! TODO: once more screens are made have index figure out which route to go to. }}
            @nestedRoute="syf.enrollment"
            {{! TODO: Update icon to zeplin when someone can point me to it. }}
            @icon="savings"
            @label="Secure Your Future"
            data-test-sidebar-employee-primary-secure-your-future
          />
        {{/if}}

        <AppSidebarNavGroupsEmployeeRepayingStudentDebt
          @nav={{nav}}
          @canInviteFamily={{this.canInviteFamily}}
          data-test-sidebar-employee-primary-repaying-student-debt
        />
        <AppSidebarNavGroupsEmployeePlanningForCollege
          @nav={{nav}}
          @canInviteFamily={{this.canInviteFamily}}
          data-test-sidebar-employee-primary-planning-for-college
        />
        <AppSidebarNavGroupsEmployeeQuestions @nav={{nav}} @faqEnabled={{this.isFaqEnabled}} />
      {{/if}}
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default AppSidebarEmployeeNavComponent;
