import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type PslfFormModel from 'tio-common/models/pslf-form';
import type PslfEmployerController from '../../controllers/pslf-employer';
interface S {
    Args: {
        model: {
            documentId: string;
            initialValues: PslfFormModel;
        };
        controller: PslfEmployerController;
    };
}
let PslfNonPartnerCertificationRoute = class PslfNonPartnerCertificationRoute extends Component<S> {
    get documentId() {
        return this.args.model.documentId;
    }
    static{
        template(`
    <div>
      <p>{{this.documentId}}</p>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(PslfNonPartnerCertificationRoute);
