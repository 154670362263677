import { template } from "@ember/template-compiler";
import { concat, fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { or, eq, not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import ActionButton from 'tio-common/components/pslf/my-documents/action-button';
import MaterialIcon from 'tio-common/components/material-icon';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import TioPreviewButton from 'tio-common/components/tio/preview-button';
import Tippy from 'ember-tippy/components/tippy';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import type PslfFormModel from 'tio-common/models/pslf-form';
interface S {
    Args: {
        doFormAction?: (action: string, model: PslfFormModel) => void;
        isLoading: boolean;
        isResending?: boolean;
        model: PslfFormModel;
        onPreview: (model: PslfFormModel) => void;
    };
}
const PslfMyDocumentsMyFormsTableRowComponent: TOC<S> = template(`
  <tr>
    {{#let "px-2 py-1 border border-gray-300" as |cellClass|}}
      <td class={{cellClass}}>
        <TioPreviewButton
          @onClick={{fn @onPreview @model}}
          @isDisabled={{not @model.hasPdfPreview}}
        />
      </td>
      {{#let @model.employerCompletedAt as |date|}}
        <td class={{cellClass}} title={{date}}>
          {{#if date}}
            {{safeFormatDate date month="short" day="2-digit" year="numeric"}}
          {{else if (or @model.isPrintAndMail @model.isPriorEmployerClosed)}}
            {{t "pslf.table.not_applicable"}}
          {{else if @model.isAwaitingSignature}}
            {{t "pslf.table.pending"}}
          {{/if}}
        </td>
      {{/let}}

      {{#let @model.borrowerCompletedAt as |date|}}
        <td class={{cellClass}} title={{date}}>
          {{#if date}}
            {{safeFormatDate date month="short" day="2-digit" year="numeric"}}
          {{else}}
            {{t "pslf.table.pending"}}
          {{/if}}
        </td>
      {{/let}}

      <td class={{cellClass}}>
        {{t (concat "pslf.status." @model.status)}}
        {{#if
          (or
            (eq @model.status "canceled")
            (eq @model.status "cancelled")
            (eq @model.status "submitted")
          )
        }}
          <span class="align-middle">
            <MaterialIcon
              @icon="info"
              class="mx-2 text-xl leading-5 cursor-default text-ocean-600"
            />
            <Tippy @placement="right" @interactive={{true}} as |tippyInstance|>
              <div class="flex">
                <label class="text-white">
                  {{t (concat "pslf.status_tippy." @model.status) htmlSafe=true}}
                </label>
                <button class="self-start" type="button" {{on "click" tippyInstance.hide}}>
                  <MaterialIcon class="text-sm" @icon="close" />
                </button>
              </div>
            </Tippy>
          </span>
        {{/if}}
      </td>
      <td class={{cellClass}}>
        {{@model.approverDisplayEmail}}
      </td>
      <td class={{cellClass}}>
        <div class="truncate">
          {{t (concat "pslf.form-type." @model.formType)}}
          <br />
          {{#if @model.isPriorEmployer}}
            -
            {{@model.priorEmployerName}}
          {{else}}
            {{@model.employerDisplayName}}
          {{/if}}
        </div>
      </td>
      <td class={{cellClass}}>
        <div class="flex justify-end">
          <ActionButton
            @icon="schedule_send"
            @label={{t "pslf.document_repo.resend"}}
            @loading={{@isResending}}
            @isDisabled={{not @model.canResendEmail}}
            {{! @glint-expect-error: we need to use less optionals}}
            @onClick={{fn @doFormAction "resend" @model}}
          />
          <ActionButton
            @icon="mark_email_read"
            @label={{t "pslf.document_repo.change_approver"}}
            @isDisabled={{not @model.canChangeApprover}}
            {{! @glint-expect-error: we need to use less optionals}}
            @onClick={{fn @doFormAction "changeApprover" @model}}
            @loading={{false}}
          />
          <ActionButton
            @icon="block"
            @label={{t "disable"}}
            @isDisabled={{not @model.canCancel}}
            {{! @glint-expect-error: we need to use less optionals}}
            @onClick={{fn @doFormAction "cancel" @model}}
            class={{if @model.canCancel "!text-red-500"}}
            @loading={{false}}
          />
        </div>
      </td>
    {{/let}}
  </tr>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PslfMyDocumentsMyFormsTableRowComponent;
