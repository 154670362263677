import { template } from "@ember/template-compiler";
import { and } from 'ember-truth-helpers';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AppPageProsVsCons from './pros-vs-cons';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import TioPageHorizontalRule from 'tio-common/components/tio/page/horizontal-rule';
import { action } from '@ember/object';
interface AppPagePlanDetailsCollapsibleSignature {
    Args: {
        title?: string;
        description?: string;
        details: Array<{
            title: string;
            description: string;
        }>;
        pros?: string[];
        cons?: string[];
        moreSections?: Array<{
            title: string;
            description: string;
        }>;
    };
}
// component requires the @details attribute:
// @details must be an array of objects, each with a title (string) and a description (string)
// --All above strings must be translation keys--
// component expects @pros & @cons
// @pros & @cons must be arrays of strings
// --All above strings must be translation keys--
// --If either @pros or @cons is not given, niether will be displayed--
// component can also handle three optional atributes:
// @title must be a string, otherwise will default to "plan details"
// @description must be a string
// @moreSections must be an array of objects, each with a title (string) and a description (string)
// --All above strings must be translation keys--
export default class AppPagePlanDetailsCollapsibleComponent extends Component<AppPagePlanDetailsCollapsibleSignature> {
    @tracked
    isCollapsed = true;
    @action
    toggleCollapsed() {
        this.isCollapsed = !this.isCollapsed;
    }
    static{
        template(`
    <div class="p-4 mt-6 bg-gray-50">
      <h1 class="text-midnight text-xl font-semibold">
        {{if @title (t @title) (t "plan_details.title")}}
      </h1>
      {{#if @description}}
        <p class="pt-4">
          {{t @description htmlSafe=true}}
        </p>
      {{/if}}
      <div class="pt-6 pb-2 flex flex-col sm:grid sm:gap-2 sm:grid-cols-{{@details.length}}">
        {{#each @details as |detail|}}
          <div>
            <p class="pt-4 font-semibold text-gray-600">
              {{t detail.title}}
            </p>
            <p>
              {{t detail.description htmlSafe=true}}
            </p>
          </div>
        {{/each}}
      </div>

      {{#if this.isCollapsed}}
        <div class="flex justify-end">
          <button
            type="button"
            {{on "click" this.toggleCollapsed}}
            class="text-center uppercase flex items-center justify-center p-2 text-ocean-600 font-semibold"
          >
            {{t "plan_details.read_more"}}
            <MaterialIcon @icon="expand_more" class="p-x-4 text-3xl" />
          </button>
        </div>

      {{else}}
        {{#if (and @pros @cons)}}
          <TioPageHorizontalRule @size="sm" />
          <AppPageProsVsCons @pros={{@pros}} @cons={{@cons}} />
        {{/if}}
        {{#if @moreSections}}
          <TioPageHorizontalRule @size="sm" />
          {{#each @moreSections as |section|}}
            <div>
              <p class="pt-4 font-semibold text-gray-600">
                {{t section.title}}
              </p>
              <p class="pt-4">
                {{t section.description htmlSafe=true}}
              </p>
            </div>
          {{/each}}
        {{/if}}
        <div class="flex justify-end">
          <button
            type="button"
            {{on "click" this.toggleCollapsed}}
            class="text-center uppercase flex items-center justify-center p-2 text-ocean-600 font-semibold"
          >
            {{t "plan_details.read_less"}}
            <MaterialIcon @icon="expand_less" class="p-x-4 text-3xl" />
          </button>
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
