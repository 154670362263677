import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AccountsLinkedLoansSummary from 'tio-employee/components/accounts/linked-loans-summary';
import CardInfo from '../card-info';
import Component from '@glimmer/component';
import NumberBadge from '../todo/number-badge';
import TioButton from 'tio-common/components/tio/button';
import TioDialog from 'tio-common/components/tio/dialog';
import Todo from '../todo';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type AccountModel from 'tio-common/models/account';
import type PersonModel from 'tio-common/models/person';
import type RecommendationModel from 'tio-common/models/recommendation';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
export interface PslfDashboardActionsEstimatorTodoSignature {
    Args: {
        accounts: AccountModel[];
        itemNo: string;
        loanSummary: LoanSummary;
        person: PersonModel;
        recommendation?: RecommendationModel;
        isCanceling?: boolean;
    };
    Element: HTMLElement;
}
export default class PslfDashboardActionsEstimatorTodoComponent extends Component<PslfDashboardActionsEstimatorTodoSignature> {
    @service
    store: StoreService;
    @service
    sessionContext: SessionContextService;
    @service
    router: RouterService;
    @tracked
    loanErrors = [];
    @tracked
    showMissingInfoDialog = false;
    @tracked
    showEstimatorBlockDialog = false;
    get estimatorComplete() {
        return !!this.args.recommendation;
    }
    @action
    checkLoansAndGoToEstimator() {
        const summary1 = this.args.loanSummary;
        this.loanErrors = summary1.errors || [];
        if (!summary1.summaryByLoanType.length) {
            this.showEstimatorBlockDialog = true;
        } else {
            if (this.loanErrors.length === 0) {
                this.router.transitionTo('authenticated.pslf.dashboard.forgiveness-estimator.index');
            } else {
                this.showMissingInfoDialog = true;
            }
        }
    }
    static{
        template(`
    <Todo
      @title={{t "pslf_dashboard.actions.forgiveness_estimator_status"}}
      @isComplete={{this.estimatorComplete}}
    >
      <:leftHeader>
        <NumberBadge @number={{@itemNo}} />
      </:leftHeader>
      <:body>
        <CardInfo
          @icon="calculate"
          @text={{t "pslf_dashboard.actions.let_us_estimate_forgive"}}
          class="w-fit"
        />
      </:body>
      <:actions>
        <TioButton @onClick={{this.checkLoansAndGoToEstimator}}>
          {{t "pslf_dashboard.actions.forgiveness_estimator"}}
        </TioButton>
      </:actions>
    </Todo>

    <TioDialog
      @show={{this.showMissingInfoDialog}}
      @containerClass="w-full sm:max-w-screen-xl my-8"
      @bodyClass="!px-4"
      @onCancel={{fn (mut this.showMissingInfoDialog) false}}
      @submitButtonText="Continue to estimator"
      @onConfirm={{this.checkLoansAndGoToEstimator}}
    >
      <:header>
        <h1 class="text-xl text-tio-gray-500 font-semibold">
          {{@person.firstName}},
          {{t "pslf_dashboard.actions.dialog.we_need_your_help"}}
        </h1>
      </:header>
      <:body>
        <p class="text-tio-gray-400 mb-5">
          {{t "pslf_dashboard.actions.dialog.please_provide_info"}}
        </p>
        <AccountsLinkedLoansSummary
          @accounts={{@accounts}}
          @allowOverrideEdits={{true}}
          @loanSummary={{@loanSummary}}
          @person={{@person}}
        />
      </:body>
    </TioDialog>

    <TioDialog
      @show={{this.showEstimatorBlockDialog}}
      @containerClass="w-full sm:max-w-screen-sm"
      @onCancel={{fn (mut this.showEstimatorBlockDialog) false}}
      @headerClass="font-semibold"
      @bodyClass="text-center"
      @actionsClass="flex justify-center"
      @removeCloseBtn={{true}}
    >
      <:body>
        <p class="text-xl text-center text-gray-600 font-semibold mb-4">
          {{t "pslf_dashboard.actions.dialog.before_proceeding"}}
        </p>

        <p>{{t "pslf_dashboard.actions.dialog.we_will_not_be_able"}}
        </p>
      </:body>
      <:actions as |actions|>
        <TioButton @onClick={{actions.cancel}} @loading={{@isCanceling}} class="min-w-48">
          {{t "ok"}}
        </TioButton>
      </:actions>
    </TioDialog>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
