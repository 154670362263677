import { template } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface AppHeaderCurrentUserMenuHeaderSig {
    Args: {
        icon?: string;
        title: string;
    };
    Element: HTMLDivElement;
}
const AppHeaderCurrentUserMenuHeaderComponent: TOC<AppHeaderCurrentUserMenuHeaderSig> = template(`
  <div class="px-4 py-2 flex items-center cursor-default" ...attributes>
    {{#if @icon}}
      <span class="inline-flex mr-2 material-icons">{{@icon}}</span>
    {{/if}}

    {{@title}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppHeaderCurrentUserMenuHeaderComponent;
