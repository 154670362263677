import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { and, not } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import AccountsLinkedLoansSummary from './linked-loans-summary';
import AppContentCard from 'tio-common/components/app/content-card';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import { t } from 'ember-intl';
import TioButton from 'tio-common/components/tio/button';
import TioDialog from 'tio-common/components/tio/dialog';
import TioPageHorizontalRule from 'tio-common/components/tio/page/horizontal-rule';
import type AccountModel from 'tio-common/models/account';
import type EmployeeService from 'tio-employee/services/employee';
import type LoanModel from 'tio-common/models/loan';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type PersonModel from 'tio-common/models/person';
import type SessionContextService from 'tio-employee/services/session-context';
import type Store from '@ember-data/store';
export interface LinkedAccountsCardSignature {
    Args: {
        accounts: AccountModel[];
        person: PersonModel;
        programName: string;
        loanSummary: LoanSummary;
    };
    Element: HTMLElement;
}
export default class LinkedAccountsCard extends Component<LinkedAccountsCardSignature> {
    @service
    sessionContext: SessionContextService;
    @service
    store: typeof Store;
    @service
    employee: EmployeeService;
    @tracked
    showViewDetailsDialog = false;
    // @tracked showLoanInDefaultDialog = false;
    // @tracked anyLoanInDefault = null;
    @tracked
    hasAcknowledgedDefaultDialog = false;
    get activeAccounts() {
        return this.args.accounts.filter((account1: AccountModel)=>account1.isActive);
    }
    get accountInstitutionDisplayNames() {
        return this.activeAccounts.slice().map((account1: AccountModel)=>account1.institutionName);
    }
    get hasActiveLinkedAccounts() {
        return !!this.activeAccounts.length;
    }
    get showDialog() {
        return this.showLoanInDefaultDialog;
    }
    get showLoanInDefaultDialog() {
        const anyLoanInDefault1 = this.args.accounts.flatMap((account1: AccountModel)=>account1.loans).some((loan1: LoanModel)=>loan1.status === 'DEFAULT');
        return anyLoanInDefault1;
    }
    @action
    cancelDefaultLoanDialog() {
        if (this.showLoanInDefaultDialog && !this.hasAcknowledgedDefaultDialog) {
            this.hasAcknowledgedDefaultDialog = true;
        }
    }
    static{
        template(`
    <AppContentCard @titleClass="uppercase" @title="{{t 'idr.accounts.default'}}" ...attributes>
      <TioPageHorizontalRule @size="sm" />
      {{#if this.hasActiveLinkedAccounts}}
        <p>{{t "idr.accounts.linked_below"}}</p>
        <div class="grid grid-cols-1 justify-items-center min-w-full">
          <ul class="font-bold min-w-[75%] mx-4">
            {{#each this.accountInstitutionDisplayNames as |name|}}
              <li class="my-2 p-2 bg-ocean-50 flex justify-between">
                <p class="flex-wrap">{{name}}</p>
                <MaterialIcon @icon="check_circle" class="text-emerald-500" />
              </li>
            {{/each}}
          </ul>
          <div>
            <TioButton class="my-3 px-8" @linkTo="authenticated.slr.linking">
              {{t "idr.accounts.add_more_accounts"}}
            </TioButton>
            <TioButton
              @outlined={{true}}
              class="my-3 min-w-full"
              @onClick={{fn (mut this.showViewDetailsDialog) true}}
            >
              {{t "idr.accounts.view_details"}}
            </TioButton>
          </div>
        </div>
      {{else}}
        <div class="grid grid-cols-1 justify-items-center min-w-full">
          <p>{{t "no_linked_accounts_alert.please_link_loans" program=@programName}}</p>
          <div>
            <TioButton class="my-3 px-8" @linkTo="authenticated.slr.linking">
              {{t "pslf_dashboard.actions.add_accounts"}}
            </TioButton>
          </div>
        </div>
      {{/if}}
    </AppContentCard>

    <TioDialog
      @show={{this.showViewDetailsDialog}}
      @containerClass="w-full sm:max-w-screen-xl my-8"
      @onCancel={{fn (mut this.showViewDetailsDialog) false}}
    >
      <:header>
        <h1 class="text-xl text-tio-gray-500 font-semibold">
          {{t "idr.accounts.accounts_linked"}}
        </h1>
      </:header>
      <:body>
        <AccountsLinkedLoansSummary
          @accounts={{@accounts}}
          @allowOverrideEdits={{true}}
          @loanSummary={{@loanSummary}}
          @person={{@person}}
        />
      </:body>
      <:actions>

      </:actions>
    </TioDialog>

    <TioDialog
      @show={{and this.showLoanInDefaultDialog (not this.hasAcknowledgedDefaultDialog)}}
      @containerClass="w-full sm:max-w-screen-sm"
      @onCancel={{this.cancelDefaultLoanDialog}}
    >
      <:body>
        {{t "default_loan.text"}}
      </:body>
      <:actions>
        <div class="flex justify-center">
          <TioButton @linkTo="authenticated.repaying-student-debt.askjeni" class="max-w-fit">
            {{t "default_loan.button"}}
          </TioButton>
        </div>
      </:actions>
    </TioDialog>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
