import { template } from "@ember/template-compiler";
import AppPage from 'tio-employee/components/app/page';
import { action } from '@ember/object';
import { and, not, gt } from 'ember-truth-helpers';
import { concat } from '@ember/helper';
import { fn } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import FormCheckbox from '@frontile/forms-legacy/components/form-checkbox';
import FormInput from '@frontile/forms-legacy/components/form-input';
import MaterialIcon from 'tio-common/components/material-icon';
import RouteTemplate from 'ember-route-template';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import TioButton from 'tio-common/components/tio/button';
import type PreRegisterService from 'tio-employee/services/pre-register';
import type PslfFormModel from 'tio-common/models/pslf-form';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type Store from '@ember-data/store';
interface S {
    Args: {
        model: {
            pslfForm: PslfFormModel;
            employerFormInfo: EmployerForm;
            documentId: string;
        };
    };
}
const maxVerificationAttempts = 5;
export type EmployerForm = {
    companyName: string;
    borrowerName: string;
    borrowerSignDate: string;
    phone: string;
    email: string;
    verifyWithEmail: boolean;
    status: string;
    pslfFormId: string;
    employerSignDate: string;
};
let PslfEmployerVerificationRoute = class PslfEmployerVerificationRoute extends Component<S> {
    @service
    router: RouterService;
    @service
    store: typeof Store;
    @service
    preRegister: PreRegisterService;
    @service
    sessionContext: SessionContextService;
    @tracked
    companyName = '';
    @tracked
    borrowerName = '';
    @tracked
    borrowerSignDate = '';
    @tracked
    phoneNumber = '';
    @tracked
    borrowerSsn = '';
    @tracked
    verifyWithEmail = false;
    @tracked
    verificationEmail = '';
    @tracked
    verificationCodeSent = false;
    @tracked
    verificationCode = '';
    @tracked
    verificationAttempts = 0;
    @tracked
    isVerified = false;
    @tracked
    unableToReceiveTexts = false;
    @tracked
    isLoading = false;
    constructor(owner1: unknown, args1: S['Args']){
        super(owner1, args1);
        const employerFormInfo1 = this.args.model.employerFormInfo;
        this.companyName = employerFormInfo1.companyName;
        this.borrowerName = employerFormInfo1.borrowerName;
        this.borrowerSignDate = employerFormInfo1.borrowerSignDate;
        this.phoneNumber = this.enforceUSPhonePrefix(employerFormInfo1.phone);
        this.verifyWithEmail = employerFormInfo1.verifyWithEmail;
        this.verificationEmail = employerFormInfo1.email;
        if (employerFormInfo1.status === 'cancelled') {
            this.router.transitionTo('/cancelled');
        } else if (employerFormInfo1.employerSignDate) {
            this.router.transitionTo('/sign');
        } else if (this.isSignerAuthenticated) {
            this.router.transitionTo('/workflow');
        }
        return;
    }
    enforceUSPhonePrefix(input1: string): string {
        // Remove any non-numeric characters from the input
        const digits1 = input1.replace(/\D/g, '');
        // Check if the cleaned number has 10 digits (add "1") or is an 11-digit starting with "1"
        if (digits1.length === 10) {
            return `+1${digits1}`;
        } else if (digits1.length === 11 && digits1.startsWith('1')) {
            return `+${digits1}`;
        }
        // If the input is not 10 digits (after cleaning), or 11 digits starting with "1", it's invalid
        throw new Error('Invalid phone number. Expected 10 digits or 11 digits starting with "1".');
    }
    get tempCompany() {
        return this.preRegister.sparseCompany;
    }
    get isSignerAuthenticated() {
        return !!this.sessionContext.currentRole?.id && this.sessionContext.user;
    }
    get startWorkflowRoute() {
        return 'pslf-employer-step-start-date';
    }
    get remainingAttempts() {
        return maxVerificationAttempts - this.verificationAttempts;
    }
    @action
    async sendVerificationCode() {
        if (this.verifyWithEmail) {
            await this.store.adapterFor('pslf-form').sendEmployerVerificationEmail({
                id: this.args.model.pslfForm.id
            });
        } else {
            await this.store.adapterFor('pslf-form').sendEmployerVerificationCode({
                id: this.args.model.pslfForm.id,
                channel: this.unableToReceiveTexts ? 'call' : 'sms'
            });
        }
        this.verificationCodeSent = true;
    }
    @action
    async verifyEmployerContact() {
        this.isLoading = true;
        let response1;
        if (this.verifyWithEmail) {
            response1 = await this.store.adapterFor('pslf-form').verifyEmployerEmail({
                id: this.args.model.pslfForm.id,
                verificationCode: this.verificationCode
            });
        } else {
            response1 = await this.store.adapterFor('pslf-form').verifyEmployerPhone({
                id: this.args.model.pslfForm.id,
                verificationCode: this.verificationCode
            });
        }
        this.isVerified = !!response1?.verified;
        if (!response1?.verified) {
            this.verificationCode = '';
            this.verificationAttempts++;
        }
        this.borrowerSsn = response1?.lastFour || '';
        this.isLoading = false;
    }
    static{
        template(`
    <AppPage @title={{t "pslf.employer_sign_page_title"}}>

      {{! Email Verification }}
      {{#if this.verifyWithEmail}}
        <div class="flex flex-col align-center justify-center mx-auto">
          <h2 class="mb-4">
            {{t "pslf.phone.enter_continue"}}
          </h2>
          <p>
            {{t "pslf.phone.dept_required"}}
          </p>
          {{#let (safeFormatDate this.borrowerSignDate) as |formattedDate|}}
            <p class="mb-10">{{t
                "pslf.phone.approver"
                company=this.companyName
                formSubmittedDate=formattedDate
                borrowerName=this.borrowerName
              }}
            </p>
          {{/let}}
          <p>
            {{t "pslf.email.security"}}
          </p>
          <p><b>{{this.verificationEmail}}</b></p>
          <p class="mr-6">
            {{t
              (concat
                "pslf.email." (if this.verificationCodeSent "enter_expire" "security_confirmation")
              )
            }}
          </p>
          {{#if (and (not this.isVerified) (gt this.verificationAttempts 0))}}
            <p class="error">
              {{#if this.remainingAttempts}}
                {{t "pslf.phone.verification_failed" remainingAttempts=this.remainingAttempts}}
              {{else}}
                {{t "pslf.phone.verification_attempts_exceeded"}}
              {{/if}}
            </p>
          {{/if}}
          {{#if (not this.verificationCodeSent)}}
            <TioButton class="submit-btn mt-4" @onClick={{this.sendVerificationCode}}>
              {{t "pslf.phone.send_code"}}
            </TioButton>
          {{else if this.remainingAttempts}}
            <div class="flex flex-col">
              {{#if this.isVerified}}
                <div class="flex flex-col align-center">
                  <h2 class="mt-4">{{t "success"}}</h2>
                  <div class="flex flex-col align-center">
                    <MaterialIcon @icon="check" class="mt-3" />
                    <h6 class="ma-3">{{t "pslf.email.verified"}}</h6>
                  </div>
                  <LinkTo @route={{this.startWorkflowRoute}}>{{t "continue"}}</LinkTo>
                </div>
              {{else}}
                <FormInput
                  data-legacy-input
                  @value={{this.verificationCode}}
                  containerClass="mt-4"
                  @onInput={{fn (mut this.verificationCode)}}
                />
                <p class="text-center">
                  {{t "didnt_get_code"}}
                  <TioButton @onClick={{this.sendVerificationCode}}>
                    {{t "resend_code"}}
                  </TioButton>
                </p>
                <TioButton @onClick={{this.verifyEmployerContact}}>
                  {{t "pslf.phone.submit_code"}}
                </TioButton>
              {{/if}}
            </div>
          {{/if}}
        </div>
        {{! Phone Verification }}
      {{else}}
        <div class="flex flex-col align-center justify-center mx-auto">
          <h2 class="mb-4">
            {{t "pslf.phone.enter_continue"}}
          </h2>
          <p>
            {{t "pslf.phone.dept_required"}}
          </p>
          {{#let (safeFormatDate this.borrowerSignDate) as |formattedDate|}}
            <p class="mb-10">
              {{t
                "pslf.phone.approver"
                company=this.companyName
                formSubmittedDate=formattedDate
                borrowerName=this.borrowerName
              }}</p>{{/let}}
          <p> {{t "pslf.phone.security"}}</p>
          <p>{{this.phoneNumber}}</p>
          <p class="mr-6">
            {{t
              (concat
                "pslf.phone." (if this.verificationCodeSent "enter_expire" "security_confirmation")
              )
            }}
          </p>
          {{#if (and (not this.isVerified) (gt this.verificationAttempts 0))}}
            <p class="error--text">
              {{#if this.remainingAttempts}}
                {{t "pslf.phone.verification_failed" remainingAttempts=this.remainingAttempts}}
              {{else}}
                {{t "pslf.phone.verification_attempts_exceeded"}}
              {{/if}}
            </p>
          {{/if}}
          <div class="d-flex align-center">
            <FormCheckbox
              data-legacy-input
              @checked={{this.unableToReceiveTexts}}
              @onChange={{fn (mut this.unableToReceiveTexts)}}
            />
            <label for="unableToReceiveTexts" class="fixed-width-checkbox">
              {{t "pslf.phone.check_box"}}
            </label>
          </div>
          {{#if (not this.verificationCodeSent)}}
            <TioButton class="submit-btn mt-4" @onClick={{this.sendVerificationCode}}>
              {{t "pslf.phone.send_code"}}
            </TioButton>
          {{else if this.remainingAttempts}}
            <div class="flex flex-col">
              {{#if this.isVerified}}
                <div class="flex flex-col align-center">
                  <h2 class="mt-4">{{t "success"}}</h2>
                  <div class="flex flex-col align-center">
                    <MaterialIcon @icon="check" class="mt-3" />
                    <h6 class="ma-3">{{t "pslf.phone.verified"}}</h6>
                  </div>
                  <LinkTo @route="startWorkflowRoute" class="btn btn-accent">{{t
                      "continue"
                    }}</LinkTo>
                </div>
              {{else}}
                <FormInput
                  data-legacy-input
                  type="text"
                  maxlength="6"
                  containerClass="mt-4"
                  @value={{this.verificationCode}}
                  @onInput={{fn (mut this.verificationCode)}}
                />
                <p class="pa-0 font-weight-light">
                  {{t "didnt_get_code"}}
                  <TioButton @onClick={{this.sendVerificationCode}}>
                    {{t "resend_code"}}
                  </TioButton>
                </p>
                <TioButton class="submit-btn" @onClick={{this.verifyEmployerContact}}>
                  {{t "pslf.phone.submit_code"}}
                </TioButton>
              {{/if}}
            </div>
          {{/if}}
        </div>
      {{/if}}
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(PslfEmployerVerificationRoute);
