import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import LoginHere from '../login-here';
interface AuthRegisterNotLaunchedComponentSignature {
    Args: {
        companyName: string;
        days: number;
    };
}
const AuthRegisterNotLaunchedComponent: TOC<AuthRegisterNotLaunchedComponentSignature> = template(`
  <div class="p-5">
    <p class="text-xl mb-6">
      {{t "login.not_launched" companyName=@companyName days=@days}}
    </p>
    <LoginHere />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AuthRegisterNotLaunchedComponent;
