import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { not } from 'ember-truth-helpers';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type RouterService from '@ember/routing/router-service';
import RouteTemplate from 'ember-route-template';
import { isNullish } from 'tio-common/utils/is-nullish';
import type { ErrorsSignature } from 'tio-common/components/tio/error-messages';
import type SessionContextService from 'tio-employee/services/session-context';
import SyfEnrollmentComplete from 'tio-employee/components/syf/enrollment-complete';
import SyfAccountConfirmation from 'tio-employee/components/syf/account-confirmation';
import SyfAlternateFlowEnrollment from 'tio-employee/components/syf/alternate-flow-enrollment';
import type { AuthenticatedSyfAccountConfirmationRouteModel } from 'tio-employee/routes/authenticated/syf/account-confirmation';
interface RouteSignature {
    Args: {
        model: AuthenticatedSyfAccountConfirmationRouteModel;
    };
}
let SyfAccountConfirmationRouteComponent = class SyfAccountConfirmationRouteComponent extends Component<RouteSignature> {
    @service
    router: RouterService;
    @service
    sessionContext: SessionContextService;
    @tracked
    loading: boolean = false;
    @tracked
    errors: ErrorsSignature | string | undefined;
    @tracked
    finished = false;
    get hasLinkedAccounts() {
        return !!this.args.model.person.activeAccounts.length;
    }
    get sumRegularMonthlyPaymentInCents() {
        return (this.args.model.person.activeAccounts.reduce((acc1, account1)=>{
            return acc1 + (account1.providerRegularMonthlyPayment || 0);
        }, 0) * 100);
    }
    @action
    async didConfirmEnrollment() {
        try {
            this.loading = true;
            const matchParticipant1 = this.args.model.matchParticipant;
            await matchParticipant1.activateMatchParticipant();
            if (isNullish(matchParticipant1.reportedMonthlyPayment)) {
                matchParticipant1.reportedMonthlyPayment = this.sumRegularMonthlyPaymentInCents;
                await matchParticipant1.save();
            }
            this.finished = true;
        } catch (e1) {
            if (e1.isAdapterError) {
                this.errors = e1;
            } else {
                this.errors = 'Activation failed.';
            }
        } finally{
            this.loading = false;
        }
    }
    @action
    didCancel() {
        this.router.transitionTo('authenticated.syf.enrollment');
    }
    static{
        template(`
    {{#if this.finished}}
      <SyfEnrollmentComplete />
    {{else if this.hasLinkedAccounts}}
      <SyfAccountConfirmation
        @accounts={{@model.person.activeAccounts}}
        @matchPlan={{@model.matchPlan}}
        @onConfirmation={{this.didConfirmEnrollment}}
        @onCancel={{this.didCancel}}
        @loading={{this.loading}}
        @errors={{this.errors}}
      />
    {{else if (not this.hasLinkedAccounts)}}
      <SyfAlternateFlowEnrollment
        @onConfirmation={{this.didConfirmEnrollment}}
        @matchPlan={{@model.matchPlan}}
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(SyfAccountConfirmationRouteComponent);
