import { template } from "@ember/template-compiler";
import { concat } from '@ember/helper';
import { get } from '@ember/object';
import { t } from 'ember-intl';
import tippy from 'ember-tippy/modifiers/tippy';
import { eq, or } from 'ember-truth-helpers';
import TioDownloadButton from 'tio-common/components/tio/download-button';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import Component from '@glimmer/component';
import type PslfFormModel from 'tio-common/models/pslf-form';
import { LinkTo } from '@ember/routing';
interface PslfAdminCompanyFormsTableRowSig {
    Args: {
        isLoading: boolean;
        model: PslfFormModel;
    };
}
export default class PslfAdminCompanyFormsTableRowComponent extends Component<PslfAdminCompanyFormsTableRowSig> {
    get attachment() {
        const attachments1 = this.args.model.attachments;
        if (!attachments1?.length) {
            return undefined;
        }
        return attachments1.find((attachment1)=>{
            return attachment1.tags.includes(`document_id:${this.args.model.documentId}`);
        });
    }
    get isPriorEmployerForm(): boolean {
        return this.args.model?.borrowerFormType === 'priorEmployer';
    }
    get isNonPartnerApproverForm(): boolean {
        return !!this.args.model?.borrowerNonPartnerForm;
    }
    get needsReview(): boolean {
        return (this.args.model?.status === 'borrower_signed' && !this.isPriorEmployerForm && !this.isNonPartnerApproverForm);
    }
    static{
        template(`
    {{#let "px-2 py-1 border border-gray-300" as |cellClass|}}
      <tr>
        <td class={{cellClass}}>{{@model.payrollId}}</td>
        <td class={{cellClass}}>{{get @model.borrowerFields "borrower-name"}}</td>
        {{#if (eq @model.status "borrower_signed")}}
          <td class="{{cellClass}} text-red-600 items-center font-semibold truncate underline">
            {{#if this.needsReview}}
              <LinkTo
                @route="authenticated.admin.pslf.form.review"
                @model={{@model.id}}
                {{! target="_blank"
                rel="noopener noreferrer" }}
                {{tippy (t "pslf.status.click_here_to_start") placement="top" delay="500"}}
              >
                {{t "pslf.status.needs_review"}}
              </LinkTo>
            {{/if}}
          </td>
        {{else}}
          <td class={{cellClass}}>{{t (concat "pslf.status." @model.status)}}</td>
        {{/if}}
        <td class={{cellClass}}>{{@model.approverDisplayEmail}}</td>
        {{#let @model.borrowerCompletedAt as |date|}}
          <td class={{cellClass}} title={{date}}>
            {{#if date}}
              {{safeFormatDate date month="short" day="2-digit" year="numeric"}}
            {{else}}
              {{t "pslf.table.pending"}}
            {{/if}}
          </td>
        {{/let}}
        {{#let @model.employerCompletedAt as |date|}}
          <td class={{cellClass}} title={{date}}>
            {{#if date}}
              {{safeFormatDate date month="short" day="2-digit" year="numeric"}}
            {{else if (or @model.isPrintAndMail @model.isPriorEmployerClosed)}}
              {{t "pslf.table.not_applicable"}}
            {{else if @model.isAwaitingSignature}}
              {{t "pslf.table.pending"}}
            {{/if}}
          </td>
        {{/let}}
        <td class={{cellClass}}>
          <div class="truncate">
            {{t (concat "pslf.form-type." @model.formType)}}
            <br />
            {{#if @model.isPriorEmployer}}
              -
              {{@model.priorEmployerName}}
            {{else}}
              {{@model.employerDisplayName}}
            {{/if}}
          </div>
        </td>
        <td class={{cellClass}}>
          {{#if this.attachment.signedUrl}}
            <TioDownloadButton
              @url={{this.attachment.signedUrl}}
              @filename={{@model.downloadFilename}}
              class="border-none bg-transparent"
            />
          {{else}}
            {{! template-lint-disable no-bare-strings }}
            No Download
          {{/if}}
        </td>
      </tr>
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
