import { template } from "@ember/template-compiler";
import { concat } from '@ember/helper';
import { eq } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import { t } from 'ember-intl';
import AppHeaderCurrentUserMenuHeader from 'tio-employee/components/app/header/current-user-menu-header';
import AppHeaderCurrentUserMenuItem from 'tio-employee/components/app/header/current-user-menu-item';
import AppHeaderInitialsIcon from 'tio-employee/components/app/header/initials-icon';
import Component from '@glimmer/component';
import cssStateTransition from 'tio-common/modifiers/css-state-transition';
import lowercase from 'ember-cli-string-helpers/helpers/lowercase';
// @ts-expect-error: get rid of this addon
import Menu from 'ember-headlessui/components/menu';
import screenIs from 'tio-common/helpers/screen-is';
import type RoleModel from 'tio-common/models/role-view';
import type UserModel from 'tio-common/models/user';
const stateColors = {
    disabled: '#999',
    connecting: '#000',
    connected: '#090',
    authenticated: 'currentColor',
    error: '#c66',
    unknown: '#d90'
};
interface AppHeaderCurrentUserMenuComponentSignature {
    Args: {
        user: UserModel;
        roles: RoleModel[];
        currentRole: RoleModel;
        onRoleChange: (role: RoleModel) => void;
        onLogout: () => void;
    };
}
/**
 * Renders the user menu within the header for the current user.
 *
 * @memberof components/app/header
 */ let AppHeaderCurrentUserMenuComponent = class AppHeaderCurrentUserMenuComponent extends Component<AppHeaderCurrentUserMenuComponentSignature> {
    /**
   * Returns the color used for the menu button. Currently used as a subtle signal for what the
   * current state of the vue integration is.
   */ get menuButtonColor() {
        // not planning to type as it is going away soon
        return stateColors.unknown;
    }
    get activeRoles() {
        return this.args.roles.filter((role1)=>role1.isActive);
    }
    static{
        template(`
    <Menu as |menu|>
      <menu.Button
        class="inline-flex justify-center items-center h-full w-full px-1 py-2 text-sm font-medium leading-5 text-gray-700 bg-white hover:text-gray-500"
      >
        {{#if (screenIs "md")}}
          <span class="mr-2">{{t "header.user_menu.title" name=@user.person.firstName}}</span>
        {{/if}}
        <AppHeaderInitialsIcon @initials={{@user.person.initials}} />
      </menu.Button>

      <div
        class="absolute right-0 origin-top-right z-100"
        {{cssStateTransition menu.isOpen "opacity:0%,100%" 300}}
      >
        <menu.Items
          class="w-56 bg-white border border-gray-200 rounded-sm shadow-lg outline-none z-100"
          as |items|
        >
          <AppHeaderCurrentUserMenuHeader @title={{t "header.user_menu.switch_account"}} />

          {{#each this.activeRoles as |role|}}
            <AppHeaderCurrentUserMenuItem
              @items={{items}}
              @title={{t (concat "user-roles." (lowercase role.relationshipType))}}
              @icon={{if
                (eq @currentRole.relationshipType role.relationshipType)
                "arrow_right"
                "\$blank"
              }}
              @onClick={{fn @onRoleChange role}}
            />
          {{/each}}

          <hr />

          <AppHeaderCurrentUserMenuItem
            @items={{items}}
            @icon="manage_accounts"
            @title={{t "header.user_menu.profile"}}
            @route="authenticated.profile"
          />

          <AppHeaderCurrentUserMenuItem
            @items={{items}}
            @icon="help"
            @title={{t "header.user_menu.help"}}
            @route="authenticated.contact-us"
          />

          <hr />

          <AppHeaderCurrentUserMenuItem
            @items={{items}}
            @icon="logout"
            @title={{t "header.user_menu.logout"}}
            @onClick={{@onLogout}}
          />
        </menu.Items>
      </div>
    </Menu>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default AppHeaderCurrentUserMenuComponent;
