import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import config from 'tio-employee/config/environment';
import { restartableTask, timeout } from 'ember-concurrency';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type TasProgramTemplateModel from 'tio-common/models/tas-program-template';
import TuitionAssistanceProgramFormField from 'tio-common/components/tuition-assistance/program-form-field';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import type TasUnifiedInstitutionModel from 'tio-common/models/tas-unified-institution';
const INPUT_DEBOUNCE = config.environment === 'test' ? 0 : 250;
type Filter = {
    'tas-program-template': string;
    search?: string;
};
export interface TuitionAssistanceFormsSchoolSelectSignature {
    Args: {
        programTemplate: TasProgramTemplateModel;
        selected?: unknown;
        onChange?: (value: TasUnifiedInstitutionModel) => void;
        placeholder?: string;
        label?: string;
        errors?: string[];
    };
    Element: HTMLDivElement;
}
/**
 *
 * <TuitionAssistance::Forms::SchoolSelect
 *  @programTemplate={{this.tasProgramTemplate}}
 *  @selected={{this.selectedModel}}
 *  @onChange={{this.didSelectSchool}}
 *  @placeholder=""
 *  @label=""
 * />
 *
 */ export default class TuitionAssistanceFormsSchoolSelectComponent extends Component<TuitionAssistanceFormsSchoolSelectSignature> {
    @service
    store: StoreService;
    constructor(owner1: unknown, args1: TuitionAssistanceFormsSchoolSelectSignature['Args']){
        super(owner1, args1);
        this.fetchRecords.perform();
    }
    fetchRecords = restartableTask(async (string1?: string)=>{
        await timeout(INPUT_DEBOUNCE);
        const filter1: Filter = {
            'tas-program-template': this.args.programTemplate.id
        };
        if (string1) {
            filter1.search = string1;
        }
        const results1 = await this.store.query('tas-unified-institution', {
            filter: filter1
        });
        return results1;
    });
    static{
        template(`
    <TuitionAssistanceProgramFormField
      @name="SCHOOL_INSTITUTION_NAME"
      @programTemplate={{@programTemplate}}
      ...attributes
      as |field|
    >
      <FormSelect
        data-legacy-input
        id={{field.inputId}}
        {{! @glint-expect-error frontile issue}}
        @search={{this.fetchRecords.perform}}
        @searchEnabled={{true}}
        @allowClear={{true}}
        @selected={{@selected}}
        {{! @glint-expect-error frontile issue}}
        @onChange={{@onChange}}
        @errors={{@errors}}
        as |school|
      >
        {{! @glint-expect-error frontile issue}}
        {{school.locationName}}
      </FormSelect>
    </TuitionAssistanceProgramFormField>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
