import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import AccountLinkingSlr from 'tio-employee/components/account-linking/slr';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type FinancialInstitutionModel from 'tio-common/models/financial-institution';
import type RouterService from 'tio-employee/services/router';
import AppPageContent from 'tio-employee/components/app/page/content';
import { t } from 'ember-intl';
import TioPageHeader from 'tio-common/components/tio/page/header';
let AccountActivityLinkingRoute = class AccountActivityLinkingRoute extends Component {
    @service
    router: RouterService;
    @action
    didLink(institution1: FinancialInstitutionModel) {
        this.router.transitionTo('authenticated.slr.linking-confirmation', institution1.legacyId);
    }
    @action
    didCancel() {
        this.router.transitionTo('authenticated.dashboard');
    }
    static{
        template(`
    <TioPageHeader as |header|>
      <header.title>{{t "account_linking.add_account"}}</header.title>
    </TioPageHeader>
    <AppPageContent>
      <AccountLinkingSlr @onSuccess={{this.didLink}} @onCanceled={{this.didCancel}} />
    </AppPageContent>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AccountActivityLinkingRoute);
