import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import Button from 'tio-common/components/tio/button';
import TioDialog from 'tio-common/components/tio/dialog';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface AuthRegisterEnrollConfirmNameModalSignature {
    Args: {
        show: boolean;
        onClose: () => void;
        onSubmit: () => void;
        employeeFullName: string;
        inputName: string;
    };
}
const AuthRegisterEnrollConfirmNameModal: TOC<AuthRegisterEnrollConfirmNameModalSignature> = template(`
    <TioDialog
      @show={{@show}}
      @onCancel={{@onClose}}
      @containerClass="rounded-md flex flex-col border border-gray-300 lg:w-2/5 md:w-3/5 sm:w-4/5"
      @actionsClass="text-center !px-0 mt-auto"
      @headerClass="px-6 py-4"
    >
      <:header>
        <span class="text-xl text-midnight font-semibold">
          {{t "enroll.confirm_name.header" name=@employeeFullName}}
        </span>
      </:header>
      <:body>
        {{t "enroll.confirm_name.body" inputName=@inputName employeeFullName=@employeeFullName}}
      </:body>
      <:actions>
        <div class="flex justify-center space-x-2 w-full mt-4">
          <Button @outlined={{true}} class="sm:min-w-48 sm:w-fit" @onClick={{@onClose}}>
            {{t "enroll.confirm_name.yes_im" name=@employeeFullName}}
          </Button>
          <Button class="sm:min-w-48 sm:w-fit" @onClick={{@onSubmit}}>
            {{t "enroll.confirm_name.register_as" name=@inputName}}
          </Button>
        </div>
      </:actions>
    </TioDialog>
  `, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AuthRegisterEnrollConfirmNameModal;
