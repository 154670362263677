import { template } from "@ember/template-compiler";
import type Store from '@ember-data/store';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { registerDestructor } from '@ember/destroyable';
import { concat } from '@ember/helper';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import { trackedFunction } from 'reactiveweb/function';
import RouteTemplate from 'ember-route-template';
import { eq, not, or } from 'ember-truth-helpers';
import EligibilityFilesDownloadEligibilityFile from 'tio-common/components/eligibility-files/download-eligibility-file';
import MaterialIcon from 'tio-common/components/material-icon';
import TioButton from 'tio-common/components/tio/button';
import TioLoadingSpinner from 'tio-common/components/tio/loading-spinner';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TioPageHeaderTitle from 'tio-common/components/tio/page/header/title';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import safeFormatTime from 'tio-common/helpers/safe-format-time';
import type EligibilityFileJobLogModel from 'tio-common/models/eligibility-file-job-log';
import type EligibilityUploadService from 'tio-common/services/eligibility-upload';
import AppPage from 'tio-employee/components/app/page';
interface DetailsBoxSignature {
    Args: {
        title: string;
        preview: number;
        processed: number;
    };
}
const DetailsBox: TOC<DetailsBoxSignature> = template(`
  <div class="rounded-md text-center my-2 border text-lg">
    <div class="py-4 font-semibold text-purple-900">
      {{@title}}
    </div>
    <dl>
      <div class="grid grid-cols-2 p-4">
        <dt class="text-left">{{t "common.eligibility_file_upload.preview"}}</dt>
        <dd>{{@preview}}</dd>
        <dt class="text-left">{{t "common.eligibility_file_upload.processed"}}</dt>
        <dd>{{@processed}}</dd>
      </div>
    </dl>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface AuthenticatedAdminEligibilityFilesShowRouteSignature {
    Args: {
        model: EligibilityFileJobLogModel;
    };
}
let AuthenticatedAdminEligibilityFilesShowRouteComponent = class AuthenticatedAdminEligibilityFilesShowRouteComponent extends Component<AuthenticatedAdminEligibilityFilesShowRouteSignature> {
    @service
    store: typeof Store;
    @service
    router: RouterService;
    @service
    eligibilityUpload: EligibilityUploadService;
    @tracked
    isProcessing = false;
    @tracked
    isAborting = false;
    @tracked
    canProcess = true;
    @tracked
    canAbort = true;
    @tracked
    buttonEnabled = true;
    constructor(owner1: unknown, args1: AuthenticatedAdminEligibilityFilesShowRouteSignature['Args']){
        super(owner1, args1);
        registerDestructor(this, ()=>this.args.model.stopPollingForStatus());
        this.beginPolling();
    }
    get processEnabled() {
        return (this.args.model.canProcess && this.canProcess === true && this.args.model.status !== 'REQUIRES_REVIEWER_APPROVAL' && this.buttonEnabled === true);
    }
    get abortEnabled() {
        return (this.args.model.canAbort && this.canAbort) === true && this.buttonEnabled === true;
    }
    async beginPolling() {
        await this.eligibilityUpload.uploadEligibilityFile();
        this.args.model.startPollingForStatus();
    }
    uploadedByData = trackedFunction(this, async ()=>{
        const { createdBy: createdBy1 } = this.args.model;
        const user1 = await this.store.findRecord('user', createdBy1, {
            include: 'person'
        });
        return user1.person.firstName + ' ' + user1.person.lastName;
    });
    get uploaderName() {
        return this.uploadedByData.value || '';
    }
    @action
    async processEligibilityFile() {
        const { legacyId: legacyId1, id: id1 } = this.args.model.company;
        const { processId: processId1 } = this.args.model;
        this.buttonEnabled = false;
        this.canProcess = false;
        this.isProcessing = true;
        await this.store.adapterFor('eligibility-file-job-log').processJob(legacyId1, id1, processId1);
        this.args.model.reload();
        this.isProcessing = false;
        this.buttonEnabled = true;
        this.canProcess = true;
    }
    @action
    async abortEligibilityFile() {
        const { legacyId: legacyId1, id: id1 } = this.args.model.company;
        const { processId: processId1 } = this.args.model;
        this.buttonEnabled = false;
        this.canAbort = false;
        this.isAborting = true;
        await this.store.adapterFor('eligibility-file-job-log').abortJob(legacyId1, id1, processId1);
        this.args.model.reload();
        this.isAborting = false;
        this.buttonEnabled = true;
        this.canAbort = true;
    }
    static{
        template(`
    <AppPage>
      <TioPageHeaderTitle>
        <div class="inline-block ml-4 mt-4">
          <h1 class="text-2xl inline-block">
            <span class="text-gray-800">{{t "eligibility_files.default"}}</span>
          </h1>
        </div>
      </TioPageHeaderTitle>

      <TioPageBreadcrumbs class="mb-4" as |b|>
        <b.crumb
          class="!relative !inline-block ml-4"
          @route="authenticated.admin.eligibility-files.index"
          @label="Eligibility Files"
        />
        <span class="!relative !inline-block">
          {{@model.fileName}}
        </span>
      </TioPageBreadcrumbs>
      <div class="grid grid-cols-5 xs:grid-cols-1 ml-4 mt-8 gap-4">
        <DetailsBox
          @title={{t "eligibility_files.unchanged_employees"}}
          @preview={{@model.previewCounts.unchanged}}
          @processed={{@model.counts.unchanged}}
        />
        <DetailsBox
          @title={{t "eligibility_files.new_employees"}}
          @preview={{@model.previewCounts.created}}
          @processed={{@model.counts.created}}
        />
        <DetailsBox
          @title={{t "eligibility_files.changed_employees"}}
          @preview={{@model.previewCounts.updated}}
          @processed={{@model.counts.updated}}
        />
        <DetailsBox
          @title={{t "eligibility_files.deactivated_employees"}}
          @preview={{@model.previewCounts.deactivated}}
          @processed={{@model.counts.deactivated}}
        />
        <DetailsBox
          @title={{t "eligibility_files.reactivated_employees"}}
          @preview={{@model.previewCounts.reactivated}}
          @processed={{@model.counts.reactivated}}
        />
      </div>

      <div class="grid grid-cols-10 ml-4 mt-4">
        <TioButton
          @onClick={{this.processEligibilityFile}}
          @disabled={{or (not this.processEnabled) this.isProcessing}}
          class="col-span-2 mt-4 mx-4"
        >
          {{t "eligibility_files.process_eligibility_file"}}
        </TioButton>
        <TioButton
          @onClick={{this.abortEligibilityFile}}
          @disabled={{or (not this.abortEnabled) this.isAborting}}
          class="col-span-2 mt-4 mx-4"
        >
          {{t "eligibility_files.abort"}}
        </TioButton>
        <EligibilityFilesDownloadEligibilityFile
          @processId={{@model.processId}}
          @companyId={{@model.company.id}}
          @legacyId={{@model.company.legacyId}}
          @fileName={{@model.fileName}}
          @format="text-button"
          class="col-span-2 mt-4 mx-4"
          @title=""
        />
      </div>
      <div class="grid grid-cols-10 ml-4 mt-16">
        <div class="border border-gray-200 col-span-4 text-sm">
          <div class="p-4">
            <div class="font-semibold">
              {{t "eligibility_files.process_details"}}
            </div>
            <div class="grid grid-cols-4">
              <div class="col-span-2">
                <h2 class="text-sm inline-block">
                  <div>
                    {{t "eligibility_files.status"}}
                    <span class="text-purple-900 font-bold">
                      {{t (concat "common.eligibility_file_upload.status." @model.status)}}
                    </span>
                    {{#if @model.isUploading}}
                      <div class="mr-12 mt-4">
                        <MaterialIcon
                          @icon="info"
                          class="text-md font-semibold align-bottom cursor-default text-gray-400"
                        />
                        <span
                          class="text-sm font-semibold align-bottom cursor-default text-ocean-600"
                        >
                          {{t "eligibility_files.upload_warning"}}
                        </span>
                      </div>
                    {{/if}}
                    <br />
                    {{t "eligibility_files.total_processed"}}
                    <span class="text-purple-900 font-bold">{{@model.totalProcessed}}
                    </span>
                    {{#if @model.isUploading}}
                      <TioLoadingSpinner />
                    {{/if}}
                  </div>
                </h2>
              </div>
              <div class="col-span-4 grid grid-cols-2">
                <span class="col-span-1">{{t "eligibility_files.uploaded_by"}}</span>
                <span class="col-span-1 float-right font-semibold">{{this.uploaderName}}</span>
                <span class="col-span-1">{{t "eligibility_files.file_name"}}</span>
                <span class="col-span-1 float-right font-semibold">
                  {{@model.fileName}}
                </span>
                <span class="col-span-1">{{t "eligibility_files.upload_type"}}</span>
                <span class="col-span-1 float-right font-semibold">
                  {{#if (eq @model.strategy "MANUAL")}}
                    {{t "eligibility_files.manual"}}
                  {{else if (eq @model.strategy "MANUAL_FORCE_UPDATE")}}
                    {{t "eligibility_files.forced"}}
                  {{else}}
                    {{t "eligibility_files.automatic"}}
                  {{/if}}
                </span>
                <span class="col-span-1">{{t "eligibility_files.start_time"}}</span>
                <span class="col-span-1 float-right font-semibold">
                  {{safeFormatDate @model.startTime}}
                  {{safeFormatTime @model.startTime}}
                </span>
                {{#if @model.abortedAt}}
                  <span class="col-span-1">{{t "eligibility_files.time_aborted"}}</span>
                  <span class="col-span-1 float-right font-semibold">{{@model.abortedAt}}</span>
                {{/if}}
                {{#if @model.endTime}}
                  <span class="col-span-1">{{t "eligibility_files.time_completed"}}</span>
                  <span class="col-span-1 float-right font-semibold">
                    {{safeFormatDate @model.endTime}}
                    {{safeFormatTime @model.endTime}}
                  </span>
                {{/if}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 p-4 pb-20">
        <h3 class="text-sm mt-4 mb-4 font-semibold">{{t "eligibility_files.warning.default"}}</h3>
        <table class="w-full table-auto border-collapse text-left">
          <thead>
            <tr class="font-semibold bg-ocean-50">
              <th>{{t "eligibility_files.warning.type"}}</th>
              <th>{{t "eligibility_files.warning.field"}}</th>
              <th>{{t "eligibility_files.warning.description"}}</th>
            </tr>
          </thead>
          <tbody>
            {{#let "px-2 py-2 border" as |cellClass|}}
              {{#each @model.jobErrors as |warning|}}
                <tr class="even:bg-ocean-50">
                  <td class={{cellClass}}>{{warning.key}}</td>
                  <td class={{cellClass}}>{{warning.field}}</td>
                  <td class={{cellClass}}>{{warning.message}}</td>
                </tr>
              {{/each}}
            {{/let}}
          </tbody>
        </table>
      </div>
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AuthenticatedAdminEligibilityFilesShowRouteComponent);
