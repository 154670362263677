import Route from '@ember/routing/route';

export default class PslfEmployerCertificationRoute extends Route {
  model(params: { form_document_id: string; initialValues: Record<string, string> }) {
    const { form_document_id } = params;
    return {
      documentId: form_document_id,
      initialValues: params.initialValues,
    };
  }
}
