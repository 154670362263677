import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
const AuthRegisterLoginHereComponent = template(`
  <div data-test-login-here class="mb-6 text-md text-center">
    {{t "login.already_have_account"}}
    <a class="underline text-ocean-600" href="/login">
      {{t "login.log_in_here"}}
    </a>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AuthRegisterLoginHereComponent;
