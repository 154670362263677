import { template } from "@ember/template-compiler";
import { Input } from '@ember/component';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import { not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import TioButton from 'tio-common/components/tio/button';
export interface AccountLinkingTermsSignature {
    Args: {
        onAccepted: () => void;
    };
}
let AccountLinkingTermsComponent = class AccountLinkingTermsComponent extends Component<AccountLinkingTermsSignature> {
    @tracked
    accepted = false;
    static{
        template(`
    {{! template-lint-disable no-bare-strings }}
    <div class="text-center">
      <h1 class="text-xl">Your employer’s loan eligibility rules</h1>
      Your employer's student loan repayment assistance benefit stipulates the following rules for
      which loans are eligible to receive their student loan repayment benefit. It may be necessary
      for Tuition.io to contact you to get additional information specific to the loans you link.
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-1">
      <div>
        <div class="text-center mx-12">
          <MaterialIcon @icon="check_circle" class="text-green-500 text-5xl" />
          <h2 class="text-2xl">Eligible loans</h2>
        </div>
        You are allowed to receive contributions to the following student loans.
        <ol class="list-disc">
          <li>Loans must be in your name and be used for your education</li>
          <li>Loans must be in good standing</li>
        </ol>
      </div>
      <div>
        {{! <div class="text-center mx-12">
          <MaterialIcon @icon="cancel" class="text-red-500 text-5xl" />
          <h2 class="text-2xl">Ineligible loans</h2>
        </div>
        You are NOT allowed to receive contributions to the following student loans.
        <ol class="list-disc">
          <li>Parent PLUS loans</li>
          <li>Loans that are delinquent</li>
          <li>Loans consolidated with someone else</li>
          <li>Loans used to pay for someone else's higher education</li>
        </ol> }}
      </div>
    </div>
    <Input id="terms-checkbox" @type="checkbox" @checked={{this.accepted}} />
    <label for="terms-checkbox">I acknowledge and agree to these terms.</label>
    <hr class="my-4" />
    <TioButton @onClick={{@onAccepted}} @disabled={{not this.accepted}}>
      {{t "account_linking.accept_terms"}}
    </TioButton>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default AccountLinkingTermsComponent;
