import Route from '@ember/routing/route';

type NonPartnerEmployerFormInfo = {
  'approver-email': string;
  'company-name': string;
  'company-website': string;
  'borrower-form-type': string;
  'borrower-name': string;
  'borrower-sign-date': string;
  'employer-sign-date': string | undefined;
  'last-four'?: string;
  status: string;
};

export default class PslfEmployerNonPartnerCertificationRoute extends Route {
  model(params: { form_document_id: string; initialValues: NonPartnerEmployerFormInfo }) {
    const { form_document_id } = params;
    return {
      documentId: form_document_id,
      initialValues: params.initialValues,
    };
  }
}
