import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { and, or } from 'ember-truth-helpers';
import { htmlSafe } from '@ember/template';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { ValidationErrorKey, type PastContributionPayment } from 'tio-employee/services/employee';
import AccountDialog from 'tio-employee/components/slr/account-dialog';
import AppPageContent from 'tio-employee/components/app/page/content';
import Button from 'tio-common/components/tio/button';
import Component from '@glimmer/component';
import DefinitionList from 'tio-common/components/ui/definition-list';
import MaterialIcon from 'tio-common/components/material-icon';
import RouteTemplate from 'ember-route-template';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import SlrError from 'tio-employee/components/slr/error';
import StatusText from 'tio-employee/components/slr/status-text';
import SupportEmailModal from 'tio-employee/components/slr/support-email-modal';
import Table from 'tio-employee/components/slr/table';
import TioClickableText from 'tio-common/components/tio/clickable-text';
import TioPageHeader from 'tio-common/components/tio/page/header';
import type AccountModel from 'tio-common/models/account';
import type EmployeeService from 'tio-employee/services/employee';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type UserModel from 'tio-common/models/user';
interface SlrDashboardSignature {
    Args: {
        model: UserModel;
    };
}
let SlrDashboardRouteComponent = class SlrDashboardRouteComponent extends Component<SlrDashboardSignature> {
    @service
    employee: EmployeeService;
    @service
    store: typeof Store;
    @service
    intl: IntlService;
    @tracked
    isOpenPlanDialog = false;
    @tracked
    isOpenAccountDialog = false;
    @tracked
    isOpenSupportEmailModal = false;
    // button element for positioning dialog
    @tracked
    btnTriggerElement: HTMLElement | null = null;
    get user() {
        return this.args.model;
    }
    get pastContributionsPayments() {
        return this.employee.contributionPayments;
    }
    get nextContributionPayment() {
        return (this.employee.futureContributionPayments.find((payment1)=>!payment1.validation_errors.length) || this.employee.futureContributionPayments[0]);
    }
    get sortedContributions(): PastContributionPayment[] {
        return this.pastContributionsPayments.sort((a1, b1)=>new Date(b1.line_item_date).getTime() - new Date(a1.line_item_date).getTime());
    }
    get lastPaymentAmount() {
        return (this.sortedContributions[0]?.amount || 0) / 100;
    }
    get lastPaymentDate() {
        return (this.sortedContributions[0]?.transaction_date || this.sortedContributions[0]?.line_item_period || '');
    }
    get lastPaymentStatus() {
        if (this.sortedContributions[0]?.transaction_date) {
            return this.intl.t('slr.payment_sent');
        } else if (this.sortedContributions[0]?.line_item_date) {
            return this.intl.t('slr.in_progress');
        } else return this.intl.t('slr.pending');
    }
    get nextPaymentAmount() {
        return (this.nextContributionPayment?.amount || 0) / 100;
    }
    get nextPaymentDate() {
        return this.nextContributionPayment?.date || '';
    }
    get nextPaymentStatus() {
        const status1 = this.nextContributionPayment?.state || '';
        if (status1 === 'PENDING_APPROVAL') {
            return this.intl.t('slr.pending');
        }
        return status1.replace(/_/g, ' ').toLowerCase();
    }
    get totalPayments() {
        return this.employee.contributionSummary?.total / 100 || 0;
    }
    get paymentsCount() {
        return this.employee.contributionPayments?.length || 0;
    }
    get totalAnnualProjection() {
        return this.employee.annualPreviewData?.annual_projection || 0;
    }
    get yearlyTotal() {
        return this.employee.annualPreviewData?.paid_amount || 0;
    }
    get yearlyProjection() {
        return this.employee.annualPreviewData?.projected_amount || 0;
    }
    get isContributionsLoading() {
        return this.employee.isLoadingContributions;
    }
    get hasContributions() {
        return this.employee.hasContributions;
    }
    get accountWithContribution(): AccountModel | null {
        if (this.hasContributions) {
            return (this.user.activeAccounts.sort((a1, b1)=>a1.publicOrdinalValue - b1.publicOrdinalValue)[0] || null);
        } else {
            return null;
        }
    }
    get planBtnBg() {
        return this.isOpenPlanDialog ? 'bg-gray-200 ' : '';
    }
    get validationError() {
        return this.nextContributionPayment?.validation_errors[0] || null;
    }
    get formattedValidationError() {
        if (this.validationError) {
            const { key: key1, message: message1 } = this.validationError;
            switch(key1){
                case ValidationErrorKey.ACCOUNTS_UNPAYABLE:
                    if (message1.includes('address') || message1.includes('payment method not set')) {
                        return {
                            content: this.intl.t('slr.error.no_address'),
                            emailModal: true
                        };
                    } else return {
                        content: this.intl.t('slr.error.accounts_unpayable'),
                        linkAccount: true
                    };
                case ValidationErrorKey.NO_LOAN_BALANCE:
                case ValidationErrorKey.INVALID_LOAN_TYPE:
                case ValidationErrorKey.STALE_ACCOUNT:
                    return {
                        content: this.intl.t('slr.error.accounts_unpayable'),
                        linkAccount: true
                    };
                case ValidationErrorKey.EXCEEDED_LIMIT:
                    return {
                        content: this.intl.t('slr.error.exceeded_limit')
                    };
                case ValidationErrorKey.NO_EMPLOYEE_TRANSACTIONS:
                    return {
                        content: this.intl.t('slr.error.no_transactions'),
                        emailModal: true
                    };
                case ValidationErrorKey.TERMS_NOT_ACCEPTED:
                    return {
                        content: this.intl.t('slr.error.terms_not_accepted')
                    };
                default:
                    return {
                        content: this.intl.t('slr.error.default')
                    };
            }
        } else return null;
    }
    @action
    onPlanDetailsClick(event1: MouseEvent) {
        this.btnTriggerElement = event1.target as HTMLElement;
        this.isOpenPlanDialog = !this.isOpenPlanDialog;
    }
    @action
    onAccountClick(event1?: MouseEvent) {
        if (!event1) return;
        this.btnTriggerElement = event1.target as HTMLElement;
        this.isOpenAccountDialog = !this.isOpenAccountDialog;
    }
    @action
    onClosePlanDialog() {
        this.isOpenPlanDialog = false;
    }
    @action
    onCloseAccountDialog() {
        this.isOpenAccountDialog = false;
    }
    @action
    onSupportEmailModalClick() {
        this.isOpenSupportEmailModal = true;
    }
    @action
    onCloseSupportEmailModal() {
        this.isOpenSupportEmailModal = false;
    }
    static{
        template(`
    <AppPageContent>
      <TioPageHeader as |header|>
        <header.title>{{t "slr.slr_page_header"}}
          {{#if (or this.formattedValidationError this.accountWithContribution)}}
            <div class="inline-flex items-center text-black text-sm font-normal">
              <MaterialIcon
                @icon="circle"
                class="text-sm mx-1
                  {{if this.formattedValidationError 'text-red-500' 'text-green-500'}}"
              /><span>{{t
                  (if
                    this.formattedValidationError "slr.issue_present" "slr.receiving_contributions"
                  )
                }}</span>
            </div>
          {{/if}}
        </header.title>
      </TioPageHeader>
      <div class="flex flex-col space-y-4">
        <div class="w-full grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 items-center">
          <h2 class="text-lg font-medium">{{t "slr.employer_contributions.title"}}</h2>
          {{!-- <button
          type="button"
          {{on "click" this.onPlanDetailsClick}}
          class={{concat
            this.planBtnBg
            " text-gray-500 border border-gray-500 rounded-md px-4 py-2 flex flex-row items-center space-x-2 hover:bg-gray-200 sm:ml-0 md:ml-auto lg:ml-auto w-fit"
          }}
        >
          <MaterialIcon @icon="description" @style="outlined" class="text-lg text-gray-500" />
          <span class="font-medium">{{t "slr.details_btn"}}</span>
        </button> --}}
          {{!-- <PlanDetailsDialog
            @show={{this.isOpenPlanDialog}}
            @onClose={{this.onClosePlanDialog}}
            @triggerElement={{this.btnTriggerElement}}
          /> --}}
        </div>
        {{#if this.accountWithContribution}}
          <div class="flex items-center space-x-2">
            <h3 class="text-lg font-medium truncate">{{t
                "slr.account_receiving_contributions"
              }}</h3>
            <span
              class="pr-2 border-r border-r-gray-500 truncate"
            >{{this.accountWithContribution.institutionName}}</span>
            <TioClickableText
              @onClick={{this.onAccountClick}}
              class="text-ocean-600 hover:text-ocean-800"
            >{{t "slr.change"}}</TioClickableText>
            <AccountDialog
              @show={{this.isOpenAccountDialog}}
              @onClose={{this.onCloseAccountDialog}}
              @accountWithContribution={{this.accountWithContribution}}
              @activeAccounts={{this.user.activeAccounts}}
              @triggerElement={{this.btnTriggerElement}}
            />
          </div>
        {{/if}}
        {{#if this.formattedValidationError}}
          <SlrError as |options|>
            <options.header>{{t "slr.error.needs_attention"}}</options.header>
            <options.body>{{htmlSafe this.formattedValidationError.content}}</options.body>
            {{#if this.formattedValidationError.emailModal}}
              <options.footer>
                <Button
                  @onClick={{this.onSupportEmailModalClick}}
                  @outlined={{true}}
                  class="!bg-gray-100"
                >{{t "slr.attach_statement"}}</Button>
              </options.footer>
            {{else if this.formattedValidationError.linkAccount}}
              <options.footer>
                <Button
                  @linkTo="authenticated.slr.linking"
                  @outlined={{true}}
                  class="!bg-gray-100"
                >{{t "slr.link_account"}}</Button>
              </options.footer>
            {{/if}}
          </SlrError>
          {{#if this.validationError}}
            <SupportEmailModal
              @show={{this.isOpenSupportEmailModal}}
              @onClose={{this.onCloseSupportEmailModal}}
              @error={{this.validationError}}
            />
          {{/if}}
        {{/if}}
        <div class="grid grid-cols-2 sm:grid-cols-4 sm:gap-4 md:gap-12 lg:w-4/5">
          {{#if this.hasContributions}}
            <DefinitionList class="divide-none">
              <:default as |options|>
                <options.dt class="!text-black">
                  {{t "slr.employer_contributions.last"}}
                  <span class="bg-gray-100 py-0.5 px-3 ml-2 rounded">{{safeFormatDate
                      this.lastPaymentDate
                      month="short"
                      year="numeric"
                    }}</span>
                </options.dt>
                <options.dd class="*:text-2xl *:text-purple-900 decoration-purple-900">
                  {{safeFormatNumber this.lastPaymentAmount style="currency" currency="USD"}}
                </options.dd>
                <options.dd>
                  <StatusText
                    @status={{this.lastPaymentStatus}}
                    @lastPaymentDate={{this.lastPaymentDate}}
                  />
                </options.dd>
              </:default>
            </DefinitionList>
          {{/if}}
          {{#if (and this.nextPaymentDate this.nextPaymentAmount)}}
            <DefinitionList class="divide-none">
              <:default as |options|>
                <options.dt class="!text-black">
                  {{t "slr.employer_contributions.next"}}
                  <span class="bg-gray-100 py-0.5 px-3 ml-2 rounded">{{safeFormatDate
                      this.nextPaymentDate
                      month="short"
                      year="numeric"
                    }}</span>
                </options.dt>
                <options.dd class="*:text-2xl *:text-ocean-400 decoration-ocean-400">
                  {{safeFormatNumber this.nextPaymentAmount style="currency" currency="USD"}}
                </options.dd>
                <options.dd>
                  <StatusText @status={{this.nextPaymentStatus}} />
                </options.dd>
              </:default>
            </DefinitionList>
          {{/if}}
          {{#if this.paymentsCount}}
            <DefinitionList class="divide-none">
              <:default as |options|>
                <options.dt class="!text-black">
                  {{t "slr.total"}}
                </options.dt>
                <options.dd class="*:text-2xl *:text-midnight decoration-midnight">
                  {{safeFormatNumber this.totalPayments style="currency" currency="USD"}}
                </options.dd>
                <options.dd>
                  {{#let (safeFormatNumber this.paymentsCount) as |total|}}
                    {{t "slr.employer_contributions.total_contributions" total=total}}
                  {{/let}}
                </options.dd>
              </:default>
            </DefinitionList>
          {{/if}}
          {{!-- <DefinitionList class="divide-none">
            <:default as |options|>
              <options.dt class="!text-black">
                {{t "slr.employer_contributions.annual_projection"}}
              </options.dt>
              <options.dd class="*:text-2xl *:text-violet-300 decoration-violet-300">
                {{safeFormatNumber this.totalAnnualProjection style="currency" currency="USD"}}
              </options.dd>
              <options.dd class="underline">
                YTD + Projected
                <Tippy @placement="bottom" @onHidden={{@onHide}} @onShow={{@onShow}}>

                  {{safeFormatNumber this.yearlyTotal style="currency" currency="USD"}}
                  +
                  {{safeFormatNumber this.yearlyProjection style="currency" currency="USD"}}
                </Tippy>
              </options.dd>
            </:default>
          </DefinitionList> --}}
        </div>
        {{!-- <StudentLoanRepaymentChart
        @pastContributions={{this.pastContributionsPayments}}
        @futureContributions={{this.futureContributionsPayments}}
        @isLoading={{this.isContributionsLoading}}
      /> --}}
        <Table @contributionPayments={{this.sortedContributions}} />
      </div>
    </AppPageContent>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(SlrDashboardRouteComponent);
