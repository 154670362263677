import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import Table from 'tio-common/components/table/index';
import TioDialog from 'tio-common/components/tio/dialog';
import FormInput from '@frontile/forms-legacy/components/form-input';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import TioButton from 'tio-common/components/tio/button';
import type { DependentRelationship } from '../forms/add-dependents';
import type StoreService from 'tio-common/services/store';
import type DependentModel from 'tio-common/models/dependent';
import type EmployeeModel from 'tio-common/models/employee';
export interface DependentTableSignature {
    Args: {
        employee: EmployeeModel;
    };
}
const tableRowCellClass = 'px-2 py-4 text-lg border-b border-gray-300 text-tio-gray-600';
const relationships = [
    {
        type: 'DependentRelationship.CHILD',
        name: 'Child'
    },
    {
        type: 'DependentRelationship.SPOUSE',
        name: 'Spouse'
    },
    {
        type: 'DependentRelationship.PARTNER',
        name: 'Partner'
    }
];
const ageFromBirthDate = (date1: string)=>{
    return Math.floor((new Date().valueOf() - new Date(date1).getTime().valueOf()) / 3.15576e10);
};
const getDependentRelationshipName = (dependent1: DependentModel)=>{
    return relationships.find((relationship1)=>relationship1.type === dependent1.relationship)!.name;
};
export default class TuitionAssistanceDependentTable extends Component<DependentTableSignature> {
    @service
    store: StoreService;
    @tracked
    showEditDependentDialog = false;
    @tracked
    relationshipToEdit?: DependentRelationship;
    @tracked
    dependentToEdit?: DependentModel;
    @tracked
    isConfirming = false;
    @action
    toggleEditDependentDialog(dependent1: DependentModel) {
        this.dependentToEdit = dependent1;
        this.relationshipToEdit = relationships.find((relationship1)=>dependent1.relationship === relationship1.type);
        this.showEditDependentDialog = !this.showEditDependentDialog;
    }
    @action
    async submit() {
        this.isConfirming = true;
        let dependentToUpdate1 = await this.store.findRecord('dependent', this.dependentToEdit?.id);
        dependentToUpdate1.firstName = this.dependentToEdit!.firstName;
        dependentToUpdate1.lastName = this.dependentToEdit!.lastName;
        dependentToUpdate1.age = this.dependentToEdit!.age;
        dependentToUpdate1.birthDate = this.dependentToEdit!.birthDate;
        dependentToUpdate1.relationship = this.relationshipToEdit!.type;
        await dependentToUpdate1.save();
        this.isConfirming = false;
        this.showEditDependentDialog = false;
    }
    @action
    async cancel() {
        await this.dependentToEdit!.rollbackAttributes();
        this.showEditDependentDialog = !this.showEditDependentDialog;
    }
    @action
    async deleteDependentToEdit() {
        let dependnet1 = this.store.peekRecord('dependent', this.dependentToEdit?.id);
        await dependnet1.destroyRecord();
        this.showEditDependentDialog = false;
    }
    static{
        template(`
    <Table @isLoading={{false}}>
      <:thead as |options|>
        <options.tr class="font-semibold text-left py-4 bg-ocean-50 px-2 py-1">
          <options.th>{{t "common.tuition_assistance.dependent_table.header_name"}}</options.th>
          <options.th>{{t
              "common.tuition_assistance.dependent_table.header_relationship"
            }}</options.th>
          <options.th>{{t "common.tuition_assistance.dependent_table.header_age"}}</options.th>
        </options.tr>
      </:thead>
      <:tbody as |options|>
        {{#each @employee.dependents as |dependent|}}
          <options.tr>
            <options.td
              class={{tableRowCellClass}}
              data-test-dependent-table-full-name
            >{{dependent.fullName}}</options.td>
            <options.td class={{tableRowCellClass}} data-test-dependent-table-relationship>
              {{getDependentRelationshipName dependent}}
            </options.td>
            <options.td class="{{tableRowCellClass}} traicking-wide" data-test-dependent-table-age>
              <span class="flex w-full justify-between mr-4">
                {{ageFromBirthDate dependent.birthDate}}
                <button
                  type="submit"
                  class="text-tio-gray-400 hover:text-tio-gray-600 cursor-pointer"
                  {{on "click" (fn this.toggleEditDependentDialog dependent)}}
                  data-test-edit-dependent-button
                >
                  {{svgJar "edit-pencil"}}
                </button>
              </span>
            </options.td>
          </options.tr>
        {{/each}}
      </:tbody>
    </Table>
    <TioDialog
      @show={{this.showEditDependentDialog}}
      @onConfirm={{this.submit}}
      @onCancel={{this.cancel}}
      @headerClass="border-b"
      @containerClass="max-w-[600px]"
      @actionsClass="m-auto"
      @submitButtonText={{t "common.tuition_assistance.edit_dependent_modal.submit_button"}}
      @isConfirming={{this.isConfirming}}
    >
      <:header>
        <h1 class="font-semibold text-lg text-violet-800" data-test-edit-dependent-dialog>
          {{t "common.tuition_assistance.edit_dependent_modal.header"}}
        </h1>
      </:header>
      <:body>
        <form class="bg-white md:col-span-2 pb-8">
          <div class="flex flex-col max-w-2xl">
            <div class="flex mb-6">
              <div class="mt-2 mr-2">
                <FormInput
                  data-legacy-input
                  @label={{t "common.tuition_assistance.add_dependent_modal.first_name"}}
                  @type="text"
                  @value={{this.dependentToEdit.firstName}}
                  @onChange={{fn (mut this.dependentToEdit.firstName)}}
                  data-test-edit-dependent-first-name
                />
              </div>
              <div class="mt-2">
                <FormInput
                  data-legacy-input
                  @label={{t "common.tuition_assistance.add_dependent_modal.last_name"}}
                  @type="text"
                  @value={{this.dependentToEdit.lastName}}
                  @onChange={{fn (mut this.dependentToEdit.lastName)}}
                  data-test-edit-dependent-last-name
                />
              </div>
            </div>
            <div class="flex flex-col w-1/2">
              <div class="mt-2 mb-6">
                <FormSelect
                  data-legacy-input
                  @label={{t "common.tuition_assistance.add_dependent_modal.relationship"}}
                  @options={{relationships}}
                  @selected={{this.relationshipToEdit}}
                  @onChange={{fn (mut this.relationshipToEdit)}}
                  {{! @glint-expect-error}}
                  @renderInPlace={{true}}
                  @containerClass="t-select-relationship"
                  as |relationship|
                >
                  {{! @glint-expect-error}}
                  {{relationship.name}}
                </FormSelect>
              </div>
              <div class="mt-2">
                <FormInput
                  data-legacy-input
                  @label={{t "common.tuition_assistance.add_dependent_modal.birth_date"}}
                  @type="date"
                  @value={{this.dependentToEdit.birthDate}}
                  @onChange={{fn (mut this.dependentToEdit.birthDate)}}
                  data-test-edit-dependent-birth-date
                />
              </div>
            </div>
          </div>
        </form>
      </:body>
      <:actions>
        <div class="flex">
          <div class="flex w-2/3">
            <TioButton
              @outlined={{true}}
              @onClick={{this.cancel}}
              class="!w-1/2 !mr-2 !text-ocean-800 !border-ocean-800 !hover:bg-tio-gray-50 uppercase"
            >
              {{t "common.tuition_assistance.edit_dependent_modal.cancel_button"}}
            </TioButton>
            <TioButton
              @onClick={{this.submit}}
              class="!w-1/2 !mr-2 uppercase"
              data-test-dialog-submit
            >
              {{t "common.tuition_assistance.edit_dependent_modal.save_button"}}
            </TioButton>
          </div>
          <div class="flex w-1/3 justify-end">
            <TioButton @warning={{true}} @onClick={{this.deleteDependentToEdit}} class="uppercase">
              {{t "common.tuition_assistance.edit_dependent_modal.delete_button"}}
            </TioButton>
          </div>
        </div>
      </:actions>
    </TioDialog>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
