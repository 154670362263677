import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type PreRegisterService from '../../services/pre-register.ts';
import type RouterService from '@ember/routing/router';

export default class RegisterRoute extends Route {
  @service declare router: RouterService;
  @service declare preRegister: PreRegisterService;

  queryParams = {
    'ember-register': { refreshModel: false },
  };

  beforeModel() {
    const subDomain = this.preRegister.subdomain;

    if (subDomain === 'horacemann') {
      window.open('http://www.horacemann.com/student-loan-debt-help/signup', '_blank');
      this.router.transitionTo('login');
    }
  }
}
