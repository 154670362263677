import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { array } from '@ember/helper';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { TrackedObject } from 'tracked-built-ins';
import AppPageContent from 'tio-employee/components/app/page/content';
import ApproverComments from 'tio-common/components/tuition-assistance/approver-comments';
import Component from '@glimmer/component';
import ENV from 'tio-employee/config/environment';
import RouteTemplate from 'ember-route-template';
import TioAlert from 'tio-common/components/tio/alert';
import TioAppearance from 'tio-common/components/tio/appearance';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TuitionAssistanceApplicationDetailsApprovalQueueStatus from 'tio-common/components/tuition-assistance/application-details/approval-queue-status';
import TuitionAssistanceApplicationDetailsApproverOverview from 'tio-common/components/tuition-assistance/application-details/approver-overview';
import TuitionAssistanceApplicationDetailsCoursesStatusApproval from 'tio-common/components/tuition-assistance/application-details/courses-status-approval';
import TuitionAssistanceApplicationDetailsEmployeeInformation from 'tio-common/components/tuition-assistance/application-details/employee-information';
import TuitionAssistanceProgramDetailsAppDetailsAppHistory from 'tio-common/components/tuition-assistance/program-details/app-details/app-history';
import TuitionAssistanceProgramDetailsAppDetailsApproverCourseCard from 'tio-common/components/tuition-assistance/program-details/app-details/approver-course-card';
import TuitionAssistanceProgramDetailsAppDetailsReadonlyCourseCard from 'tio-common/components/tuition-assistance/program-details/app-details/readonly-course-card';
import TuitionAssistanceProgramDetailsAppDetailsScholarshipCard from 'tio-common/components/tuition-assistance/program-details/app-details/scholarship-card';
import TuitionAssistanceProgramDetailsApplicationInformation from 'tio-common/components/tuition-assistance/program-details/application-information';
import TuitionAssistanceProgramDetailsProgramInfoCard from 'tio-common/components/tuition-assistance/program-details/program-info-card';
import type { ReceivedScholarship } from 'tio-common/types/tuition-assistance';
import type SessionContextService from 'tio-employee/services/session-context';
import type SessionService from 'tio-employee/services/session';
import type TasApplicationModel from 'tio-common/models/tas-application';
import ProgressTracker from 'tio-common/components/tio/progress-tracker';
import type ProgressBuilder from 'tio-common/services/progress-builder';
import type { Step } from 'tio-common/components/tio/progress-tracker';
interface RouteSignature {
    Args: {
        model: TasApplicationModel;
    };
}
let TuitionAssistanceApplicationsShowIndexRouteComponent = class TuitionAssistanceApplicationsShowIndexRouteComponent extends Component<RouteSignature> {
    @service
    sessionContext: SessionContextService;
    @service
    session: SessionService;
    @service
    progressBuilder: ProgressBuilder;
    applicationFieldsCopy: TasApplicationModel['fields'];
    constructor(owner1: unknown, args1: RouteSignature['Args']){
        super(owner1, args1);
        this.applicationFieldsCopy = new TrackedObject(this.args.model.fields);
    }
    get canEditTasApplication() {
        return this.sessionContext.canEditTasApplication && this.args.model.approverCanEditApplication;
    }
    get approverName() {
        return this.sessionContext.user?.person?.fullName || '';
    }
    get steps() {
        return this.progressBuilder.applicationStepList(this.args.model) as Step[];
    }
    @action
    didUpdateScholarships(editableScholarships1: ReceivedScholarship[], changelog1?: string) {
        this.applicationFieldsCopy['SCHOLARSHIPS_RECEIVED'] = {
            values: editableScholarships1
        };
        if (changelog1) {
            this.saveScholarships.perform(changelog1);
        }
    }
    saveScholarships = dropTask(async (changelog1: string)=>{
        this.args.model.fields = this.applicationFieldsCopy;
        try {
            await this.args.model.save({
                adapterOptions: {
                    changelog: changelog1
                }
            });
        } catch (e1) {
            console.error(e1);
        }
    });
    static{
        template(`
    <div class="flex sticky top-0 bg-white">
      <TioPageBreadcrumbs class="mt-4 flex-1" as |b|>
        <b.crumb
          @route="authenticated.admin.tuition-assistance.applications.index"
          @label="Applications"
        />
        <b.crumb
          @route="authenticated.admin.tuition-assistance.applications.show.index"
          @model={{@model.id}}
          @label={{@model.displayName}}
        />

      </TioPageBreadcrumbs>
      <div class="mt-2">
        <TioAppearance
          @apiKey={{ENV.apiKey}}
          @apiHost={{ENV.apiHost}}
          @token={{this.session.data.authenticated.access_token}}
          @type="tas_course_application"
          @documentId={{@model.id}}
          class="inline-block"
          @label={{t "common.also_viewing"}}
        />
      </div>
    </div>
    <AppPageContent>
      {{#if @model.tasProgramInstance.employee.tasParticipant.isHeld}}
        <TioAlert @type="warning" @allowDismiss={{false}} class="max-w-8xl mx-4 mb-8">
          <:header>
            <p class="font-semibold">{{t "tuition_assistance.alert.employee_held_pt_1"}}</p>
          </:header>
          <:body>
            <p class="text-sm">{{t "tuition_assistance.alert.employee_held_pt_2"}}</p>
          </:body>
        </TioAlert>
      {{/if}}
      <div class="my-8">
        <ProgressTracker class="hidden lg:block" @steps={{this.steps}} />
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div class="col-span-1 space-y-5">
          <TuitionAssistanceApplicationDetailsEmployeeInformation
            @instance={{@model.tasProgramInstance}}
            @application={{@model}}
            @profileRoute="authenticated.admin.employees.show"
            @routeModel={{@model.tasProgramInstance.employee.id}}
          />
          <TuitionAssistanceProgramDetailsApplicationInformation
            @application={{@model}}
            @isAdminView={{true}}
            @canRequestRepayment={{this.sessionContext.user.isAccountOwnerOrEmployeeAdmin}}
            @canEditTasApplication={{this.canEditTasApplication}}
            @isReadonlyView={{false}}
          />
          <TuitionAssistanceProgramDetailsProgramInfoCard
            @programInstance={{@model.tasProgramInstance}}
            @showAttachments={{true}}
          />
        </div>
        <div class="col-span-1 space-y-5">
          <TuitionAssistanceApplicationDetailsApproverOverview
            @programTemplate={{@model.tasProgramInstance.tasProgramTemplate}}
          />
        </div>
        <div class="col-span-1">
          <ApproverComments
            @commentable={{@model}}
            @commentableType="TasApplication"
            @authoredByPerson={{this.sessionContext.user.person}}
            @commentTags={{array "approver"}}
          />
        </div>
      </div>
      <div class="flex flex-wrap my-8 gap-5 justify-center md:justify-start">
        {{#each @model.activeCourses as |course|}}
          {{#if this.canEditTasApplication}}
            <div>
              <TuitionAssistanceProgramDetailsAppDetailsApproverCourseCard
                @course={{course}}
                @showCustomFields={{true}}
                @approverName={{this.approverName}}
              />
            </div>
          {{else}}
            <div>
              <TuitionAssistanceProgramDetailsAppDetailsReadonlyCourseCard
                @course={{course}}
                @showCustomFields={{true}}
              />
            </div>
          {{/if}}
        {{/each}}
        <div>
          <TuitionAssistanceProgramDetailsAppDetailsScholarshipCard
            @application={{@model}}
            @isEditable={{this.canEditTasApplication}}
            @isAdminView={{true}}
            @onUpdateScholarships={{this.didUpdateScholarships}}
          />
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div class="col-span-1">
          <TuitionAssistanceApplicationDetailsApprovalQueueStatus
            @application={{@model}}
            @consumingApp="employee"
            @isReadonlyView={{false}}
          />
        </div>
        <div class="col-span-1">
          <TuitionAssistanceApplicationDetailsCoursesStatusApproval
            @application={{@model}}
            @programInstance={{@model.tasProgramInstance}}
            @routePrefix="authenticated.admin"
            @isReadonlyView={{false}}
          />
        </div>
      </div>

      <TuitionAssistanceProgramDetailsAppDetailsAppHistory
        @application={{@model}}
        @viewType="admin"
      />
    </AppPageContent>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(TuitionAssistanceApplicationsShowIndexRouteComponent);
