import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { trackedFunction } from 'reactiveweb/function';
import AppContentCard from 'tio-common/components/app/content-card';
import Component from '@glimmer/component';
import type PersonModel from 'tio-common/models/person';
import TioPageHorizontalRule from 'tio-common/components/tio/page/horizontal-rule';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type AnswerProfileService from 'tio-employee/services/answer-profile';
import type RecomendationModel from 'tio-common/models/recommendation';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import AddAccountTodo from './add-account-todo';
import ConsolidationTodo from './consolidation-todo';
import RecertifyTodo from './recertify-todo';
import RepaymentPlanTodo from './repayment-plan-todo';
export interface DashRecommendationsSignature {
    Args: {
        loanSummary: LoanSummary;
        person: PersonModel;
        recommendation: RecomendationModel;
    };
    Element: HTMLDivElement;
}
export default class DashRecommendationsComponent extends Component<DashRecommendationsSignature> {
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @service('answer-profile')
    answerProfileService: AnswerProfileService;
    answerProfileState = trackedFunction(this, async ()=>{
        const profile1 = await this.answerProfileService.findOrCreateAnswerProfileForCurrentUser();
        return profile1;
    });
    get answerProfile() {
        return this.answerProfileState.value;
    }
    get loanSummary() {
        return this.args.loanSummary;
    }
    get loanSummariesByType() {
        return this.loanSummary?.summaryByLoanType || [];
    }
    get loans() {
        return this.args.person.allActiveAccountLoans || [];
    }
    get shouldConsolidateLoans() {
        return !!this.loanSummariesByType.find((summary1)=>{
            return summary1.loanType !== 'direct';
        });
    }
    get shouldChangeRepaymentPlan() {
        const validRepaymentPlansForPslf1 = [
            'icr',
            'ibr',
            'paye',
            'repaye',
            'fivePercentUndergrad'
        ];
        const allRepaymentPlans1 = [
            ...new Set(this.loanSummariesByType.flatMap((summary1)=>summary1.repaymentPlans))
        ];
        return allRepaymentPlans1.every((plan1)=>!validRepaymentPlansForPslf1.includes(plan1));
    }
    get shouldLinkLoans() {
        return this.args.person.needsToLinkLoans;
    }
    get hasNoRecommendations() {
        return !(this.shouldConsolidateLoans || this.shouldChangeRepaymentPlan || this.shouldLinkLoans || this.needsToRecertify);
    }
    get hasTakenEstimator() {
        return !!this.args.recommendation;
    }
    get needsToRecertify() {
        return this.hasTakenEstimator;
    }
    static{
        template(`
    {{#unless this.hasNoRecommendations}}
      <AppContentCard @title={{t "recommendations.default"}} @titleClass="uppercase" ...attributes>
        <TioPageHorizontalRule @size="sm" />
        <ul>
          {{#if this.shouldLinkLoans}}
            <AddAccountTodo />
          {{/if}}
          {{#if this.shouldConsolidateLoans}}
            <ConsolidationTodo @answerProfile={{this.answerProfile}} />
          {{/if}}
          {{#if this.shouldChangeRepaymentPlan}}
            <RepaymentPlanTodo @answerProfile={{this.answerProfile}} />
          {{/if}}
          {{#if this.needsToRecertify}}
            <RecertifyTodo @answerProfile={{this.answerProfile}} />
          {{/if}}
        </ul>
      </AppContentCard>
    {{/unless}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
