import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type { IntlService } from 'ember-intl';
import type MatchParticipantModel from 'tio-common/models/match-participant';
import type MatchPlanModel from 'tio-common/models/match-plan';
import TioForm from 'tio-common/components/tio/form';
import { tracked } from '@glimmer/tracking';
import { object, number } from 'yup';
import { action } from '@ember/object';
import { t } from 'ember-intl';
import { getTranslationKeyForReportedSalaryForMatchPlan } from 'tio-common/utils/syf';
import TioDialog from 'tio-common/components/tio/dialog';
interface SyfDashboardInsightsParticipantFormDialogSignature {
    Args: {
        matchParticipant: MatchParticipantModel;
        matchPlan: MatchPlanModel;
        didSubmit?: (savedModel: MatchParticipantModel) => void;
        onCancel?: () => void;
    };
    Element: HTMLDivElement;
}
type SyfDashboardInsightsParticipantFormData = {
    reportedSalary: number;
    reportedMonthlyPayment: number;
};
let SyfDashboardInsightsParticipantFormDialog = class SyfDashboardInsightsParticipantFormDialog extends Component<SyfDashboardInsightsParticipantFormDialogSignature> {
    @service
    store: typeof Store;
    @service
    intl: IntlService;
    @tracked
    isSubmitting = false;
    formSubmitAction: (() => void) | undefined = undefined;
    initialFormData: SyfDashboardInsightsParticipantFormData | Record<string, unknown> = {};
    constructor(owner1: unknown, args1: SyfDashboardInsightsParticipantFormDialogSignature['Args']){
        super(owner1, args1);
        const { matchParticipant: matchParticipant1 } = this.args;
        this.initialFormData = {
            reportedSalary: (matchParticipant1.reportedSalary || 0) / 100,
            reportedMonthlyPayment: (matchParticipant1.reportedMonthlyPayment || 0) / 100
        };
    }
    get reportedSalaryFieldLabel() {
        return this.intl.t(getTranslationKeyForReportedSalaryForMatchPlan(this.args.matchPlan));
    }
    @action
    setFormSubmitAction(submitAction1?: () => SyfDashboardInsightsParticipantFormData) {
        this.formSubmitAction = submitAction1;
    }
    @action
    async onSubmit(formData1: SyfDashboardInsightsParticipantFormData) {
        const model1 = this.args.matchParticipant;
        this.updateModelWithFormValues(model1, formData1);
        const saved1 = await model1.save();
        this.args.didSubmit?.(saved1);
    }
    @action
    updateModelWithFormValues(matchParticipant1: MatchParticipantModel, formData1: SyfDashboardInsightsParticipantFormData) {
        const { reportedSalary: reportedSalary1, reportedMonthlyPayment: reportedMonthlyPayment1 } = formData1;
        matchParticipant1.reportedSalary = reportedSalary1 * 100;
        matchParticipant1.reportedMonthlyPayment = reportedMonthlyPayment1 * 100;
    }
    formSchema = object().shape({
        reportedSalary: number().nullable(),
        reportedMonthlyPayment: number().nullable()
    });
    static{
        template(`
    <TioDialog
      @show={{true}}
      @onConfirm={{this.formSubmitAction}}
      @onCancel={{@onCancel}}
      @submitButtonText="Save"
      @containerClass="w-full sm:max-w-screen-sm"
    >
      <:body>
        <TioForm
          class=""
          @i18nPath="syf.plan_form"
          @data={{this.initialFormData}}
          @schema={{this.formSchema}}
          {{! @glint-expect-error}}
          @onSubmit={{this.onSubmit}}
          as |form|
        >
          {{! @glint-expect-error}}
          {{this.setFormSubmitAction form.submit}}
          <form.CurrencyField
            @containerClass="mb-4"
            @name="reportedMonthlyPayment"
            @label={{t "syf.dashboard.insights.estimated_monthly_payment"}}
          />
          <form.CurrencyField
            @containerClass="mb-4"
            @name="reportedSalary"
            @label={{this.reportedSalaryFieldLabel}}
          />

          <form.Errors class="my-6" />
        </TioForm>
      </:body>
    </TioDialog>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default SyfDashboardInsightsParticipantFormDialog;
