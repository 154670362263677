import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { createConsumer } from '@rails/actioncable';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import ENV from 'tio-employee/config/environment';
import MaterialIcon from 'tio-common/components/material-icon';
import TioButton from 'tio-common/components/tio/button';
import type { IntlService } from 'ember-intl';
import type PslfFormModel from 'tio-common/models/pslf-form';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type SessionService from 'tio-employee/services/session';
import { trackedFunction } from 'reactiveweb/function';
import type Store from '@ember-data/store';
export interface PslfFormPageSignature {
    Args: {
        form: PslfFormModel;
        signer: string;
    };
}
type ConsumerType = ReturnType<typeof createConsumer>;
type ReceivedMessageType = {
    status: string;
};
export default class PslfSignFormPageComponent extends Component<PslfFormPageSignature> {
    @service
    intl: IntlService;
    @service
    session: SessionService;
    @service
    sessionContext: SessionContextService;
    @service
    router: RouterService;
    @service
    store: typeof Store;
    @tracked
    pslfFormStatus = '';
    consumer: ConsumerType;
    unreadMessagesCountSubscription: ConsumerType['subscriptions']['subscriptions'][0];
    constructor(owner1: unknown, args1: PslfFormPageSignature['Args']){
        super(owner1, args1);
        this.setupCable();
    }
    get isBorrower() {
        const signer1 = this.args.signer;
        const isBorrower1 = signer1 === 'borrower';
        return isBorrower1;
    }
    get alreadySigned() {
        const status1 = this.args.form.status;
        const isSigned1 = this.isBorrower ? ![
            'created',
            'pending'
        ].includes(status1) : ![
            'created',
            'borrower_signed'
        ].includes(status1);
        return isSigned1;
    }
    get borrowerName() {
        return this.args.form.borrowerFields['borrower-name'];
    }
    get employerMessage() {
        return this.intl.t('pslf.complete_pslf.done_employer', {
            date: this.args.form.borrowerCompletedAt,
            name: this.borrowerName
        });
    }
    @action
    goBack(event1?: Event) {
        event1?.preventDefault();
        window.history.back();
    }
    get continueLink() {
        return this.isBorrower ? 'authenticated.pslf.dashboard.index' : 'authenticated.admin.pslf';
    }
    @action
    async setupCable() {
        if (!this.sessionContext?.user) {
            return;
        }
        const KEY1 = encodeURIComponent(ENV.apiKey);
        const TOKEN1 = encodeURIComponent(this.session.data.authenticated.access_token);
        const URL1 = `${ENV.apiHost}/cable?apiKey=${KEY1}&token=${TOKEN1}`;
        this.consumer = createConsumer(URL1);
        this.consumer.connect();
        this.subscribeToPslfFormStatus(this.args.form.id);
    }
    @action
    async subscribeToPslfFormStatus(pslfFormId1: string) {
        this.unreadMessagesCountSubscription = this.consumer.subscriptions.create({
            channel: 'PslfFormStatusChannel',
            pslf_form_id: pslfFormId1
        }, {
            received: (data1: ReceivedMessageType)=>{
                this.pslfFormStatus = data1.status;
                const signer1 = this.args.signer;
                const isBorrower1 = signer1 === 'borrower';
                const isSigned1 = isBorrower1 ? ![
                    'created',
                    'pending'
                ].includes(data1.status) : ![
                    'created',
                    'borrowerSigned'
                ].includes(data1.status);
                if (isSigned1) {
                    if (isBorrower1) {
                        this.router.transitionTo('authenticated.pslf.form.what-next', {
                            queryParams: {
                                formId: pslfFormId1
                            }
                        });
                    } else {
                        this.router.transitionTo('authenticated.admin.pslf');
                    }
                }
            }
        });
    }
    embedUrlData = trackedFunction(this, async ()=>{
        if (this.isBorrower) {
            return this.args.form.borrowerFields['embed-url'];
        }
        const response1 = await this.store.adapterFor('pslf-form').prepareForEmployerSignature(this.args.form);
        return response1.embedUrl;
    });
    get embedUrl() {
        return this.embedUrlData.value || '';
    }
    static{
        template(`
    <div class="flex items-center">
      {{#if this.alreadySigned}}
        <h2 class="text-xl">{{t "pslf.complete_pslf.already_signed"}}</h2>
      {{else}}
        <TioButton @outlined={{true}} @onClick={{this.goBack}} class="mr-4">
          <MaterialIcon @icon="arrow_back_ios" />
          <div class="p-1">{{t "back"}}</div>
        </TioButton>
        <h2 class="text-xl">{{t "pslf.complete_pslf.title"}}</h2>
      {{/if}}
    </div>

    <hr class="my-4" />
    {{#if this.alreadySigned}}
      {{#if this.isBorrower}}
        <div>{{t "pslf.complete_pslf.done_borrower"}}</div>
      {{else}}
        <div>{{this.employerMessage}}</div>
      {{/if}}
      <TioButton
        @outlined={{true}}
        class="my-4 w-[312px]"
        color="accent"
        @linkTo={{this.continueLink}}
      >
        {{t "continue"}}
        <MaterialIcon @icon="chevron_right" />
      </TioButton>
    {{else}}
      <h3 class="mb-8">
        {{t "pslf.complete_pslf.note"}}
      </h3>
      {{#if this.embedUrl}}
        <iframe
          class="w-full h-[1000px]"
          title={{t "pslf.complete_pslf.title"}}
          src={{this.embedUrl}}
        />
      {{/if}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
