import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { array } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'reactiveweb/function';
import AppPage from 'tio-employee/components/app/page';
import AppPageContent from 'tio-employee/components/app/page/content';
import Chip from 'tio-common/components/ui/chip';
import Component from '@glimmer/component';
import divide from 'ember-math-helpers/helpers/div';
import MaterialIcon from 'tio-common/components/material-icon';
import RouteTemplate from 'ember-route-template';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import SelectGeneric from 'tio-common/components/select/generic';
import Table from 'tio-common/components/table/index';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TioPageHeaderTitle from 'tio-common/components/tio/page/header/title';
import type { GroupedData } from 'tio-employee/routes/authenticated/tuition-assistance/my-payment-details';
import type IntlService from 'ember-intl/services/intl';
import type StoreService from 'tio-common/services/store';
import type TuitionAssistanceMyPaymentDetailsController from 'tio-employee/controllers/authenticated/tuition-assistance/my-payment-details';
import TioPageHorizontalRuleComponent from 'tio-common/components/tio/page/horizontal-rule';
export interface AppTuitionAssistanceMyPaymentDetailsSignature {
    Args: {
        model: GroupedData[];
        controller: TuitionAssistanceMyPaymentDetailsController;
    };
}
let AppTuitionAssistanceMyPaymentDetailsComponent = class AppTuitionAssistanceMyPaymentDetailsComponent extends Component<AppTuitionAssistanceMyPaymentDetailsSignature> {
    @service
    intl: IntlService;
    @service
    store: StoreService;
    @tracked
    error?: Error;
    @tracked
    isLoading = false;
    @tracked
    selectedYear = '';
    currentYear = new Date().getFullYear().toString();
    organizePaymentData = trackedFunction(this, async ()=>{
        const paidStates1 = [
            'TAS.ApplicationPaymentState.PAID_IN_FULL',
            'TAS.ApplicationPaymentState.REFUND_REQUESTED',
            'TAS.ApplicationPaymentState.PAID_IN_PART'
        ];
        const pendingStates1 = [
            'TAS.AssistanceState.UNSETTLED',
            'TAS.AssistanceState.PAYABLE',
            'TAS.AssistanceState.REPAYABLE'
        ];
        const initialAccumulator1: {
            paidData: GroupedData[];
            pendingData: GroupedData[];
        } = {
            paidData: [],
            pendingData: []
        };
        const acceptedAssistanceStates1 = [
            'TAS.AssistanceState.REPAYABLE',
            'TAS.AssistanceState.SETTLED'
        ];
        const { paidData: paidData1, pendingData: pendingData1 } = this.args.model.reduce((acc1, application1)=>{
            if (application1.application_state === 'TAS.ApplicationState.FULFILLED' && paidStates1.includes(application1.payment_state) && acceptedAssistanceStates1.includes(application1.assistance_state)) {
                acc1.paidData.push(application1);
            } else if (application1.payment_state === 'TAS.ApplicationPaymentState.UNPAID' && pendingStates1.includes(application1.assistance_state) && application1.application_state !== 'TAS.ApplicationState.ABANDONED') {
                acc1.pendingData.push(application1);
            }
            return acc1;
        }, initialAccumulator1);
        return {
            paidData: paidData1,
            pendingData: pendingData1
        };
    });
    get organizedData() {
        return this.organizePaymentData.value || {
            paidData: [],
            pendingData: []
        };
    }
    get paidData() {
        return this.organizedData.paidData;
    }
    get pendingData() {
        return this.organizedData.pendingData;
    }
    get yearOptions() {
        const beginYear1: number = Number(this.currentYear) - 7;
        return Array.from({
            length: 9
        }, (_1, i1)=>`${beginYear1 + i1 - 1} - ${beginYear1 + i1}`);
    }
    @action
    setSelectedYear(year1: string | null) {
        //if year is null, it means the user has cleared the year filter and an empty string should be set
        this.selectedYear = year1 || '';
        this.args.controller.paymentYear = year1 ? year1.split(' - ')[1] || '' : '';
    }
    get paidTotal() {
        return (this.paidData.reduce((sum1, { total_paid_amount: total_paid_amount1 })=>sum1 + (total_paid_amount1 || 0), 0) / 100);
    }
    @action
    uiPillStateLabel(paymentState1: string, assistanceState1: string): string {
        switch(paymentState1){
            case 'TAS.ApplicationPaymentState.PAID_IN_FULL':
                return this.intl.t('tuition_assistance.dashboard.payment_activity.pill_label.fully_paid');
            case 'TAS.ApplicationPaymentState.REFUND_REQUESTED':
                return this.intl.t('tuition_assistance.dashboard.payment_activity.pill_label.in_repayment');
            case 'TAS.ApplicationPaymentState.PAID_IN_PART':
                return this.intl.t('tuition_assistance.dashboard.payment_activity.pill_label.partially_paid');
            case 'TAS.ApplicationPaymentState.UNPAID':
                switch(assistanceState1){
                    case 'TAS.AssistanceState.UNSETTLED':
                        return this.intl.t('tuition_assistance.dashboard.payment_activity.pill_label.approved_for_courses');
                    case 'TAS.AssistanceState.PAYABLE':
                    case 'TAS.AssistanceState.REPAYABLE':
                        return this.intl.t('tuition_assistance.dashboard.payment_activity.pill_label.pending');
                    default:
                        return this.intl.t('tuition_assistance.dashboard.payment_activity.pill_label.in_transition');
                }
            default:
                return this.intl.t('tuition_assistance.dashboard.payment_activity.pill_label.in_transition');
        }
    }
    uiPillStateBackgroundColor(paymentState1: string, assistanceState1: string): string {
        switch(paymentState1){
            case 'TAS.ApplicationPaymentState.PAID_IN_FULL':
                return 'success';
            case 'TAS.ApplicationPaymentState.REFUND_REQUESTED':
                return 'error';
            case 'TAS.ApplicationPaymentState.PAID_IN_PART':
                return 'info';
            case 'TAS.ApplicationPaymentState.UNPAID':
                switch(assistanceState1){
                    case 'TAS.AssistanceState.UNSETTLED':
                        return 'info';
                    case 'TAS.AssistanceState.PAYABLE':
                    case 'TAS.AssistanceState.REPAYABLE':
                        return 'warning';
                    default:
                        return 'warning';
                }
            default:
                return 'warning';
        }
    }
    static{
        template(`
    <AppPage>
      <TioPageBreadcrumbs class="flex items-center" as |b|>
        <MaterialIcon @icon="arrow_back" class="text-lg leading-none" />
        <b.crumb
          @route="authenticated.tuition-assistance.dashboard"
          @label={{t "tuition_assistance.default"}}
        />
      </TioPageBreadcrumbs>
      <TioPageHeaderTitle>
        <div class="flex justify-between">
          <div class="self-center">
            {{t "tuition_assistance.dashboard.payment_activity.payment_details"}}
          </div>
          <div class="mr-4">
            <SelectGeneric
              @label={{t
                "tuition_assistance.dashboard.payment_activity.filter_all_records_by_year"
              }}
              @selected={{this.selectedYear}}
              @options={{this.yearOptions}}
              @searchEnabled={{true}}
              @onChange={{this.setSelectedYear}}
              @labelClass="form-field-label text-black p-2"
              {{! @glint-expect-error}}
              @allowClear={{true}}
              @placeholder="All Payments"
            >
              <:option as |option|>
                <span class="text-sm">
                  {{option}}
                </span>
              </:option>
            </SelectGeneric>
          </div>
        </div>
      </TioPageHeaderTitle>

      <AppPageContent>
        <div class="mb-3">
          {{#if this.pendingData.length}}
            <h1 class="text-sm font-semibold uppercase mb-3">
              {{t "tuition_assistance.dashboard.pending_payment_details.title"}}
            </h1>
            <Table @isLoading={{false}}>
              <:thead as |options|>
                <options.tr>
                  <options.th>
                    {{t "tuition_assistance.dashboard.pending_payment_details.application_name"}}
                  </options.th>
                  <options.th />
                  <options.th>
                    {{t "tuition_assistance.dashboard.pending_payment_details.expected_amount"}}
                  </options.th>
                </options.tr>
              </:thead>
              <:tbody as |options|>
                {{#each this.pendingData as |record|}}
                  <options.tr>
                    <options.td>
                      <LinkTo
                        @route="authenticated.tuition-assistance.programs.instance.course-applications.show"
                        @models={{array record.program_instance_id record.application_id}}
                      >
                        {{record.application_id}}
                      </LinkTo>
                    </options.td>
                    <options.td>
                      <Chip
                        @label={{this.uiPillStateLabel
                          record.payment_state
                          record.assistance_state
                        }}
                        @type={{this.uiPillStateBackgroundColor
                          record.payment_state
                          record.assistance_state
                        }}
                      />
                    </options.td>
                    <options.td>
                      {{safeFormatNumber
                        (divide record.total_approved_amount 100)
                        style="currency"
                        currency="USD"
                      }}
                    </options.td>
                  </options.tr>
                {{/each}}
              </:tbody>
            </Table>
            <TioPageHorizontalRuleComponent @size="md" />
          {{/if}}
        </div>

        <div>
          <h1 class="text-sm font-semibold uppercase mb-3">
            {{t "tuition_assistance.dashboard.payment_details.title"}}
          </h1>
          {{#if this.paidData.length}}
            <div class="mb-5">
              <Table @isLoading={{false}}>
                <:thead as |options|>
                  <options.tr>
                    <options.th>
                      {{t "tuition_assistance.dashboard.payment_details.application_name"}}
                    </options.th>
                    <options.th />
                    <options.th>
                      {{t "tuition_assistance.dashboard.payment_details.payment_date"}}
                    </options.th>
                    <options.th>
                      {{t "tuition_assistance.dashboard.payment_details.payment_amount"}}
                    </options.th>
                  </options.tr>
                </:thead>
                <:tbody as |options|>
                  {{#each this.paidData as |record|}}
                    <options.tr>
                      <options.td>
                        <LinkTo
                          @route="authenticated.tuition-assistance.programs.instance.course-applications.show"
                          @models={{array record.program_instance_id record.application_id}}
                        >
                          {{record.application_id}}
                        </LinkTo>
                      </options.td>
                      <options.td>
                        <Chip
                          @label={{this.uiPillStateLabel
                            record.payment_state
                            record.assistance_state
                          }}
                          @type={{this.uiPillStateBackgroundColor
                            record.payment_state
                            record.assistance_state
                          }}
                        />
                      </options.td>
                      {{#if record.paid_date}}
                        <options.td>{{safeFormatDate
                            record.paid_date
                            month="2-digit"
                            day="2-digit"
                            year="numeric"
                          }}</options.td>
                      {{else}}
                        <options.td />
                      {{/if}}
                      <options.td>
                        {{safeFormatNumber
                          (divide record.total_paid_amount 100)
                          style="currency"
                          currency="USD"
                        }}
                      </options.td>
                    </options.tr>
                  {{/each}}
                </:tbody>
              </Table>
            </div>
          {{else}}
            <div class="">
              {{t "tuition_assistance.dashboard.payment_activity.no_records_found"}}
            </div>
          {{/if}}
          <div class="flex my-2 text-lg font-semibold text-white">
            <div class="flex justify-between py-2 px-8 mb-5 bg-tio-gray-400 w-full">
              <div>
                {{t "tuition_assistance.dashboard.payment_activity.total"}}
              </div>
              <div>
                {{safeFormatNumber this.paidTotal style="currency" currency="USD"}}
              </div>
            </div>
          </div>
        </div>
      </AppPageContent>
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AppTuitionAssistanceMyPaymentDetailsComponent);
