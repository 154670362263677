import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { service } from '@ember/service';
import { t, type IntlService } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AccountRow from './account-row';
import Button from 'tio-common/components/tio/button';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import TioClickableText from 'tio-common/components/tio/clickable-text';
import TioDialog from 'tio-common/components/tio/dialog';
import type AccountModel from 'tio-common/models/account';
import type NotificationsService from '@frontile/notifications/services/notifications';
interface AccountDialogSignature {
    Args: {
        show: boolean;
        onClose: () => void;
        accountWithContribution: AccountModel;
        activeAccounts: AccountModel[];
        triggerElement?: HTMLElement | null;
    };
}
export default class AccountDialog extends Component<AccountDialogSignature> {
    @service
    notifications: NotificationsService;
    @service
    intl: IntlService;
    @tracked
    selectedAccount: AccountModel;
    constructor(owner1: unknown, args1: AccountDialogSignature['Args']){
        super(owner1, args1);
        this.selectedAccount = args1.accountWithContribution;
    }
    get sortedAccounts() {
        return this.args.activeAccounts.sort((a1, b1)=>a1.publicOrdinalValue - b1.publicOrdinalValue);
    }
    get isNewAccountSelected() {
        return this.args.accountWithContribution !== this.selectedAccount;
    }
    @action
    onClickAccountRow(account1: AccountModel) {
        this.selectedAccount = account1;
    }
    @action
    onClose() {
        this.selectedAccount = this.args.accountWithContribution;
        this.args.onClose();
    }
    @action
    async changeAccountWithContribution(account1: AccountModel) {
        this.args.activeAccounts.forEach((a1)=>(a1.publicOrdinalValue = 1));
        account1.publicOrdinalValue = 0;
        this.args.activeAccounts.map(async (a1)=>a1.save());
        this.notifications.add(this.intl.t('slr.account_dialog.success'), {
            appearance: 'success'
        });
        this.onClose();
    }
    static{
        template(`
    <TioDialog
      @show={{@show}}
      @onCancel={{this.onClose}}
      @containerClass="rounded-md lg:w-2/5 md:w-3/5 sm:w-4/5 min-h-96 flex flex-col border border-gray-300"
      @actionsClass="text-center !px-0 mt-auto"
      @headerClass="px-6 py-4"
      @bodyClass="grid grid-cols-1 gap-4"
      @triggerElement={{@triggerElement}}
      @isClearBackdrop={{true}}
      @closeOnOutsideClick={{true}}
      @align="right"
    >
      <:header>
        <span class="text-xl text-midnight font-semibold">{{t "slr.account_dialog.title"}}</span>
      </:header>
      <:body>
        {{#each this.sortedAccounts as |account index|}}
          <AccountRow
            @account={{account}}
            @selectedAccount={{this.selectedAccount}}
            @index={{index}}
            @onClickAccountRow={{this.onClickAccountRow}}
            @sortedAccounts={{this.sortedAccounts}}
          />
        {{/each}}
        <TioClickableText
          @linkTo="authenticated.slr.linking"
          @icon="play_arrow"
          @outlined={{true}}
          @iconClass="border-0 pl-0 -ml-2"
          @iconRight={{true}}
          @textClass="text-md underline text-ocean-600"
          class="w-fit"
        >
          {{t "slr.account_dialog.link_account"}}
        </TioClickableText>
      </:body>
      <:actions>
        {{#if this.isNewAccountSelected}}
          <div class="flex w-full mb-4 bg-gray-100 p-6 my-2">
            <MaterialIcon @icon="info" @style="outlined" class="text-gray-700" />
            <p>{{t "slr.account_dialog.change_account"}}</p>
          </div>
          <div class="flex justify-center space-x-2 w-full mt-4">
            <Button @outlined={{true}} class="sm:min-w-48 sm:w-fit" @onClick={{this.onClose}}>
              {{t "cancel"}}
            </Button>
            <Button
              class="sm:min-w-48 sm:w-fit"
              @onClick={{fn this.changeAccountWithContribution this.selectedAccount}}
            >
              {{t "done"}}
            </Button>
          </div>
        {{else}}
          <Button
            @outlined={{true}}
            @onClick={{this.onClose}}
            class="sm:min-w-48 sm:w-fit m-auto capitalize"
          >
            {{t "close"}}
          </Button>
        {{/if}}
      </:actions>
    </TioDialog>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
