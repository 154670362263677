import ApplicationAdapter from './application';
import ENV from 'tio-employee/config/environment';

/**
 * An extended adapter for the `UserLogin` model.
 *
 * @memberof adapters
 */
class LoginAdapter extends ApplicationAdapter {
  async sendForgotPassword({ email, type }: { email: string; type: string }) {
    const url = this.buildURL('login') + '/forgot-password';
    return await this.ajax(url, 'POST', {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': ENV.apiKey,
      },
      body: JSON.stringify({
        email,
        requestType: type,
      }),
    });
  }

  async resetPassword({
    email,
    password,
    token,
  }: {
    email: string;
    password: string;
    token: string;
  }) {
    const url = this.buildURL('login') + '/reset-password';
    return await this.ajax(url, 'POST', {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': ENV.apiKey,
      },
      body: JSON.stringify({
        email,
        password,
        token,
      }),
    });
  }
}

export default LoginAdapter;
