import { template } from "@ember/template-compiler";
import { htmlSafe } from '@ember/template';
import { not } from 'ember-truth-helpers';
import AppContentCard from 'tio-common/components/app/content-card';
import Component from '@glimmer/component';
import inc from 'ember-composable-helpers/helpers/inc';
import screenIs from 'tio-common/helpers/screen-is';
import TioDownloadButton from 'tio-common/components/tio/download-button';
import TioPdfPrintButton from 'tio-common/components/tio/pdf/print-button';
import type RecommendationModel from 'tio-common/models/recommendation';
export interface ForgivenessEstimatorResultInstructionsSignature {
    Args: {
        recommendation: RecommendationModel;
    };
}
export default class ForgivenessEstimatorResultInstructionsComponent extends Component<ForgivenessEstimatorResultInstructionsSignature> {
    get instructions() {
        return this.args.recommendation?.nextSteps || [];
    }
    static{
        template(`
    {{#if (screenIs "md")}}
      <div class="mx-20 my-4">
        <div class="mb-0 border">
          <div class="flex flex-row-reverse text-sm">
            <TioPdfPrintButton
              @url={{@recommendation.downloadUrl}}
              @filename={{@recommendation.downloadFilename}}
              class="border-none"
            />
            <TioDownloadButton
              @url={{@recommendation.downloadUrl}}
              @filename={{@recommendation.downloadFilename}}
              class="border-none"
            />
          </div>
        </div>
        <table class="text-sm mb-4 step-by-step-table border table-fixed overflow-hidden w-full">
          <thead>
            <tr>
              {{#each this.instructions as |column|}}
                <th scope="col" class="bg-ocean-600 text-white text-left align-top border">
                  {{column.title}}
                  {{#if column.subtitle}}
                    <div class="font-normal">
                      {{column.subtitle}}
                    </div>
                  {{/if}}
                </th>
              {{/each}}
            </tr>
          </thead>
          <tbody class="relative">
            <tr>
              {{#each this.instructions as |row|}}
                <td class="border align-top">
                  {{#if row.subtitle}}
                    <p class="font-semibold mx-2 mt-4 min-h-12">{{row.subtitle}}:</p>
                  {{/if}}
                  <ul class="flex-col top-10">
                    {{#each row.instructions as |step index|}}
                      <li class="flex my-4 mx-2">
                        <div
                          class="rounded-full bg-ocean-600 text-white mr-2 w-6 h-6 flex items-center justify-center shrink-0"
                        >{{inc index}}</div>
                        <span class="step-by-step-table-links">
                          {{htmlSafe step}}
                        </span>
                      </li>
                    {{/each}}
                  </ul>
                </td>
              {{/each}}
            </tr>
          </tbody>
        </table>
      </div>
    {{else}}
      {{#each this.instructions as |card|}}
        <AppContentCard @title={{card.title}} class="m-4 even:bg-ocean-50">
          {{#if card.subtitle}}
            <h2 class="italicized">{{card.subtitle}}:</h2>
          {{/if}}
          <ul class="flex-col">
            {{#each card.instructions as |step index|}}
              <li class="flex my-4 mx-2">
                <div
                  class="rounded-full bg-ocean-600 text-white mr-2 w-6 h-6 flex items-center justify-center shrink-0"
                >{{inc index}}</div>
                <span class="step-by-step-table-links">
                  {{htmlSafe step}}
                </span>
              </li>
            {{/each}}
          </ul>
        </AppContentCard>
      {{/each}}

      {{#if (not (screenIs "md"))}}
        <div class="flex w-full items-center justify-around">
          <TioPdfPrintButton
            @url={{@recommendation.downloadUrl}}
            @filename={{@recommendation.downloadFilename}}
            class="border-none"
          />
          <TioDownloadButton
            @url={{@recommendation.downloadUrl}}
            @filename={{@recommendation.downloadFilename}}
            class="border-none"
          />
        </div>
      {{/if}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
