import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type { IntlService } from 'ember-intl';
import type { NotificationsService } from '@frontile/notifications';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';
import type TASProgramTemplateModel from 'tio-common/models/tas-program-template';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';

type Transition = ReturnType<RouterService['transitionTo']>;

export default class AuthenticatedTuitionAssistanceDashboardRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;
  @service declare notifications: NotificationsService;
  @service declare intl: IntlService;
  @service declare tuitionAssistance: TuitionAssistanceService;

  // check for mentorship form submission re-route without passing
  // as a param that needs to be reset to prevent persistence
  beforeModel(transition: Transition) {
    if (transition.to?.queryParams.formSubmitted) {
      const successMessage = this.intl.t('tuition_assistance.mentoring.submit_success');
      this.notifications.add(successMessage, {
        appearance: 'success',
      });
    }
  }

  async model() {
    const currentEmployee = this.sessionContext.currentEmployee;

    let templates = await this.store.query('tas-program-template', {
      filter: {
        state: 'TAS.ProgramTemplateState.LAUNCHED',
        byEligibility: currentEmployee.id,
        company: currentEmployee.company.id,
      },
      include: 'company.company-setting',
      reload: true,
      limit: 100,
    });

    const promises = [
      // Load the TasParticipant fresh to ensure we alert any HELD participant state
      this.store.findRecord('employee', currentEmployee.id, {
        include: 'tas-participant,company',
        reload: true,
      }),
      this.tuitionAssistance.getApplicationLimitStatusByEmployeeId(currentEmployee),
      this.store.query('tas-program-instance', {
        filter: { employee: currentEmployee.id, active: true },
        include: 'tas-program-template,tas-applications',
        limit: 100,
      }),
      this.store.query('tas-program-instance', {
        filter: { employee: currentEmployee.id, past: true },
        include: 'tas-program-template',
      }),
    ];

    const currentUserCanViewTrialTemplates =
      this.sessionContext.user.isEmployeeAdmin || this.sessionContext.user.isAccountOwner;

    if (currentUserCanViewTrialTemplates) {
      promises.push(
        this.store.query('tas-program-template', {
          filter: {
            state: 'TAS.ProgramTemplateState.LAUNCHED',
            isTrial: true,
            company: currentEmployee.company.id,
          },
          include: 'company.company-setting',
        })
      );
    }

    const [
      employeeWithParticipants,
      limitsStatus,
      activeInstances,
      pastInstances,
      trialTemplates = [],
    ] = await Promise.all(promises);

    if (currentUserCanViewTrialTemplates) {
      templates = [...templates, ...trialTemplates];
    }

    const isEmployeeProgram = (program: TASProgramTemplateModel) => {
      return program.isDependentProgram === false;
    };

    const isEmployeeInstance = (instance: TASProgramInstanceModel) => {
      return instance.isDependentProgram === false;
    };

    const filteredTemplates = await templates.filter(isEmployeeProgram);
    const filteredActiveInstances = await activeInstances.filter(isEmployeeInstance);
    const filteredPastInstances = await pastInstances.filter(isEmployeeInstance);

    return {
      templates: filteredTemplates,
      activeInstances: filteredActiveInstances,
      pastInstances: filteredPastInstances,
      isHeldParticipant: !!employeeWithParticipants.tasParticipant?.isHeld,
      limitsStatus,
    };
  }
}
