import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import type { IntlService } from 'ember-intl';
import type DigitalAssetsService from 'tio-common/services/digital-assets';
import type EmployeeModel from 'tio-common/models/employee';
import type PslfProfileModel from 'tio-common/models/pslf-profile';
import type StoreService from 'tio-common/services/store';
import type DigitalAssetModel from 'tio-common/models/digital-asset';
import TioDialog from 'tio-common/components/tio/dialog';
import TioButton from 'tio-common/components/tio/button';
import TioAlert from 'tio-common/components/tio/alert';
import MaterialIcon from 'tio-common/components/material-icon';
import FileUploader from 'tio-common/components/file-uploader';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import FormTextarea from '@frontile/forms-legacy/components/form-textarea';
import FormCheckboxGroup from '@frontile/forms-legacy/components/form-checkbox-group';
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
import { not } from 'ember-truth-helpers';
import type PslfAssetModel from 'tio-common/models/pslf-asset';
export interface PslfUploadModalSignature {
    Args: {
        employee: EmployeeModel;
        profile: PslfProfileModel;
        didSave?: () => void;
        onCancel?: () => void;
    };
    Element: HTMLDivElement;
}
type FormType = {
    text: string;
    value: string;
};
export default class PslfUploadModal extends Component<PslfUploadModalSignature> {
    @service
    store: StoreService;
    @service
    intl: IntlService;
    @service
    digitalAssets: DigitalAssetsService;
    @tracked
    formType: FormType;
    @tracked
    details?: DigitalAssetModel['details'];
    @tracked
    description = '';
    @tracked
    didConfirmWarning = false;
    @tracked
    didConfirmWarning2 = false;
    @tracked
    didConfirmWarning3 = false;
    @tracked
    isFileAttached = false;
    @tracked
    validationErrors?: string[];
    error?: string;
    docTypes: FormType[];
    constructor(owner1: unknown, args1: PslfUploadModalSignature['Args']){
        super(owner1, args1);
        this.docTypes = [
            {
                text: this.intl.t('pslf.document_repo.classification.pslf_or_ecf_form'),
                value: 'pslf_or_ecf_form'
            },
            {
                text: this.intl.t('pslf.document_repo.classification.pslf_letter'),
                value: 'pslf_letter'
            },
            {
                text: this.intl.t('pslf.document_repo.classification.pslf_loan'),
                value: 'pslf_loan'
            },
            {
                text: this.intl.t('pslf.document_repo.classification.pslf_other'),
                value: 'pslf_other'
            }
        ];
    }
    get canSubmit() {
        return this.isFileAttached && this.hasAcknowledgedDisclaimer;
    }
    get isPslfEcfForm() {
        return this.formType?.value === 'pslf_or_ecf_form';
    }
    get hasAcknowledgedDisclaimer() {
        if (this.isPslfEcfForm) {
            return this.didConfirmWarning && this.didConfirmWarning2 && this.didConfirmWarning3;
        }
        return true;
    }
    @action
    setFormType(value1: FormType) {
        this.formType = value1;
    }
    @action
    async didUploadFile(details1: DigitalAssetModel['details']) {
        this.details = details1;
        this.isFileAttached = true;
    }
    @dropTask
    *saveAsset() {
        const asset1: PslfAssetModel = this.digitalAssets.createAssetFromS3Details('pslfAsset', this.details);
        asset1.description = this.description;
        asset1.classification = this.formType.value;
        const { employee: employee1, profile: profile1 } = this.args;
        asset1.employees.push(employee1);
        asset1.pslfProfiles.push(profile1);
        const saved1: PslfAssetModel = yield asset1.save();
        yield this.store.findRecord('pslfProfile', profile1.id, {
            include: 'attachments',
            reload: true
        });
        this.args.didSave?.();
        return saved1;
    }
    @action
    cancel() {
        this.args.onCancel?.();
        delete this.error;
    }
    static{
        template(`
    <TioDialog @onCancel={{@onCancel}} @show={{true}} ...attributes>
      <:header>
        <p class="font-semibold uppercase">{{t "pslf.document_repo.send_us_your_doc"}}</p>
      </:header>
      <:body>
        {{#if this.isPslfEcfForm}}
          <TioAlert
            @type="warning"
            @allowDismiss={{false}}
            @iconClass="sm:!self-start"
            class="max-w-screen-lg mb-8"
          >
            <:body>
              <p class="mb-2 font-semibold">{{t "pslf.document_repo.pslf_form_type_disclaimer"}}</p>
              <div class="text-sm ml-4 mb-3">
                <span>
                  {{t "mail_to"}}
                  <br />
                  {{t "pslf.document_repo.dept_ed_mailing_address" htmlSafe=true}}
                  <br />
                  <br />
                  {{t "fax_to"}}
                  {{t "pslf.document_repo.dept_ed_fax_no"}}
                </span>
              </div>
              <p class="font-bold my-2">
                {{t "pslf.document_repo.disclaimer_header"}}
              </p>
              <FormCheckboxGroup
                data-legacy-input
                class="my-2"
                @errors={{this.validationErrors}}
                as |Checkbox|
              >
                <Checkbox
                  @name="notDeptEd"
                  @label={{t "pslf.document_repo.not_dept_ed"}}
                  @checked={{this.didConfirmWarning}}
                  @onChange={{fn (mut this.didConfirmWarning)}}
                  @containerClass="mb-1"
                />
                <Checkbox
                  @name="mustMail"
                  @label={{t "pslf.document_repo.must_mail"}}
                  @checked={{this.didConfirmWarning2}}
                  @onChange={{fn (mut this.didConfirmWarning2)}}
                  @containerClass="mb-1"
                />
                <Checkbox
                  @name="tioDoesNotSend"
                  @label={{t "pslf.document_repo.tio_does_not_submit"}}
                  @checked={{this.didConfirmWarning3}}
                  @onChange={{fn (mut this.didConfirmWarning3)}}
                  @containerClass="mb-1"
                />
              </FormCheckboxGroup>
            </:body>
          </TioAlert>
        {{/if}}
        <div class="grid gap-8 w-full max-w-screen-lg sm:grid-cols-1 md:grid-cols-2">
          <div>
            <div class="mb-2">
              <p class="mb-4">{{t "pslf.document_repo.why_upload_docs"}}</p>
              <p class="text-lg font-semibold mb-4 text-gray-600">
                {{t "pslf.document_repo.what_type_of_doc"}}
              </p>
              <FormSelect
                data-legacy-input
                {{! @glint-expect-error: bug in power select}}
                @allowClear={{true}}
                @label={{t "pslf.document_repo.document_type"}}
                @searchEnabled={{true}}
                @searchField="name"
                @options={{this.docTypes}}
                @selected={{this.formType}}
                @onChange={{this.setFormType}}
                @renderInPlace={{true}}
                @hint={{t "pslf.document_repo.required"}}
                as |formType|
              >
                {{! @glint-expect-error: bug in power select}}
                {{formType.text}}
              </FormSelect>
            </div>
            <div class="mb-2">
              <FormTextarea
                data-legacy-input
                @label={{t "pslf.document_repo.text_area_label"}}
                @value={{this.description}}
                @onInput={{fn (mut this.description)}}
              />
            </div>
          </div>
          <div>
            <p>{{t "upload_requirements"}}</p>
            {{#if this.isFileAttached}}
              <div
                class="p-2 border border-gray-300 border-dashed h-full flex flex-col items-center justify-center min-h-56"
              >
                <MaterialIcon @icon="description" class="text-gray-500" />
                <p>{{this.details.filename}}</p>
              </div>
            {{else}}
              <FileUploader class="min-h-56 h-full" @didUploadFile={{this.didUploadFile}} />
            {{/if}}
          </div>
        </div>

      </:body>
      <:actions>
        <div class="flex w-full items-center justify-between">
          <TioButton
            @outlined={{true}}
            {{! @glint-expect-error: return to this. something is weird on flow }}
            @disabled={{this.saveAsset.isRunning}}
            @onClick={{this.cancel}}
            class="w-1/2 sm:min-w-48 sm:w-fit"
          >
            {{t "cancel"}}
          </TioButton>
          <TioButton
            {{! @glint-expect-error: return to this. something is weird on flow }}
            @onClick={{this.saveAsset.perform}}
            {{! @glint-expect-error: return to this. something is weird on flow }}
            @loading={{this.saveAsset.isRunning}}
            @disabled={{not this.canSubmit}}
            class="ml-4 w-1/2 sm:min-w-48 sm:w-fit"
          >
            {{t "submit"}}
          </TioButton>
        </div>
      </:actions>
    </TioDialog>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
