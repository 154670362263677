import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { gt, not } from 'ember-truth-helpers';
import Component from '@glimmer/component';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type TasProgramInstance from 'tio-common/models/tas-program-instance';
import type DependentModel from 'tio-common/models/dependent';
import TioButton from 'tio-common/components/tio/button';
import AddDependent from '../forms/add-dependents';
import FormRadio from '@frontile/forms-legacy/components/form-radio';
import UiPill from 'tio-common/components/ui/pill';
import FormInput from '@frontile/forms-legacy/components/form-input';
import DependentApplicationStepper from './dependent-application-stepper';
import { number, NumberSchema, ValidationError } from 'yup';
interface TAProgramInstanceApplyDependentInformationSignature {
    Args: {
        programInstance: TasProgramInstance;
    };
}
const relationships = [
    {
        type: 'DependentRelationship.CHILD',
        name: 'Child'
    },
    {
        type: 'DependentRelationship.SPOUSE',
        name: 'Spouse'
    },
    {
        type: 'DependentRelationship.PARTNER',
        name: 'Partner'
    }
];
const ageFromBirthDate = (date1: string)=>{
    return Math.floor((new Date().valueOf() - new Date(date1).getTime().valueOf()) / 3.15576e10);
};
const getDependentRelationshipName = (dependent1: DependentModel)=>{
    return relationships.find((relationship1)=>relationship1.type === dependent1.relationship)!.name;
};
let TuitionAssistanceProgramInstanceApplyDependenInformationComponent = class TuitionAssistanceProgramInstanceApplyDependenInformationComponent extends Component<TAProgramInstanceApplyDependentInformationSignature> {
    @service
    store: typeof Store;
    @service
    router: RouterService;
    @tracked
    selectedDependent = '';
    @tracked
    showForm = false;
    @action
    toggleShowForm() {
        this.showForm = !this.showForm;
    }
    @action
    setDependent(value1: unknown) {
        this.selectedDependent = value1 as string;
    }
    @action
    updateBirthdate(birthDate1: string) {
        this.dependentApplying.birthDate = birthDate1;
    }
    @action
    async updateInstanceAndAdvance() {
        if (this.dependentApplying.dirtyType === 'updated') {
            this.dependentApplying.save();
        }
        this.args.programInstance.dependent = this.dependentApplying;
        this.args.programInstance.setInitialDependentFields();
        await this.args.programInstance.save();
        this.router.transitionTo('authenticated.tuition-assistance.programs.instance.apply.program-information');
    }
    get dependentApplying(): DependentModel {
        return this.args.programInstance.employee.dependents.find((dependent1)=>dependent1.id === this.selectedDependent)!;
    }
    get ageVerification() {
        const ageLimit1 = getSingleValueForTasField('DEPENDENT_PROGRAM_AGE_LIMITS', this.args.programInstance.tasProgramTemplate.fields) as Record<string, number>;
        const dependentAge1 = ageFromBirthDate(this.dependentApplying.birthDate);
        const schemaMapping1: Record<string, NumberSchema> = {
            PARTNER: number().max(ageLimit1.PARTNER!, `The age limit for this program is ${ageLimit1.PARTNER}`),
            SPOUSE: number().max(ageLimit1.SPOUSE!, `The age limit for this program is ${ageLimit1.SPOUSE}`),
            CHILD: number().max(ageLimit1.CHILD!, `The age limit for this program is ${ageLimit1.CHILD}`)
        };
        const relationship1 = this.dependentApplying.relationship.split('.')[1];
        const schema1 = schemaMapping1[relationship1 as keyof typeof schemaMapping];
        if (!ageLimit1[relationship1 as keyof typeof schemaMapping]) {
            return [];
        }
        try {
            schema1!.validateSync(dependentAge1);
            return [];
        } catch (err1) {
            if (err1 instanceof ValidationError) {
                return [
                    err1.message
                ];
            }
        }
        return [];
    }
    static{
        template(`
    <DependentApplicationStepper @showForm={{this.showForm}} @stage="Info" />
    <h3 class="font-semibold my-2 text-midnight">{{t
        "common.tuition_assistance.program_details.applicant_info"
      }}</h3>
    <h4>{{t "common.tuition_assistance.program_details.header"}}</h4>
    {{#if this.showForm}}
      <div class="mt-4 flex-col" data-test-dependent-info>
        <span class="flex my-6 w-96 justify-between items-baseline">
          {{this.dependentApplying.fullName}}
          <UiPill
            @label={{getDependentRelationshipName this.dependentApplying}}
            @bgColorClass="bg-gray-300"
            @textColorClass="text-gray-800"
            class="!flex !px-4 !py-2"
          />
          {{ageFromBirthDate this.dependentApplying.birthDate}}
          {{t "common.tuition_assistance.dependent_programs_information_years"}}
        </span>
        <form class="w-1/4 bg-white pb-8">
          <div class="mt-2">
            <FormInput
              data-legacy-input
              @label={{t "common.tuition_assistance.add_dependent_modal.birth_date"}}
              @type="date"
              @value={{this.dependentApplying.birthDate}}
              @onChange={{this.updateBirthdate}}
              @errors={{this.ageVerification}}
              @showError={{true}}
              data-test-edit-dependent-birth-date
            />
          </div>
          <div class="flex my-6">
            <TioButton
              @outlined={{true}}
              @onClick={{this.toggleShowForm}}
              class="mr-8 w-48 uppercase !border-cyan-700 !text-cyan-700"
            >
              {{t "common.back"}}
            </TioButton>
            <TioButton
              @onClick={{this.updateInstanceAndAdvance}}
              class="w-48 uppercase"
              @disabled={{gt this.ageVerification.length 0}}
            >
              {{t "common.confirm"}}
            </TioButton>
            <TioButton
              @outlined={{true}}
              @linkTo="authenticated.tuition-assistance.dependent-programs"
              class="ml-8 w-48 uppercase"
            >
              {{t "common.cancel"}}
            </TioButton>
          </div>
        </form>
      </div>
    {{else}}
      {{#if @programInstance.employee.dependents}}
        {{#each @programInstance.employee.dependents as |dependent|}}
          <div class="my-6">
            <FormRadio
              data-legacy-input
              @value={{dependent.id}}
              class="items-center"
              name="dependent"
              @checked={{this.selectedDependent}}
              @onChange={{this.setDependent}}
            >
              <span class="flex w-96 justify-around items-start">
                {{dependent.fullName}}
                <UiPill
                  @label={{getDependentRelationshipName dependent}}
                  @bgColorClass="bg-gray-300"
                  @textColorClass="text-gray-800"
                  class="!flex !px-4 !py-2 -mt-2"
                />
                {{ageFromBirthDate dependent.birthDate}}
                {{t "common.tuition_assistance.dependent_programs_information_years"}}
              </span>
            </FormRadio>
          </div>
        {{/each}}
      {{/if}}
      <AddDependent @employee={{@programInstance.employee}} />
      <div class="flex my-6">
        <TioButton
          @onClick={{this.toggleShowForm}}
          class="w-48 uppercase"
          @disabled={{not this.selectedDependent}}
          data-test-dependent-continue
        >
          {{t "common.continue"}}
        </TioButton>
        <TioButton
          @outlined={{true}}
          @linkTo="authenticated.tuition-assistance.dependent-programs"
          class="ml-8 w-48 uppercase"
        >
          {{t "common.cancel"}}
        </TioButton>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default TuitionAssistanceProgramInstanceApplyDependenInformationComponent;
