import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { and } from 'ember-truth-helpers';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import changeApproverFormSchema from 'tio-common/validation-schema/forms/change-approver-form';
import Component from '@glimmer/component';
import errorsForField from 'tio-common/helpers/errors-for-field';
import FormInput from '@frontile/forms-legacy/components/form-input';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import TioAlert from 'tio-common/components/tio/alert';
import TioDialog from 'tio-common/components/tio/dialog';
import type { IntlService } from 'ember-intl';
import type Errors from 'tio-employee/types/errors';
import type PslfFormModel from 'tio-common/models/pslf-form';
export interface PslfMyDocumentsMyFormsTableChangeApproverSignature {
    Args: {
        form: PslfFormModel;
        didSave: (form: PslfFormModel) => Promise<void>;
        didCancel: () => void;
    };
}
export default class PslfMyDocumentsMyFormsTableChangeApproverComponent extends Component<PslfMyDocumentsMyFormsTableChangeApproverSignature> {
    @service
    intl: IntlService;
    @tracked
    borrowerEmployerEmail = '';
    @tracked
    borrowerEmployerContactName = '';
    @tracked
    borrowerEmployerContactTitle = '';
    @tracked
    borrowerEmployerContactPhone = '';
    @tracked
    borrowerPrintAndMail = false;
    @tracked
    hasSubmitted = false;
    @tracked
    submitError?: Errors;
    get hasEmail() {
        return !this.borrowerPrintAndMail;
    }
    constructor(owner1: unknown, args1: PslfMyDocumentsMyFormsTableChangeApproverSignature['Args']){
        super(owner1, args1);
        const editableForm1 = this.args.form;
        if (editableForm1.borrowerFields['borrower-employer-email']) {
            this.borrowerEmployerEmail = editableForm1.borrowerFields['borrower-employer-email'];
        }
        if (editableForm1.borrowerFields['borrower-employer-contact-name']) {
            this.borrowerEmployerContactName = editableForm1.borrowerFields['borrower-employer-contact-name'];
        }
        if (editableForm1.borrowerFields['borrower-employer-contact-title']) {
            this.borrowerEmployerContactTitle = editableForm1.borrowerFields['borrower-employer-contact-title'];
        }
        if (editableForm1.borrowerFields['borrower-employer-contact-phone']) {
            this.borrowerEmployerContactPhone = editableForm1.borrowerFields['borrower-employer-contact-phone'];
        }
        if (editableForm1.borrowerFields['borrower-print-and-mail']) {
            this.borrowerPrintAndMail = editableForm1.borrowerFields['borrower-print-and-mail'];
        }
    }
    @action
    submit() {
        this.submitTask.perform();
    }
    submitTask = dropTask(async ()=>{
        delete this.submitError;
        this.hasSubmitted = true;
        if (this.formValidationErrors.length) {
            return;
        }
        try {
            const saved1 = await this.saveFormValues.perform();
            this.hasSubmitted = false;
            this.args.didSave?.(saved1);
        } catch (e1) {
            this.submitError = e1;
            console.error(e1);
        }
    });
    saveFormValues = dropTask(async ()=>{
        const form1 = await this.args.form;
        const existingBorrowerFields1 = form1.borrowerFields;
        const updatedBorrowerFields1 = {
            ...existingBorrowerFields1,
            'borrower-employer-email': this.borrowerEmployerEmail,
            'borrower-employer-contact-name': this.borrowerEmployerContactName,
            'borrower-employer-contact-title': this.borrowerEmployerContactTitle,
            'borrower-employer-contact-phone': this.borrowerEmployerContactPhone,
            'borrower-print-and-mail': this.borrowerPrintAndMail
        };
        form1.borrowerFields = updatedBorrowerFields1;
        // This preserves the behavior of the Vue app, however,
        // now that we have automatic group assignment for some partners,
        // the rest of the employer fields are auto-filled (not just email).
        //
        // TODO: Investigate if we should remove the entire employerFields object
        // when a user sets their form to print and mail, because they cannot undo it.
        // - Julia 12/13/2022
        if (this.borrowerPrintAndMail) {
            const existingEmployerFields1 = form1.employerFields;
            const updatedEmployerFields1 = {
                ...existingEmployerFields1,
                'employer-email': ''
            };
            form1.employerFields = updatedEmployerFields1;
        }
        await form1.save();
        return form1;
    });
    @action
    cancel() {
        this.args.didCancel?.();
    }
    @action
    didChangePrintAndMail(value1: unknown) {
        this.borrowerPrintAndMail = !!value1;
        if (value1) {
            this.borrowerEmployerContactName = '';
            this.borrowerEmployerContactPhone = '';
            this.borrowerEmployerContactTitle = '';
            this.borrowerEmployerEmail = '';
        }
    }
    get formValidationErrors() {
        const schema1 = changeApproverFormSchema;
        const formModel1 = {
            borrowerEmployerEmail: this.borrowerEmployerEmail
        };
        try {
            schema1?.validateSync?.(formModel1, {
                abortEarly: false
            });
        } catch (err1) {
            return err1.inner || [];
        }
        return [];
    }
    get serverErrorMessages() {
        const genericErrorMessage1 = this.intl.t('error_occurred');
        if (this.submitError?.errors) {
            return this.submitError.errors.map((err1)=>{
                return err1?.detail || genericErrorMessage1;
            });
        } else if (this.submitError) {
            return [
                genericErrorMessage1
            ];
        }
        return [];
    }
    static{
        template(`
    <TioDialog
      @show={{true}}
      @containerClass="w-full sm:max-w-screen-md"
      @onCancel={{this.cancel}}
      @onConfirm={{this.submit}}
      @headerClass="border-b"
      @icon="mail"
      @submitButtonText={{if
        this.borrowerPrintAndMail
        (t "save")
        (t "pslf.workflow_prior_employer.submit_for_approval")
      }}
    >
      <:header>
        <div class="text-xl">
          {{t "pslf.workflow_prior_employer.send_your_document"}}
        </div>
      </:header>

      <:body>
        <form class="mx-1 md:mx-20">
          <FormRadioGroup
            data-legacy-input
            @label={{t "pslf.workflow_prior_employer.send_electronically"}}
            required="true"
            @value={{this.borrowerPrintAndMail}}
            class="text-lg change-approver-radio"
            @onChange={{this.didChangePrintAndMail}}
            as |Radio|
          >
            {{! @glint-expect-error}}
            <Radio @value={{false}} @label={{t "pslf.workflow_prior_employer.i_have_email"}} />
            {{! @glint-expect-error}}
            <Radio @value={{true}} @label={{t "pslf.workflow_prior_employer.i_dont_have_email"}} />
          </FormRadioGroup>

          {{#if this.hasEmail}}
            <div class="mt-4">
              <p class="text-lg font-medium py-2">
                {{t "pslf.workflow_prior_employer.please_provide"}}
              </p>
              <p class="text-sm text-tio-gray-600">
                {{t "pslf.workflow_prior_employer.please_provide_hint"}}
              </p>
            </div>

            <TioAlert @type="info" @allowDismiss={{false}}>
              <:body>
                <p class="text-sm">
                  {{t "pslf.workflow_prior_employer.correct_email_alert"}}
                </p>
              </:body>
            </TioAlert>

            <div class="mx-6">
              <FormInput
                data-legacy-input
                @label={{t "pslf.workflow_prior_employer.employer_email"}}
                @value={{this.borrowerEmployerEmail}}
                @onInput={{fn (mut this.borrowerEmployerEmail)}}
                @containerClass="mb-4 w-full"
                @hint={{t "pslf.workflow_prior_employer.employer_email_hint"}}
                @errors={{errorsForField
                  "borrowerEmployerEmail"
                  schemaErrors=this.formValidationErrors
                }}
              />

              <FormInput
                data-legacy-input
                @label={{t "pslf.workflow_prior_employer.contact_name"}}
                @value={{this.borrowerEmployerContactName}}
                @onInput={{fn (mut this.borrowerEmployerContactName)}}
                @containerClass="mb-4 w-full"
                @hint={{t "pslf.workflow_prior_employer.optional_not_required"}}
              />

              <FormInput
                data-legacy-input
                @label={{t "pslf.workflow_prior_employer.contact_title"}}
                @value={{this.borrowerEmployerContactTitle}}
                @onInput={{fn (mut this.borrowerEmployerContactTitle)}}
                @containerClass="mb-4 w-full"
                @hint={{t "pslf.workflow_prior_employer.optional"}}
              />

              <FormInput
                data-legacy-input
                @label={{t "pslf.workflow_prior_employer.contact_phone"}}
                @value={{this.borrowerEmployerContactPhone}}
                @onInput={{fn (mut this.borrowerEmployerContactPhone)}}
                @containerClass="mb-4 w-full"
                @hint={{t "pslf.workflow_prior_employer.optional"}}
              />
            </div>
          {{/if}}
        </form>

        {{#if (and this.hasSubmitted this.serverErrorMessages.length)}}
          <ul class="text-red-600 my-2">
            {{#each this.serverErrorMessages as |message|}}
              <li class="whitespace-normal">{{message}}</li>
            {{/each}}
          </ul>
        {{/if}}
      </:body>
    </TioDialog>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
