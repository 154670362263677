import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { TrackedObject } from 'tracked-built-ins';
import { tracked, cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { setSingleValueForTasField, getSingleValueForTasField, buildValidationSchemaForProgramTemplateFields } from 'tio-common/utils/tuition-assistance/fields';
import { object } from 'yup';
import type RouterService from '@ember/routing/router';
import type TasCourse from 'tio-common/models/tas-course';
import TioButton from 'tio-common/components/tio/button';
import { eq, not, or } from 'ember-truth-helpers';
import HeldParticipantWarning from 'tio-common/components/tuition-assistance/forms/courses/held-participant-warning';
import { t } from 'ember-intl';
import ProgramReadonlyField from 'tio-common/components/tuition-assistance/program-readonly-field';
import ProgramCustomReadonlyField from 'tio-common/components/tuition-assistance/program-custom-readonly-field';
import UploadAttachments from 'tio-common/components/tuition-assistance/forms/upload-attachments';
import divide from 'ember-math-helpers/helpers/div';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import TuitionAssistanceFormsCoursesGradeSelectOptions from 'tio-common/components/tuition-assistance/forms/courses/grade-select-options';
import { fn } from '@ember/helper';
export interface TuitionAssistanceFormsCoursesCourseAddGradeSignature {
    Args: {
        course: TasCourse;
    };
}
export default class TuitionAssistanceFormsCoursesCourseAddGrade extends Component<TuitionAssistanceFormsCoursesCourseAddGradeSignature> {
    @service
    router: RouterService;
    @tracked
    customFieldsCopy: TasCourse['fields'][] = [];
    customFieldsProvince = 'COURSE';
    courseFieldsCopy: TasCourse['fields'];
    constructor(owner1: unknown, args1: TuitionAssistanceFormsCoursesCourseAddGradeSignature['Args']){
        super(owner1, args1);
        this.courseFieldsCopy = new TrackedObject(this.args.course.fields);
        // We specifically are not updating custom fields against program config
        // at this point (see `copyFieldsAndUpdatePerProgramTemplate` for reference)
        // because these fields are no longer editable at this point.
        // @ts-expect-error: need to unify types
        this.customFieldsCopy = this.args.course.customFields?.map((field1)=>{
            return {
                ...field1
            };
        }) || [];
    }
    get courseFormFields() {
        return [
            {
                name: 'COURSE_NAME',
                mask: null
            },
            {
                name: 'COURSE_NUMBER',
                mask: null
            },
            {
                name: 'COURSE_DESCRIPTION',
                mask: null
            },
            {
                name: 'COURSE_CREDIT',
                mask: null
            },
            {
                name: 'COURSE_TUITION',
                mask: 'currency'
            },
            {
                name: 'COURSE_BOOKS',
                mask: 'currency'
            },
            {
                name: 'COURSE_LABS',
                mask: 'currency'
            },
            {
                name: 'COURSE_SOFTWARE',
                mask: 'currency'
            },
            {
                name: 'COURSE_FEES',
                mask: 'currency'
            }
        ];
    }
    get customFieldsForProvince() {
        // @ts-expect-error: will get fixed when we make a generic of method
        return this.customFieldsCopy.filter((field1)=>field1.province === this.customFieldsProvince);
    }
    get programTemplate() {
        return this.args.course.tasApplication?.tasProgramInstance?.tasProgramTemplate || {};
    }
    get showHeldParticipantWarning() {
        return !!this.args.course.tasApplication?.tasProgramInstance?.employee?.tasParticipant?.isHeld;
    }
    get requireAttachmentUploads() {
        return this.args.course.tasApplication?.isEvidenceRequired;
    }
    get attachmentUploadsVisible() {
        return this.programTemplate.attachmentsForCourseCompletionVisible;
    }
    get hasAttachments() {
        return !!this.args.course.evidenceAssets?.length;
    }
    get formModel() {
        return {
            COURSE_GRADE: getSingleValueForTasField('COURSE_GRADE', this.courseFieldsCopy)
        };
    }
    get formValidationErrors() {
        const schema1 = this.dynamicValidationSchema || object();
        try {
            schema1?.validateSync?.(this.formModel, {
                abortEarly: false
            });
        } catch (err1) {
            return err1.inner || [];
        }
        return [];
    }
    get isFormValid() {
        if (this.requireAttachmentUploads) {
            return !this.formValidationErrors.length && this.hasAttachments;
        }
        return !this.formValidationErrors.length;
    }
    @cached
    get dynamicValidationSchema() {
        const fields1 = [
            {
                name: 'COURSE_GRADE',
                rules: {
                    type: 'string'
                }
            }
        ];
        // @ts-expect-error: will get fixed when we make a generic of method
        return buildValidationSchemaForProgramTemplateFields(fields1, this.programTemplate);
    }
    @action
    updateCourseGrade(fieldName1: string, value1: string) {
        // @ts-expect-error: will get fixed when we make a generic of method
        setSingleValueForTasField(fieldName1, value1, this.courseFieldsCopy);
    }
    @action
    cancel() {
        // Does not save
        this.router.transitionTo('authenticated.tuition-assistance.programs.instance.course-applications.show');
    }
    saveAndNext = dropTask(async ()=>{
        if (!this.isFormValid) {
            return;
        }
        await this.saveFields.perform();
        this.router.transitionTo('authenticated.tuition-assistance.programs.instance.course-applications.show');
    });
    saveFields = dropTask(async ()=>{
        this.args.course.fields = this.courseFieldsCopy;
        await this.args.course.save();
        await this.args.course.tasApplication.reload();
    });
    static{
        template(`
    <form class="flex flex-wrap">
      <div class="grid md:grid-cols-2 mt-5 md:mt-10 gap-4 w-full md:w-2/3">
        {{#each this.courseFormFields as |formField|}}
          <div class="mx-8 col-span-1">
            <ProgramReadonlyField
              {{! @glint-expect-error: looks like we need to use correct types }}
              @name={{formField.name}}
              @programTemplate={{@course.tasApplication.tasProgramInstance.tasProgramTemplate}}
              @fields={{@course.fields}}
              as |field|
            >
              <dt>{{field.programLabel}}</dt>
              {{#if (eq formField.mask "currency")}}
                <dd class="font-semibold pl-2 py-3">
                  {{safeFormatNumber
                    (divide (or field.value 0) 100)
                    style="currency"
                    currency="USD"
                  }}
                </dd>
              {{else}}
                <dd class="font-semibold pl-2 py-3">
                  {{! @glint-expect-error: will get fixed when we make a generic of method }}
                  {{field.value}}
                </dd>
              {{/if}}
            </ProgramReadonlyField>
          </div>
        {{/each}}
        {{#each this.customFieldsForProvince as |customField|}}
          {{! @glint-expect-error: will get fixed when we make a generic of method }}
          <ProgramCustomReadonlyField @customField={{customField}} as |field|>
            <div class="mx-8 col-span-1">
              {{! @glint-expect-error: will get fixed when we make a generic of method }}
              <dt>{{field.programLabel}}</dt>
              <dd class="font-semibold pl-2 py-3">
                {{! @glint-expect-error: will get fixed when we make a generic of method }}
                {{field.value}}
              </dd>
            </div>
          </ProgramCustomReadonlyField>
        {{/each}}
      </div>

      <div class="md:row-span-4 mt-5 md:mt-10 md:w-1/3">
        <TuitionAssistanceFormsCoursesGradeSelectOptions
          @programTemplate={{@course.tasApplication.tasProgramInstance.tasProgramTemplate}}
          @updateCourseGrade={{fn this.updateCourseGrade "COURSE_GRADE"}}
          @formValidationErrors={{this.formValidationErrors}}
          @fieldsCopy={{this.courseFieldsCopy}}
        />

        {{#if this.attachmentUploadsVisible}}
          <div class="mt-4">
            <UploadAttachments
              @programTemplate={{@course.tasApplication.tasProgramInstance.tasProgramTemplate}}
              @classification="COMPLETION_DOCUMENTS"
              @attachable={{@course}}
              @attachableType="tasCourse"
              @uploadRequired={{this.requireAttachmentUploads}}
              class="!mx-0"
            />
          </div>
        {{/if}}
      </div>
    </form>

    {{#if this.showHeldParticipantWarning}}
      <HeldParticipantWarning class="my-4 mx-auto max-w-md" />
    {{/if}}

    <div class="flex flex-col md:flex-row md:justify-center items-center gap-4">
      <TioButton
        @onClick={{this.cancel}}
        @disabled={{this.saveAndNext.isRunning}}
        @outlined={{true}}
        class="mx-8 w-48"
      >
        {{t "cancel"}}
      </TioButton>
      <TioButton
        @onClick={{this.saveAndNext.perform}}
        @loading={{this.saveAndNext.isRunning}}
        @disabled={{not this.isFormValid}}
        class="mx-8 w-48"
      >
        {{t "save"}}
      </TioButton>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
